import axios from "axios";

const url = process.env.REACT_APP_API_URL;
const url2 = process.env.REACT_APP_API_URL_BPMS;

const brevo_url = "https://api.brevo.com/v3";

const KEY =
  "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";

const Authentication = {
  loginHandler: (payload) => {
    const response = axios({
      url: `${url}/auth/login/`,
      method: "POST",
      data: JSON.stringify({
        email: payload.email,
        phone: payload.phone,
        password: payload.password,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  verifyOtp: (payload) => {
    const response = axios({
      url: `${url}/auth/users/verify-token/`,
      method: "POST",
      data: JSON.stringify({
        token: payload.token,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  resetPassword: (payload) => {
    const response = axios({
      url: `${url}/auth/users/reset-password/`,
      method: "POST",
      data: JSON.stringify({
        email: payload.email,
        phone_number: payload.phone_number,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  createPassword: (payload) => {
    const response = axios({
      url: `${url}/auth/users/create-password/`,
      method: "POST",
      data: JSON.stringify({
        token: payload.token,
        password: payload.password,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  fetchUserDetails: (payload) => {
    const response = axios({
      url: `${url}/auth/users/${payload.user_id}/`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  },
  signupHandler: (payload) =>
    axios({
      url: `${url}/auth/users/signup/`,
      method: "POST",
      data: JSON.stringify({
        email: payload.email,
        phone: payload.phone,
        password: payload.password,
        fullname: payload.fullname,
        vehicle_data: {
          plate_number: payload.vehicle_data.plate_number,
          vehicle_year: payload.vehicle_data.vehicle_year,
          vehicle_make: payload.vehicle_data.vehicle_make,
          vehicle_model: payload.vehicle_data.vehicle_model,
          vehicle_trim: payload.vehicle_data.vehicle_trim,
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }),

  regCollectorHandler: (payload) =>
    axios({
      url: `${url2}/main/app/reg/collector`,
      method: "POST",
      data: JSON.stringify({
        email: payload.email,
        name: payload.fullname,
        phone_no: payload.phone_no,
        plate_no: payload.plate_no,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }),

  registerUserToPremiumPapers: (payload) => {
    const res = axios({
      url: `${url2}/premiumpapers/app/register`,
      method: "POST",
      data: JSON.stringify({
        name: payload.name,
        plate_no: payload.plate_no,
        phone_no: payload.phone_no,
        email: payload.email,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  premiumPapersHandler: (payload) => {
    const res = axios({
      url: `${url2}/python/premiumpapers/app/register`,
      method: "POST",
      data: JSON.stringify({
        name: payload.name,
        plate_no: payload.plate_no,
        phone_no: payload.phone_no,
        email: payload.email,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  setengineHandler: (payload) =>
    axios({
      url: `${url2}/main/app/set/engine?plate_no=${payload.list}&make=${payload.selectInput2}&model=${payload.selectInput3}&year=${payload.selectInput}&v_type=${payload.selectInput4}`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }),

  getExistingCustomerDetails: (payload) => {
    const response = axios({
      url: `${url2}/noauth/cusinfo/${payload.plate_number}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  validateAccount: (payload) => {
    const response = axios({
      url: `${url}/auth/users/validate-account/`,
      method: "POST",
      data: JSON.stringify({
        phone_number: payload.phone_number,
        email: payload.email,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },
  addNewVehicle: (payload) => {
    const response = axios({
      url: `${url}/auth/users/${payload.id}/add/vehicle/data/`,
      method: "POST",
      data: JSON.stringify({
        plate_number: payload.plate_number,
        vehicle_year: payload.vehicle_year,
        vehicle_make: payload.vehicle_make,
        vehicle_model: payload.vehicle_model,
        vehicle_trim: "4444",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  brevoWelcomeMessage: (payload) => {
    const response = axios({
      url: `${brevo_url}/smtp/email`,
      method: "POST",
      data: JSON.stringify({
        sender: {
          name: "Temitope from Motormata",
          email: "temitopefashae@gmail.com",
        },
        params: { NAME: payload.name },
        to: [{ email: payload.email, name: payload.name }],
        templateId: 1,
      }),
      headers: {
        accept: "application/json",
        "content-type": "application/json",
        "api-key": KEY,
      },
    });

    return response;
  },

  addContactToBrevo: (payload) => {
    const response = axios({
      url: `${brevo_url}/contacts`,
      method: "POST",
      data: JSON.stringify({
        attributes: { FIRSTNAME: payload.name },
        updateEnabled: false,
        email: payload.email,
        ext_id: payload.id,
      }),
      headers: {
        "Content-Type": "application/json",
        "api-key": KEY,
      },
    });

    return response;
  },

  progressierWelcomeMessage: (payload) => {
    const response = axios({
      url: `https://progressier.app/kCb2XYYfUhuKHz2nbI0Y/send`,
      method: "POST",
      data: JSON.stringify({
        recipients: {
          id: payload.id,
        },
        title: "Welcome to Motormata",
        body: `New message: Welcome to Motormata ${payload.name}`,
        url: "https://app.motormata.com/home",
      }),
      headers: {
        authorization:
          "Bearer 9qy05xbwt39rgrvl5tpvy1sb3z9xt3ohhrvs4ly8hdh0blzl",
        "Content-Type": "application/json",
      },
    });
    return response;
  },
};
export default Authentication;
