import React, { useEffect, useState } from "react";
import BottomNav from "../../../Components/BottomNav";
import InsuranceNav from "./InsuranceNav";
import styles from "./Papers.module.css";
import NavBar from "../../../Components/interface/NavBar";
import { Link } from "react-router-dom";
import Button from "../../../Components/interface/Button";
import Input from "../../../Components/interface/Input";
import { useDispatch } from "react-redux";
import { dongleActions } from "../../../store/redux-store/dongle-store";

const initialValues = {
  firstName: "",
  lastName: "",
  dateOfBirth: "",
};

function InsuranceKyc(props) {
  const [values, setValues] = useState(initialValues);
  const [fileType, setFileType] = useState("");
  const [filePath, setFilePath] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      filePath !== null &&
      values.firstName.length > 0 &&
      values.lastName.length > 0 &&
      values.dateOfBirth.length > 0 &&
      fileType.length > 0
    ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [
    values.firstName.length,
    values.lastName.length,
    filePath,
    fileType.length,
    values.dateOfBirth.length,
  ]);

  const handleInputChange = (e) => {
    console.log(fileType);
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  // const removeBase64Prefix = (base64String) => {
  //   // Check if the string starts with the prefix
  //   if (base64String.startsWith("data:application/pdf;base64,")) {
  //     // Remove the prefix from the string
  //     return base64String.slice("data:application/pdf;base64,".length);
  //   } else {
  //     // Return the original string if it doesn't start with the prefix
  //     return base64String;
  //   }
  // };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    setFilePath(file);

    // return new Promise((resolve, reject) => {
    //   if (!file) {
    //     reject("No file provided");
    //     return;
    //   }

    // const reader = new FileReader();

    // reader.onload = (event) => {
    //   resolve(event.target.result);
    //   const base64Image = event.target.result;
    //   // const base64FilePath = removeBase64Prefix(event.target.result);
    //   // const buffer = Buffer.from(base64FilePath, "base64");

    //   // Convert base64 to buffer
    //   // const buffer = base64ToBlob(base64Image);
    //   setFilePath(base64Image);
    // };

    // reader.onerror = (error) => {
    //   reject(error);
    // };

    // reader.readAsDataURL(file);
    // });
  };

  const submitPersonalInfo = () => {
    dispatch(dongleActions.clearThirdPartyKyc());
    const kycData = {
      firstName: values.firstName,
      lastName: values.lastName,
      fileType,
      filePath,
      dateOfBirth: values.dateOfBirth,
    };
    dispatch(dongleActions.thirdPartyPlusKyc(kycData));
  };

  return (
    <div className={styles.buyDongle}>
      <NavBar title="Insurance" setShowMenu={props.setShowMenu} />
      <div className={styles.buyDongle__body}>
        <InsuranceNav currentStep={2} />

        <div className={styles.head}>
          <div className={styles.head_title}>
            <h1>Get Started by Inputting Your KYC Info.</h1>
          </div>

          <div className={styles.head_title_banner}>
            <p>Contact Information</p>
          </div>

          <div className={styles.form}>
            <Input
              label="First Name"
              onChange={handleInputChange}
              value={values.firstName}
              type="name"
              placeholder="Enter your first name"
              name="firstName"
            />
            <Input
              label="Last Name"
              onChange={handleInputChange}
              value={values.lastName}
              type="text"
              placeholder="Enter your last number"
              name="lastName"
            />
            <Input
              label="Date Of Birth"
              onChange={handleInputChange}
              value={values.number}
              type="date"
              placeholder="Enter phone number"
              name="dateOfBirth"
            />
            <div className={styles.form__inner}>
              <label htmlFor="Year">Select ID Type</label>
              <div className={styles.form__passwordInput}>
                <select onChange={(e) => setFileType(e.target.value)}>
                  <option value="ID Type">ID Type</option>
                  <option value="NIN">NIN</option>
                  <option value="Passport">Passport</option>
                </select>
              </div>
            </div>

            <Input
              label="Upload ID file"
              onChange={handleFileUpload}
              value={values.name}
              type="file"
              placeholder="Enter your full name"
              name="file"
            />
          </div>

          <div className={styles.button3}>
            <Link to="/insurance/claim">
              <Button onClick={submitPersonalInfo} disabled={buttonDisabled}>
                Continue
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <BottomNav />
    </div>
  );
}

export default InsuranceKyc;
