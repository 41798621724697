import React from "react";
import styles from "./Papers.module.css";
import NavBar from "../../../Components/interface/NavBar";
import { Link } from "react-router-dom";
import Button from "../../../Components/interface/Button";
import InsuranceNav from "./InsuranceNav";
import Card from "../../../Components/UI/Card";

function InsuranceCheckout(props) {
  return (
    <div className={styles.buyDongle}>
      <NavBar title="Insurance" setShowMenu={props.setShowMenu} />
      <div className={styles.buyDongle__body}>
        <InsuranceNav currentStep={4} />
        <div className={styles.head}>
          <div className={styles.bankDetails}>
            <h3>Here is the account details you are to make transfer to.</h3>
            <h2>17821782178217821782</h2>
            <h3>Account name: Test</h3>
            <h3>Bank Name: First Bank</h3>
          </div>

          <Card>
            <div className={styles.insurancePapers}>
              <div className={styles.papers_title}>
                <h4>Third party Plus</h4>
              </div>
              <div className={styles.papers_title}>
                <h4>₦ 11,000</h4>
              </div>
            </div>

            <div className={styles.insurancePapers}>
              <p>View details</p>
              <p>Price</p>
            </div>
          </Card>

          <div className={styles.button3}>
            <Link to="/papers/success">
              <Button>Continue</Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InsuranceCheckout;
