import React from "react";
import styles from "./Health.module.css";
import NavBar from "../../Components/interface/NavBar";
import BottomNav from "../../Components/BottomNav";

function MotorAi(props) {
  return (
    <div>
      <NavBar title="MotorAi" setShowMenu={props.setShowMenu} />
      <div className={styles.motor_ai_head}>
        <iframe
          className={styles.iframe}
          src="https://motoai.vercel.app/"
          name="motor_ai"
          // height="620px"
          width="100%"
          title="MotormataAi"
        ></iframe>
      </div>
      <BottomNav />
    </div>
  );
}

export default MotorAi;
