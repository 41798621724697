import React, { useEffect, useState } from "react";
import NavBar from "../../Components/interface/NavBar";
import styles from "./InspectionReport.module.css";
import BottomNav from "../../Components/BottomNav";
import CustomerScans from "../../utils/api/CustomerScans";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Card from "../../Components/UI/Card";

function InspectionReportHistory(props) {
  const [data, setData] = useState([]);

  const personalData = useSelector((data) => data.auth.generalLoginData);

  const vehicleIndex = useSelector((data) => data.dongles.currentVehicle);

  const plate_no =
    personalData?.vehicle_data[vehicleIndex]?.plate_number !== undefined
      ? personalData?.vehicle_data[vehicleIndex]?.plate_number
      : personalData?.vendor_vehicle_data[0].plate_number;

  useEffect(() => {
    let fetchInspectionReport = async () => {
      try {
        const fetchInspeactionRes =
          await CustomerScans.getAllInspeactionReports({
            plate_no,
          });
        setData(fetchInspeactionRes.data);
      } catch (err) {
        console.log(err?.response?.data);
      }
    };
    fetchInspectionReport();
  }, [personalData, plate_no]);

  return (
    <section>
      <NavBar title="Inspection" setShowMenu={props.setShowMenu} />
      <div className={styles.head}>
        <div className={styles.main_hero}>
          <div className={styles.history}>
            {data?.data?.length > 0 ? (
              <div className={styles.history_list}>
                {data?.data?.map((data) => (
                  <Link to={`/inspection-report/${data?.unique_id}`}>
                    <Card className={styles.list}>
                      <div>
                        <p className={styles.title}>Report</p>
                        <h5>{data?.customer_name}</h5>
                        <p>Customer Name</p>
                      </div>
                      <div className={styles.list_info}>
                        <p>Order Status</p>
                        <h5>{data?.status}</h5>
                        <p>{data?.unique_id}</p>
                      </div>
                    </Card>
                  </Link>
                ))}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h1>There is nothing to show here.</h1>
                <p>You have no inspection report.</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <BottomNav />
    </section>
  );
}

export default InspectionReportHistory;
