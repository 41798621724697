import axios from "axios";

const url = `https://motormata-app-default-rtdb.firebaseio.com`;

const base_url = process.env.REACT_APP_API_URL_BPMS;

const ServiceApi = {
  fetchServiceCategories: () => {
    const response = axios({
      url: `${url}/spare_parts.json`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  fetchCategories: () => {
    const response = axios({
      url: `${base_url}/product/and/services/categories`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  getProductsList: (payload) => {
    const response = axios({
      url: `${base_url}/product/and/services/productList`,
      method: "POST",
      data: JSON.stringify({
        category_name: payload.category_name,
        search: payload.search,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  checkout: (payload) => {
    const response = axios({
      url: `${base_url}/product/and/services/checkout/${payload.plate_no}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  addToCart: (payload) => {
    const response = axios({
      url: `${base_url}/product/and/services/addToCart`,
      method: "POST",
      data: JSON.stringify({
        plate_no: payload.plate_no,
        product_id: payload.product_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  removeFromCart: (payload) => {
    const response = axios({
      url: `${base_url}/product/and/services/removeFromCart`,
      method: "POST",
      data: JSON.stringify({
        plate_no: payload.plate_no,
        product_id: payload.product_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  generateBankAccount: (payload) => {
    const response = axios({
      url: `${base_url}/main/app/papers/generateAccount/${payload.order_id}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  submitOrder: (payload) => {
    const response = axios({
      url: `${base_url}/product/and/services/upload`,
      method: "POST",
      data: JSON.stringify({
        plateno: payload.plateno,
        phone: payload.phone,
        products: payload.products,
        state: payload.state,
        city: payload.city,
        service_delivery_date: payload.service_delivery_date,
        order_type: payload.order_type,
        location: payload.location,
        street: payload.street,
        lastname: payload.lastname,
        firstname: payload.firstname,
        customer_id: payload.customer_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  fetchAllItems: () => {
    const response = axios({
      url: `${url}/post_inventory.json`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  fetchVehicles: () => {
    const response = axios({
      url: `${url}/vehicles.json`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },
};
export default ServiceApi;
