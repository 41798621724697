import React from "react";
import styles from "../../Pages/Insurance/Insurance.module.css";

function VehicleDetails2(props) {
  const openInfoHandler = () => {
    props.setShowVehicleDetails((prev) => !prev);
  };
  return (
    <div className={styles.body} onClick={openInfoHandler}>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.25 9.75C19.25 7.95979 18.5388 6.2429 17.273 4.97703C16.0071 3.71116 14.2902 3 12.5 3C10.7098 3 8.9929 3.71116 7.72703 4.97703C6.46116 6.2429 5.75 7.95979 5.75 9.75C5.75 12.519 7.9655 16.128 12.5 20.451C17.0345 16.128 19.25 12.519 19.25 9.75ZM12.5 22.5C6.9995 17.5005 4.25 13.2495 4.25 9.75C4.25 7.56196 5.11919 5.46354 6.66637 3.91637C8.21354 2.36919 10.312 1.5 12.5 1.5C14.688 1.5 16.7865 2.36919 18.3336 3.91637C19.8808 5.46354 20.75 7.56196 20.75 9.75C20.75 13.2495 18.0005 17.5005 12.5 22.5Z"
          fill="#03C962"
        />
        <path
          d="M12.5 12C13.0967 12 13.669 11.7629 14.091 11.341C14.5129 10.919 14.75 10.3467 14.75 9.75C14.75 9.15326 14.5129 8.58097 14.091 8.15901C13.669 7.73705 13.0967 7.5 12.5 7.5C11.9033 7.5 11.331 7.73705 10.909 8.15901C10.4871 8.58097 10.25 9.15326 10.25 9.75C10.25 10.3467 10.4871 10.919 10.909 11.341C11.331 11.7629 11.9033 12 12.5 12ZM12.5 13.5C11.5054 13.5 10.5516 13.1049 9.84835 12.4017C9.14509 11.6984 8.75 10.7446 8.75 9.75C8.75 8.75544 9.14509 7.80161 9.84835 7.09835C10.5516 6.39509 11.5054 6 12.5 6C13.4946 6 14.4484 6.39509 15.1517 7.09835C15.8549 7.80161 16.25 8.75544 16.25 9.75C16.25 10.7446 15.8549 11.6984 15.1517 12.4017C14.4484 13.1049 13.4946 13.5 12.5 13.5Z"
          fill="#03C962"
        />
      </svg>

      <p>
        {props.vehicle_make} {props.vehicle_model}{" "}
        {props?.plate_number?.toUpperCase()}
      </p>

      {props.showVehicleDetails ? (
        <svg
          width="29"
          height="28"
          viewBox="0 0 29 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.5 17L14.5 11L8.5 17"
            stroke="#151522"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ) : (
        <svg
          width="29"
          height="28"
          viewBox="0 0 29 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.5 11L14.5 17L8.5 11"
            stroke="#151522"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      )}
    </div>
  );
}

export default VehicleDetails2;
