import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./Papers.module.css";
import InsuranceNav from "./InsuranceNav";
import NavBar from "../../../Components/interface/NavBar";
import BottomNav from "../../../Components/BottomNav";
import Button from "../../../Components/interface/Button";
import { useNavigate } from "react-router-dom";
import cars from "../../../utils/CarList";
import placeholder from "../../../asset/images/imgPlaceholder.png";
import AWS from "aws-sdk";
import Input from "../../../Components/interface/Input";
import { useSelector } from "react-redux";
import CustomerScans from "../../../utils/api/CustomerScans";

const initialValues = {
  name: "",
  number: "",
  plateNumber: "",
};

const S3_BUCKET = "third-party-plus-kyc";
const REGION = "us-east-1";

AWS.config.update({
  accessKeyId: "AKIA4YTYZ6EGUHWXIQZ5",
  secretAccessKey: "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",
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

function InsuranceClaim(props) {
  const [values, setValues] = useState(initialValues);
  const [selectInput, setSelectInput] = useState("");
  const [selectInput2, setSelectInput2] = useState("");
  const [selectInput3, setSelectInput3] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [displayImages, setDisplayImages] = useState([]);

  const thirdPartyInfo = useSelector((data) => data.dongles.thirdPartyPlusInfo);

  const navigate = useNavigate();

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (
      uploadedImages.length > 0 &&
      values.number.length > 0 &&
      values.plateNumber.length > 0 &&
      selectInput.length > 0 &&
      selectInput3.length > 0 &&
      selectInput2.length > 0
    ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [
    uploadedImages.length,
    values.number.length,
    values.plateNumber.length,
    selectInput.length,
    selectInput2.length,
    selectInput3.length,
  ]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const model = cars?.find((model, index) => model.brand === selectInput2);

  // const removeBase64Prefix = (base64String) => {
  //   // Check if the string starts with the prefix
  //   if (base64String.startsWith("data:image/png;base64,")) {
  //     // Remove the prefix from the string
  //     return base64String.slice("data:image/png;base64,".length);
  //   } else if (base64String.startsWith("data:image/jpeg;base64,")) {
  //     return base64String.slice("data:image/jpeg;base64,".length);
  //   } else {
  //     // Return the original string if it doesn't start with the prefix
  //     return base64String;
  //   }
  // };

  const handleFileUpload = (file) => {
    return new Promise((resolve, reject) => {
      if (!file) {
        reject("No file provided");
        return;
      }

      const reader = new FileReader();

      reader.onload = (event) => {
        const base64Data = event.target.result;

        resolve(base64Data);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  const addImage = useCallback(() => {
    if (
      thirdPartyInfo?.filePath &&
      !uploadedImages.includes(thirdPartyInfo.filePath)
    ) {
      setUploadedImages((images) => [...images, thirdPartyInfo.filePath]);
    }
  }, [thirdPartyInfo?.filePath, uploadedImages]);

  useEffect(() => {
    addImage();
  }, [addImage]);

  const handleCapture = async (event) => {
    const files = event.target.files[0];
    if (uploadedImages.length >= 5) {
      alert("You can only upload up to 4 images.");
      return;
    }
    try {
      const fileUrl = await handleFileUpload(files);
      // const base64 = removeBase64Prefix(fileUrl);

      // Remove data URL prefix
      // const base64 = fileUrl.replace(/^data:image\/\w+;base64,/, "");

      // const buffer = Buffer.from(base64, "base64");
      setUploadedImages((images) => [...images, files]);
      setDisplayImages((images) => [...images, fileUrl]);
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("There was an error uploading the file. Please try again.");
    }
  };

  const clearPicturesHandler = () => {
    setUploadedImages([]);
    setDisplayImages([]);
  };

  let selectChangeHandler = (e) => {
    setSelectInput(e.target.value);
  };
  let brandSelectChangeHandler = (e) => {
    setSelectInput2(e.target.value);
  };
  let modelSelectChangeHandler = (e) => {
    setSelectInput3(e.target.value);
  };

  // function getFileType(file) {
  //   if (file instanceof File) {
  //     // If it's a File object, we can get the type directly
  //     const fileType = file.type.split("/").pop();
  //     return fileType.toLowerCase();
  //   } else if (typeof file === "string") {
  //     // If it's a string (possibly a data URL or file name)
  //     if (file.startsWith("data:")) {
  //       // It's a data URL
  //       const match = file.match(/^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
  //       if (match) {
  //         return match[1].split("/").pop().toLowerCase();
  //       }
  //     } else {
  //       // It might be a file name
  //       const extension = file.split(".").pop().toLowerCase();
  //       return extension;
  //     }
  //   }

  //   // If we can't determine the file type
  //   return "unknown";
  // }

  const submitClaimsHandler = async () => {
    setButtonDisabled(true);
    const promises = uploadedImages.map(async (file) => {
      // const format = getFileType(file);
      const params = {
        Bucket: S3_BUCKET,
        Key: `${Date.now()}-${file.name}`,
        Body: file,
        ContentType: file.type,
      };

      return new Promise((resolve, reject) => {
        myBucket.upload(params, (err, data) => {
          if (err) {
            console.error("Error uploading file:", err?.response);
            reject(err);
          } else {
            console.log("Successfully uploaded file:", data);
            resolve(data.Location); // The file URL
          }
        });
      });
    });

    try {
      const urls = await Promise.all(promises);
      const thirdPartyPlusRes = await CustomerScans.sendTicket({
        to_name: "Admin",
        from_name: `Third Party Plus request for - ${thirdPartyInfo.firstName}, ${thirdPartyInfo.lastName}`,
        message: `A user named
          ${thirdPartyInfo.firstName}, ${
          thirdPartyInfo.lastName
        } reuested for the third party plus with their KYC below. First Name:${
          thirdPartyInfo.firstName
        }, Last Name :${thirdPartyInfo.lastName} 
          Date Of Birth: ${thirdPartyInfo.dateOfBirth}
          ID type: ${thirdPartyInfo.fileType}
          Car Brand: ${selectInput2}
          Car Make: ${selectInput3}
          Year: ${selectInput}
          Plate Number: ${values.plateNumber}
          Value Of Car: ${values.number}

         Links to Images of Vehicle Below And ID
          ${urls.map((data, index) => `${index + 1}\n${data}`).join("\n")}. `,
      });
      navigate("/insurance/terms");
      setButtonDisabled(false);
      return thirdPartyPlusRes;
    } catch (error) {
      setButtonDisabled(false);

      console.error("Error uploading files:", error);
    }
  };

  const image = displayImages.length !== 0 ? displayImages[0] : placeholder;
  const image2 = displayImages.length !== 0 ? displayImages[1] : placeholder;
  const image3 = displayImages.length !== 0 ? displayImages[2] : placeholder;
  const image4 = displayImages.length !== 0 ? displayImages[3] : placeholder;

  return (
    <div className={styles.buyDongle}>
      <NavBar title="Insurance" setShowMenu={props.setShowMenu} />
      <div className={styles.buyDongle__body}>
        <InsuranceNav currentStep={3} />

        <div className={styles.head}>
          <div className={styles.head_title}>
            <h1>Input your vehicle details to get insured.</h1>
          </div>
          <div className={styles.claim_form}>
            <div className={styles.imageUploadWrapper}>
              {/* <input
                type="file"
                accept="image/png"
                capture="environment"
                multiple
                onChange={handleCapture}
              /> */}

              <div style={{ textAlign: "center", padding: "50px" }}>
                <input
                  type="file"
                  accept="image/*"
                  capture="environment"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleCapture}
                />
                <button
                  onClick={handleButtonClick}
                  style={{ border: "none", background: "none" }}
                >
                  {/* Replace this with your SVG or any other icon */}
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16.5605 4.5H22.25L23 5.25V18.75L22.25 19.5H2.75L2 18.75V5.25L2.75 4.5H8.4395L9.719 3.219L10.25 3H14.75L15.281 3.219L16.5605 4.5ZM3.5 18H21.5V6H16.25L15.719 5.781L14.4395 4.5H10.5605L9.281 5.781L8.75 6H3.5V18ZM5.75 7.5C5.55109 7.5 5.36032 7.57902 5.21967 7.71967C5.07902 7.86032 5 8.05109 5 8.25C5 8.44891 5.07902 8.63968 5.21967 8.78033C5.36032 8.92098 5.55109 9 5.75 9C5.94891 9 6.13968 8.92098 6.28033 8.78033C6.42098 8.63968 6.5 8.44891 6.5 8.25C6.5 8.05109 6.42098 7.86032 6.28033 7.71967C6.13968 7.57902 5.94891 7.5 5.75 7.5ZM12.5 9C13.2956 9 14.0587 9.31607 14.6213 9.87868C15.1839 10.4413 15.5 11.2044 15.5 12C15.5 12.7956 15.1839 13.5587 14.6213 14.1213C14.0587 14.6839 13.2956 15 12.5 15C11.7044 15 10.9413 14.6839 10.3787 14.1213C9.81607 13.5587 9.5 12.7956 9.5 12C9.5 11.2044 9.81607 10.4413 10.3787 9.87868C10.9413 9.31607 11.7044 9 12.5 9ZM12.5 7.5C11.3065 7.5 10.1619 7.97411 9.31802 8.81802C8.47411 9.66193 8 10.8065 8 12C8 13.1935 8.47411 14.3381 9.31802 15.182C10.1619 16.0259 11.3065 16.5 12.5 16.5C13.6935 16.5 14.8381 16.0259 15.682 15.182C16.5259 14.3381 17 13.1935 17 12C17 10.8065 16.5259 9.66193 15.682 8.81802C14.8381 7.97411 13.6935 7.5 12.5 7.5Z"
                      fill="#03C962"
                    />
                  </svg>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div>Take a shot</div>
                  </div>
                </button>
              </div>
            </div>

            <div onClick={clearPicturesHandler} className={styles.videoUpload}>
              <div>
                <p>Clear Pictures</p>
              </div>
              <div>
                <svg
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.677 3.888C20.1573 1.368 16.8457 0 13.2822 0C9.75469 0 6.40714 1.368 3.88748 3.888C-1.29583 9.072 -1.29583 17.496 3.88748 22.644C6.40714 25.164 9.71869 26.532 13.2822 26.532C16.8097 26.532 20.1573 25.164 22.677 22.644C27.8603 17.496 27.8603 9.072 22.677 3.888ZM22.353 22.356C19.9413 24.768 16.7018 26.1 13.2822 26.1C9.86267 26.1 6.62311 24.768 4.21143 22.356C-0.791893 17.352 -0.791893 9.216 4.21143 4.212C6.62311 1.8 9.86267 0.468 13.2822 0.468C16.7018 0.468 19.9413 1.8 22.353 4.212C27.3563 9.216 27.3563 17.352 22.353 22.356ZM17.1337 9.756L13.4982 13.392L17.1337 17.028C17.2057 17.1 17.2057 17.244 17.1337 17.352C17.0977 17.388 17.0257 17.424 16.9897 17.424C16.9177 17.424 16.8817 17.388 16.8457 17.352L13.1742 13.716L9.53872 17.352C9.50272 17.388 9.43073 17.424 9.39474 17.424C9.32275 17.424 9.28675 17.388 9.25076 17.352C9.17877 17.28 9.17877 17.136 9.25076 17.028L12.8863 13.392L9.21476 9.756C9.14277 9.684 9.14277 9.54 9.21476 9.432C9.28675 9.36 9.43073 9.36 9.53872 9.432L13.1742 13.068L16.8097 9.432C16.8817 9.36 17.0257 9.36 17.1337 9.432C17.2057 9.54 17.2057 9.684 17.1337 9.756Z"
                    fill="#008069"
                  />
                </svg>
              </div>
            </div>
            <div className={styles.uploadedImageWrapper}>
              <div className={styles.uploadedImageWrapper_container}>
                <div className={styles.uploadedImageWrapper_inner}>
                  <img src={image} alt="" />
                </div>
              </div>

              <div className={styles.uploadedImageWrapper_container}>
                <div className={styles.uploadedImageWrapper_inner}>
                  <img src={image2} alt="" />
                </div>
              </div>

              <div className={styles.uploadedImageWrapper_container}>
                <div className={styles.uploadedImageWrapper_inner}>
                  <img src={image3} alt="" />
                </div>
              </div>

              <div className={styles.uploadedImageWrapper_container}>
                <div className={styles.uploadedImageWrapper_inner}>
                  <img src={image4} alt="" />
                </div>
              </div>
            </div>
            <div className={styles.plateNumber_input}>
              <div className={styles.form__inner}>
                <div className={styles.form__passwordInput}>
                  <select onChange={brandSelectChangeHandler}>
                    <option value="">Brand</option>
                    {cars
                      .sort((a, b) => a.brand.localeCompare(b.brand))
                      .map((data, index) => (
                        <option value={data.brand} key={index}>
                          {data.brand}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className={styles.form__inner}>
                <div className={styles.form__passwordInput}>
                  <select onChange={modelSelectChangeHandler}>
                    <option value="">Choose Model</option>
                    {model?.models.map((data, index) => (
                      <option key={index} value={data}>
                        {data}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className={styles.form__inner}>
                <div className={styles.form__passwordInput}>
                  <select onChange={selectChangeHandler}>
                    <option value="">Choose Year</option>
                    <option value="2000">2000</option>
                    <option value="2001">2001</option>
                    <option value="2002">2002</option>
                    <option value="2003">2003</option>
                    <option value="2004">2004</option>
                    <option value="2005">2005</option>
                    <option value="2006">2006</option>
                    <option value="2007">2007</option>
                    <option value="2008">2008</option>
                    <option value="2009">2009</option>
                    <option value="2010">2010</option>
                    <option value="2011">2011</option>
                    <option value="2012">2012</option>
                    <option value="2013">2013</option>
                    <option value="2014">2014</option>
                    <option value="2015">2015</option>
                    <option value="2016">2016</option>
                    <option value="2017">2017</option>
                    <option value="2018">2018</option>
                    <option value="2019">2019</option>
                    <option value="2020">2020</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                  </select>
                </div>

                <Input
                  label="Enter Plate Number"
                  onChange={handleInputChange}
                  value={values.plateNumber}
                  type="text"
                  placeholder="Enter plate number"
                  name="plateNumber"
                />
                <Input
                  type="text"
                  value={values.number}
                  label="Enter Car value"
                  onChange={handleInputChange}
                  placeholder="Enter the car value"
                  name="number"
                />
              </div>
            </div>
          </div>{" "}
          <div className={styles.button3}>
            {/* <Link to="/insurance/terms"> */}
            <Button onClick={submitClaimsHandler} disabled={buttonDisabled}>
              Continue
            </Button>
            {/* </Link> */}
          </div>
        </div>
      </div>
      <BottomNav />
    </div>
  );
}

export default InsuranceClaim;
