import React, { useEffect, useState } from "react";
import InsuranceNav from "./InsuranceNav";
import BottomNav from "../../../Components/BottomNav";
import NavBar from "../../../Components/interface/NavBar";
import styles from "./Papers.module.css";
import Card from "../../../Components/UI/Card";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../Components/interface/Button";
import ServiceApi from "../../../utils/api/ServiceApi";
import Modal from "../../../Components/UI/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import CarPapersAPi from "../../../utils/api/CarPapersApi";

function InsuranceItemPage(props) {
  const [index, setIndex] = useState(null);
  const [products, setProducts] = useState([]);
  const [openDetails, setOpenDetails] = useState(false);
  const [initLoading, setInitLoading] = useState(false);

  const navigate = useNavigate();

  const dongle = useSelector((data) => data.dongles);

  const personalData = useSelector((data) => data.auth.generalLoginData);

  const plate_number =
    personalData?.vehicle_data[dongle.currentVehicle]?.plate_number !==
    undefined
      ? personalData?.vehicle_data[dongle.currentVehicle]?.plate_number
      : personalData?.vendor_vehicle_data[0].plate_number;

  useEffect(() => {
    let fetchOrderHistory = async () => {
      try {
        const fetchedorderRes = await CarPapersAPi.fetchOrderHistory({
          plate_number: plate_number,
        });
        for (let i = 0; i < fetchedorderRes.data.length; i++) {
          const parsedData = JSON.parse(fetchedorderRes.data[i].order_details);
          for (let j = 0; j < parsedData.items.length; j++) {
            if (
              fetchedorderRes.data[i].order_status === "Pending" &&
              parsedData.items[j].name
                .toLowerCase()
                .includes("third party plus")
            ) {
              navigate(`/insurance/kyc`);
            }
          }
        }
      } catch (err) {
        toast.error("An error Occured", {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    };
    fetchOrderHistory();
  }, [personalData, plate_number, navigate]);

  const selectPaperHandler = async (paperIndex, product_id) => {
    if (index === paperIndex) {
      try {
        const removedCartRes = await ServiceApi.removeFromCart({
          plate_no: plate_number,
          product_id: product_id,
        });
        setIndex(null);
        toast.success(removedCartRes.data.message, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return removedCartRes.data;
      } catch (err) {
        toast.error(err?.response?.data?.message, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        console.log(err?.response?.data?.message);
      }
    } else {
      try {
        const addedCartRes = await ServiceApi.addToCart({
          plate_no: plate_number,
          product_id,
        });
        setIndex(paperIndex);
        toast.success(addedCartRes.data.message, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        return addedCartRes.data;
      } catch (err) {
        toast.error(err?.response?.data?.message, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setInitLoading(true);
      try {
        // Fetch data from the database
        const response = await ServiceApi.getProductsList({
          category_name: "Paper",
        });
        const filteredEngineOilProducts = response.data.data.data.filter(
          (product) =>
            product.product_title.includes(
              "Mini Comprehensive Insurance  + KYC & Claim Addon"
            )
        );
        setProducts(filteredEngineOilProducts);
        setInitLoading(false);
      } catch (error) {
        setInitLoading(false);
        toast.error("An error Occured", {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    };

    fetchData();
  }, []);

  return (
    <div className={styles.buyDongle}>
      <ToastContainer />
      <NavBar title="Insurance" setShowMenu={props.setShowMenu} />
      <div className={styles.buyDongle__body}>
        <InsuranceNav currentStep={1} />

        {initLoading ? (
          <Modal message="" back="" accept="" loader="true" />
        ) : (
          <div className={styles.head}>
            <div className={styles.head_title}>
              <h1>Select your preferred insurance package</h1>
            </div>

            <div className={styles.head_title_banner}>
              <p>Insurance packages</p>
            </div>

            <Card
              onClick={() =>
                selectPaperHandler(1, products[0].magento_product_id)
              }
              className={index !== null ? styles.selected : ""}
            >
              <div className={styles.insurancePapers}>
                <div className={styles.papers_title}>
                  <h4>{products[0]?.product_title}</h4>
                </div>
                <div className={styles.papers_title}>
                  <h4>₦{Number(products[0]?.price).toLocaleString()}</h4>
                </div>
              </div>

              <div className={styles.insurancePapers}>
                <p
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenDetails((open) => !open);
                  }}
                >
                  View details
                </p>
                <p>Price</p>
              </div>
              {openDetails && (
                <div className={styles.insurancePapers}>
                  <p>
                    Get a ₦500,000 cover on your vehicle in the event of an
                    accident and all the benefits that a 3rd party insurance
                    offers.
                  </p>
                </div>
              )}
            </Card>

            <div className={styles.button3}>
              <Link to="/spare-parts/cart">
                <Button disabled={index === null}>Buy Now</Button>
              </Link>
            </div>
          </div>
        )}
      </div>
      <BottomNav />
    </div>
  );
}

export default InsuranceItemPage;
