import React, { useState, useEffect } from "react";
import styles from "./RegisterVehicle.module.css";
import { useNavigate } from "react-router";
import Logo from "../../Components/interface/Logo";
import image from "../../asset/images/vehicle.png";
import Button from "../../Components/interface/Button";
import EmailInput from "../../Components/interface/EmailInput";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/redux-store/Index";

function RegisterVehicle() {
  let [number, setNumber] = useState("");
  let [validNumber, setValidNumber] = useState(false);
  let [numberInvalid, setNumberInvalid] = useState(false);
  let [formIsValid, setFormIsValid] = useState(false);
  let navigate = useNavigate();
  let dispatch = useDispatch();

  useEffect(() => {
    if (number.trim().length >= 3) {
      setValidNumber(true);
      setFormIsValid(true);
    } else {
      setValidNumber(false);
      setFormIsValid(false);
    }
  }, [number]);

  let submitHandler = (e) => {
    e.preventDefault();
    dispatch(authActions.plateNumber(number));
    navigate("/registervehicle/form");
  };
  let numberInputHandler = (e) => {
    setNumber(e.target.value);
  };
  let numberInputBlur = () => {
    if (number.trim().length === 0) {
      setNumberInvalid(true);
    } else {
      setNumberInvalid(false);
    }
  };
  return (
    <section className={styles.login}>
      <div className={styles.login__hero}>
        <Logo />
        <div className={styles.header}>
          <p>Register</p>
        </div>
        <div className={styles.login__hero__description}>
          <img src={image} alt="vehicle" />
        </div>
      </div>
      <div className={styles.form}>
        <form onSubmit={submitHandler}>
          <EmailInput
            validEmail={validNumber}
            label="Plate Number"
            value={number}
            emailInvalid={numberInvalid}
            placeholder="KJA123-AH"
            type="text"
            onBlur={numberInputBlur}
            onChange={numberInputHandler}
          />
          <Button disabled={!formIsValid}>Submit</Button>
        </form>
      </div>
    </section>
  );
}

export default RegisterVehicle;
