import React, { useEffect, useState } from "react";
import styles from "./VehicleInfo2.module.css";
import { useSelector } from "react-redux";
import carImg from "../../asset/images/car.png";
import Button from "../interface/Button";
import { useNavigate } from "react-router";
import ServiceApi from "../../utils/api/ServiceApi";

function VehicleInfo2() {
  const [carImage, setCarImage] = useState([]);

  const navigate = useNavigate();

  const personalData = useSelector((data) => data.auth);

  const vehiclesIndex = useSelector((data) => data.dongles.currentVehicle);

  const navigateToLastScanHandler = () => {
    navigate("/history/diagnosis");
  };

  const vehicle_data =
    personalData.generalLoginData?.vehicle_data.length !== 0
      ? personalData.generalLoginData?.vehicle_data
      : personalData.generalLoginData?.vendor_vehicle_data;

  // useEffect(() => {
  //   const fetchYmmData = async () => {
  //     try {
  //       const ymmRes = await CustomerScans.vinDecoder({
  //         token: props.token,
  //         vin: props?.liveMetrics?.scan_data?.vehicle_vin || "vin",
  //       });
  //       const vehicle_data_info =
  //         ymmRes.data.vehicle_make === ""
  //           ? vehicle_data[vehiclesIndex]
  //           : ymmRes.data;
  //       setVehicleInfo(vehicle_data_info);
  //     } catch (err) {
  //       console.log(err?.response?.error);
  //     }
  //   };
  //   fetchYmmData();
  // }, [
  //   props?.liveMetrics?.scan_data?.vehicle_vin,
  //   props.token,
  //   vehicle_data,
  //   vehiclesIndex,
  // ]);

  useEffect(() => {
    const fetchVehicleImages = async () => {
      try {
        const imageRes = await ServiceApi.fetchVehicles();
        const vehicleImage = imageRes.data.find(
          (data) =>
            data.year === vehicle_data[vehiclesIndex]?.vehicle_year &&
            data.make?.toUpperCase() ===
              vehicle_data[vehiclesIndex]?.vehicle_make?.toUpperCase() &&
            data.model?.toUpperCase() ===
              vehicle_data[vehiclesIndex]?.vehicle_model?.toUpperCase()
        );

        setCarImage(vehicleImage);
      } catch (err) {
        console.log(err);
      }
    };
    fetchVehicleImages();
  }, [vehicle_data, vehiclesIndex]);

  return (
    <div className={styles.VehicleInfo}>
      <div className={styles.VehicleInfo_inner}>
        <div>
          {/* <NavLink
            to="/track"
            className={(navData) =>
              navData.isActive ? `${styles.active}` : ""
            }
          >
            {" "}
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.5 6.5C12.5 5.30653 12.0259 4.16193 11.182 3.31802C10.3381 2.47411 9.19347 2 8 2C6.80653 2 5.66193 2.47411 4.81802 3.31802C3.97411 4.16193 3.5 5.30653 3.5 6.5C3.5 8.346 4.977 10.752 8 13.634C11.023 10.752 12.5 8.346 12.5 6.5ZM8 15C4.333 11.667 2.5 8.833 2.5 6.5C2.5 5.04131 3.07946 3.64236 4.11091 2.61091C5.14236 1.57946 6.54131 1 8 1C9.45869 1 10.8576 1.57946 11.8891 2.61091C12.9205 3.64236 13.5 5.04131 13.5 6.5C13.5 8.833 11.667 11.667 8 15Z"
                fill="#008069"
              />
              <path
                d="M8 8C8.39782 8 8.77936 7.84196 9.06066 7.56066C9.34196 7.27936 9.5 6.89782 9.5 6.5C9.5 6.10218 9.34196 5.72064 9.06066 5.43934C8.77936 5.15804 8.39782 5 8 5C7.60218 5 7.22064 5.15804 6.93934 5.43934C6.65804 5.72064 6.5 6.10218 6.5 6.5C6.5 6.89782 6.65804 7.27936 6.93934 7.56066C7.22064 7.84196 7.60218 8 8 8ZM8 9C7.33696 9 6.70107 8.73661 6.23223 8.26777C5.76339 7.79893 5.5 7.16304 5.5 6.5C5.5 5.83696 5.76339 5.20107 6.23223 4.73223C6.70107 4.26339 7.33696 4 8 4C8.66304 4 9.29893 4.26339 9.76777 4.73223C10.2366 5.20107 10.5 5.83696 10.5 6.5C10.5 7.16304 10.2366 7.79893 9.76777 8.26777C9.29893 8.73661 8.66304 9 8 9Z"
                fill="#008069"
              />
            </svg>
          </NavLink> */}
        </div>
      </div>
      <div className={styles.vehicle_information}>
        <div className={styles.vehicle_information_image}>
          <div className={styles.vehicle_information_image_wrapper}>
            <img src={carImage?.imageUrl || carImg} alt="car" />
          </div>
        </div>
        {vehicle_data[vehiclesIndex] && (
          <div className={styles.vehicle_information_info}>
            <h1>
              {`${vehicle_data[vehiclesIndex]?.vehicle_year} ${vehicle_data[
                vehiclesIndex
              ]?.vehicle_make?.toUpperCase()} ${vehicle_data[
                vehiclesIndex
              ]?.vehicle_model?.toUpperCase()}`}
            </h1>
            <h1>{vehicle_data[vehiclesIndex]?.plate_number?.toUpperCase()}</h1>
            <Button onClick={navigateToLastScanHandler}>View Ai Report</Button>
          </div>
        )}
        {/* <div className={styles.vehicle_information_about}>
          <div className={styles.vehicle_information_about_list}>
            <p>Brand</p>
            <p>{vehicleInfo?.vehicle_make?.toUpperCase()}</p>
          </div>

          <div className={styles.vehicle_information_about_list}>
            <p>Model</p>
            <p>{vehicleInfo?.vehicle_model?.toUpperCase()}</p>
          </div>

          <div className={styles.vehicle_information_about_list}>
            <p>Year</p>
            <p>{vehicleInfo?.vehicle_year}</p>
          </div>

          <div className={styles.vehicle_information_about_list}>
            <p>Plate No.</p>
            <p>
              {props?.data[0]?.vehicle_data[
                vehicleIndex
              ]?.plate_number?.toUpperCase() ||
                vehicleInfo?.plate_number?.toUpperCase()}
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default VehicleInfo2;
