import { configureStore, createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import dongleSlice from "./dongle-store";
import sparePartsSlice from "./spare-parts";

const defaultAuthState = {
  personalInfo: [],
  resetPasswordDetails: [],
  plateNumber: "",
  loginData: [],
  generalLoginData: [],
  token: "",
  otp: "",
  userLoggedIn: false,

  vehicleDetails2: [],
  vehicleDetailsAlt: [],
  userDetails: [],
  liveMetrics: [],
  errorDetails: [],
  vin: "",
  token2: "",
  deviceID: "",
  dongleID: "",
  plateNumber2: "",
  userLoggedInDevice: false,
  papersDetails: [],
  deliveryDetails: [],
  magentoProdId: null,
  uploadedImagesStorage: [],
  uploadedImagesStorageClaims: [],
};

let signUpSlice = createSlice({
  name: "sign up",
  initialState: defaultAuthState,
  reducers: {
    signUp(state, action) {
      const payload = action.payload;
      state.personalInfo = payload;
    },
    login(state, action) {
      let payload = action.payload;
      state.token = payload.access;
      if (state.token !== "") {
        state.userLoggedIn = true;
      } else {
        state.userLoggedIn = false;
      }
    },
    loginData(state, action) {
      let payload = action.payload;
      state.loginData.push(payload);
    },

    generalLoginData(state, action) {
      const payload = action.payload;
      state.generalLoginData = payload;
    },
    logOut(state) {
      state.userLoggedIn = false;
      state.token = "";
    },
    plateNumber(state, action) {
      let payload = action.payload;
      state.plateNumber = payload;
    },

    details(state, action) {
      let payload = action.payload;
      state.vehicleDetails2.push({
        vehicleName: payload.vehicle_make,
        vehicleYear: payload.vehicle_year,
        vehiclePicture: payload.images,
        vehicleLogo: payload.images,
        vehicleModel: payload.vehicle_model,
      });
    },

    loggedIn(state, action) {
      let payload = action.payload;
      state.token2 = payload.tokens;
      if (state.token2 !== "") {
        state.userLoggedInDevice = true;
      } else {
        state.userLoggedInDevice = false;
      }
    },

    paperDetails(state, action) {
      let payload = action.payload;
      state.papersDetails.splice(0, 1, payload);
    },
    paperDetailsClear(state) {
      state.papersDetails = [];
    },

    plateNumber2(state, action) {
      let payload = action.payload;
      state.plateNumber2 = payload;
    },

    loggedOut(state) {
      state.userLoggedInDevice = false;
    },

    detailsALt(state, action) {
      let payload = action.payload;
      state.vehicleDetailsAlt.push({
        vehicleModel: payload.vehicle_model,
        vehicleName2: payload.make,
        vehicleYear2: payload.year,
        vehicleModel2: payload.model,
        expiryDate: payload.expiryDate,
        cmmncmDate: payload.cmmncmDate,
      });
    },

    deliveryAdd(state, action) {
      let payload = action.payload;
      state.deliveryDetails = {
        state: payload.state,
        zone: payload.zone,
        street: payload.street,
        location: payload.location,
      };
    },
    clearMagentoId(state) {
      state.magentoProdId = null;
    },

    magentoAdd(state, action) {
      const payload = action.payload;
      state.magentoProdId = payload;
    },

    clearDeliveryAdd(state) {
      state.deliveryDetails = [];
    },

    metrics(state, action) {
      let payload = action.payload;
      state.liveMetrics.push({
        coolant_temperature: payload.vehicle_live_data.coolant_temperature,
        current_fuel_consumption_rate:
          payload.vehicle_live_data.current_fuel_consumption_rate,
        engine_load: payload.vehicle_live_data.engine_load,
        engine_speed: payload.vehicle_live_data.engine_speed,
        intake_pressure: payload.vehicle_live_data.intake_pressure,
        remaining_fuel: payload.vehicle_live_data.remaining_fuel,
        speed: payload.vehicle_live_data.speed,
        milage: payload.vehicle_live_data.vehicle_mileage,
        pressure: payload.vehicle_live_data.pressure,
        voltage: payload.vehicle_live_data.voltage,
        latitude: payload.vehicle_location.latitude,
        longitude: payload.vehicle_location.longitude,
      });
    },
    user(state, action) {
      let payload = action.payload;
      state.userDetails.push({
        email: payload.basic?.[0].email,
        id: payload.basic?.[0].id,
        name: payload.basic?.[0].name,
        phone_no: payload.basic?.[0].phone_no,
        insurance: payload.insurance?.[0].expiry_date,
        road: payload.road,
        vehicle_license: payload.vehicle_license?.[0].expiry_date,
      });
    },
    error(state, action) {
      let payload = action.payload;
      state.errorDetails = payload.vehicle_errors;
    },
    vin(state, action) {
      let payload = action.payload;
      state.vin = payload;
    },
    deviceID(state, action) {
      let payload = action.payload;
      state.deviceID = payload.id;
    },

    uploadCarInsurancePicture(state, action) {
      let payload = action.payload;
      state.uploadedImagesStorage.push(payload);
    },

    clearUploadCarInsurancePicture(state, action) {
      state.uploadedImagesStorage = [];
    },

    uploadCarInsuranceClaimPicture(state, action) {
      let payload = action.payload;
      state.uploadedImagesStorageClaimsÎ.push(payload);
    },

    clearUploadCarInsuranceClaimPicture(state, action) {
      state.uploadedImagesStorageClaims = [];
    },

    dongleID(state, action) {
      let payload = action.payload;
      state.dongleID = payload;
    },

    resetLiveMetrics(state) {
      state.liveMetrics = [];
    },
    resetUserData(state) {
      state.userDetails = [];
    },
    resetVehicleDetailsAlt(state) {
      state.vehicleDetailsAlt = [];
    },
    resetVehicleData(state) {
      state.vehicleDetails2 = [];
    },
    resetToDefault2(state) {
      state.vin = "";
      state.vehicleDetails2 = [];
      state.vehicleDetailsAlt = [];
      state.deviceID = "";
      state.token2 = "";
      state.plateNumber2 = "";
      state.userDetails = [];
      state.liveMetrics = [];
      state.errorDetails = [];
    },

    resetPasswordDetails(state, action) {
      let payload = action.payload;
      state.resetPasswordDetails.push({
        email: payload.email,
        phoneNumber: payload.phone_number,
      });
    },
    resetLoginDetails(state) {
      state.loginData = [];
      state.generalLoginData = [];
    },
    otpHandler(state, action) {
      let payload = action.payload;
      state.otp = payload;
    },
    resetToDefault(state) {
      state.personalInfo = [];
      state.plateNumber = "";
    },
    resetOtp(state) {
      state.otp = "";
    },
    resetOTPToDefault(state) {
      state.resetPasswordDetails = [];
    },
  },
});

const persistConfig = {
  key: "details",
  storage,
};

const donglepersistConfig = {
  key: "dongles",
  storage,
};

const partsPersistConfig = {
  key: "parts",
  storage,
};

const persistedReducer = persistReducer(persistConfig, signUpSlice.reducer);

const donglePersistedReducer = persistReducer(
  donglepersistConfig,
  dongleSlice.reducer
);

const partsPersistedReducer = persistReducer(
  partsPersistConfig,
  sparePartsSlice.reducer
);

const store = configureStore({
  reducer: {
    auth: persistedReducer,
    dongles: donglePersistedReducer,
    parts: partsPersistedReducer,
  },
  middleware: [thunk],
});

export const authActions = signUpSlice.actions;
export default store;
