import React, { useEffect, useState } from "react";
import styles from "./Papers.module.css";
import NavBar from "../../../Components/interface/NavBar";
import BottomNav from "../../../Components/BottomNav";
import image4 from "../../../asset/images/car.png";
import CarPapersAPi from "../../../utils/api/CarPapersApi";
import { useSelector } from "react-redux";
import ServiceApi from "../../../utils/api/ServiceApi";
import VehicleDetails2 from "../../../Components/interface/VehicleDetails2";
import VehicleInfo from "../../../Components/customer-profile/VehicleInfo";
import SmallCard from "../../../Components/interface/SmallCard";
import Button from "../../../Components/interface/Button";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function InsuranceLandingPage(props) {
  const [paidPapers, setPaidPapers] = useState(false);
  const [carImages, setCarImages] = useState([]);
  const [carImage, setCarImage] = useState([]);
  const [products, setProducts] = useState([]);

  const [showVehicleDetails, setShowVehicleDetails] = useState(false);

  const dongle = useSelector((data) => data.dongles);

  const naviage = useNavigate();

  const personalData = useSelector((data) => data.auth.generalLoginData);
  const plate_number =
    personalData?.vehicle_data[dongle.currentVehicle]?.plate_number !==
    undefined
      ? personalData?.vehicle_data[dongle.currentVehicle]?.plate_number
      : personalData?.vendor_vehicle_data[0].plate_number;

  const vehicle_data =
    personalData?.vehicle_data?.length !== 0
      ? personalData?.vehicle_data
      : personalData?.vendor_vehicle_data;

  useEffect(() => {
    const fetchVehicleImages = async () => {
      try {
        const imageRes = await ServiceApi.fetchVehicles();
        setCarImages(imageRes.data);
        const vehicleImage = imageRes.data.find(
          (data) =>
            data.year === vehicle_data[dongle?.currentVehicle]?.vehicle_year &&
            data.model?.toUpperCase() ===
              vehicle_data[
                dongle?.currentVehicle
              ]?.vehicle_model?.toUpperCase() &&
            data.make?.toUpperCase() ===
              vehicle_data[dongle?.currentVehicle]?.vehicle_make?.toUpperCase()
        );

        setCarImage(vehicleImage);
      } catch (err) {
        toast.error("An error occured", {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    };
    fetchVehicleImages();
  }, [dongle?.currentVehicle, vehicle_data]);

  useEffect(() => {
    let fetchOrderHistory = async () => {
      try {
        const fetchedorderRes = await CarPapersAPi.fetchOrderHistory({
          plate_number: plate_number,
        });
        for (let i = 0; i < fetchedorderRes.data.length; i++) {
          const parsedData = JSON.parse(fetchedorderRes.data[i].order_details);
          for (let j = 0; j < parsedData.items.length; j++) {
            if (
              fetchedorderRes.data[i].order_status === "Pending" &&
              parsedData.items[j].name
                .toLowerCase()
                .includes("third party plus")
            ) {
              setPaidPapers(true);
            }
          }
        }
      } catch (err) {
        toast.error("An error Occured", {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    };
    fetchOrderHistory();
  }, [personalData, plate_number]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data from the database
        const response = await ServiceApi.getProductsList({
          category_name: "Paper",
        });
        const filteredEngineOilProducts = response.data.data.data.filter(
          (product) =>
            product.product_title.includes(
              "Mini Comprehensive Insurance  + KYC & Claim Addon"
            )
        );
        setProducts(filteredEngineOilProducts);
      } catch (error) {
        toast.error("An error Occured", {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    };

    fetchData();
  }, []);

  const routeToPage = (link) => {
    naviage(link);
  };
  return (
    <div className={styles.buyDongle}>
      <ToastContainer />
      <NavBar title="Insurance" setShowMenu={props.setShowMenu} />
      <div className={styles.buyDongle__body}>
        <div className={styles.home_unregistered_vehicles}>
          <div className={styles.carType}>
            <div className={styles.buyDongle__wrapper}>
              <img src={carImage?.imageUrl || image4} alt="car" />
            </div>
          </div>

          <VehicleDetails2
            showVehicleDetails={showVehicleDetails}
            setShowVehicleDetails={setShowVehicleDetails}
            vehicle_make={vehicle_data[dongle?.currentVehicle]?.vehicle_make}
            vehicle_model={vehicle_data[dongle?.currentVehicle]?.vehicle_model}
            vehicle_year={vehicle_data[dongle?.currentVehicle]?.vehicle_year}
            vin="Not Available"
            plate_number={vehicle_data[dongle?.currentVehicle]?.plate_number}
          />
          {showVehicleDetails && (
            <VehicleInfo
              carImages={carImages}
              info={vehicle_data[dongle?.currentVehicle]}
            />
          )}
        </div>

        <SmallCard className={styles.buyDongle__order2}>
          <div className={styles.buyDongle__order__text}>
            <h3>Insurance Claim</h3>
            <p>Get your claims done now.</p>
          </div>

          <div className={styles.buyDongle__order__button}>
            <Button onClick={() => routeToPage("/insurance/third-party-claim")}>
              Claim
            </Button>
          </div>
        </SmallCard>
        <div className={styles.insurance_demo}>
          <div className={styles.insurance_demo_header}>
            <p>Cheap Insurance that works!</p>
          </div>
          <div className={styles.insurance_demo_video}></div>

          <div className={styles.insurance_demo_texts}>
            <p>1: Up to ₦500,000 cover for your vehicle</p>
            <p>2: 10% or ₦10,000 in excess</p>
          </div>
        </div>

        <div className={styles.kyc_button}>
          <Button onClick={() => routeToPage("/insurance/insurance-products")}>
            {paidPapers
              ? "Proceed to KYC"
              : `Buy Now - ₦${Number(products[0]?.price).toLocaleString()}`}
          </Button>
        </div>
      </div>
      <BottomNav />
    </div>
  );
}

export default InsuranceLandingPage;
