import React from "react";
import { Outlet } from "react-router";

function InspectionReport() {
  return (
    <div>
      <Outlet />
    </div>
  );
}

export default InspectionReport;
