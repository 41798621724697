import React, { useState } from "react";
import Button from "./Button";
import Footer from "../footer/Footer";
import styles from "./Contact.module.css";
import CustomerScans from "../../utils/api/CustomerScans";

function Contact(props) {
  const [loading, setLoading] = useState(false);
  const [sentMail, setSentMail] = useState(false);

  // Function to trigger mail sending
  const submitErrHandler = async (e) => {
    e.preventDefault();
    const message = `Hi Admin, I am ${
      props.data?.user.fullname || props.data3?.customer.user.fullname
    } with PLATE NUMBER ${
      props.data?.plate_number || props.data3?.customer?.plate_number
    } and PHONE NUMBER ${
      props.data?.user?.phone || props.data3?.customer?.user?.phone
    } and this is a link to my recent diagnosis. https://vendor.motormata.com/smart-scan/report-page/${
      props.id
    }. Please I need to book a session with a mechanic.`;
    try {
      setLoading(true);
      const updateCustomerRes = await CustomerScans.sendTicket({
        to_name: "Admin",
        from_name: `Customer - ${
          props.data?.user.fullname || props.data3?.customer.user.fullname
        }`,
        message,
      });
      setSentMail(true);
      setLoading(false);
      return updateCustomerRes;
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <div>
      <div className={styles.contact}>
        <div className={styles.warning}>
          <p>
            Kindly take action on the following Error Code listed above to avoid
            further damage to your vehicle. You can contact us now for immediate
            service.
          </p>
        </div>
        <div className={styles.buttons}>
          {!loading ? (
            <Button onClick={!sentMail ? submitErrHandler : null}>
              {!sentMail
                ? `Book a Session with an Online Mechanic`
                : `Request has been made to a mechanic, We will reach out to you soon.`}
            </Button>
          ) : (
            <Button>Loading...</Button>
          )}
        </div>
      </div>
      <div className={styles.footer}>
        <Footer />
      </div>
    </div>
  );
}

export default Contact;
