import React, { useEffect, useState } from "react";
import styles from "../../Pages/home/home/BuyDongle.module.css";
import axios from "axios";
import { Link } from "react-router-dom";

function RoutineMaintenance() {
  const [routine, setRoutine] = useState(null);

  // Fetch routine maintenance from airtable
  useEffect(() => {
    axios
      .get(`https://api.airtable.com/v0/appJt0Pp9p2hwxFxf/maintenance`, {
        headers: {
          Authorization: `Bearer pat6OPIoJE33MducG.73a05143632252510c39d3ead36996b5353c5d775d68645e2ff4932eabd1e52d`,
        },
      })
      .then((response) => {
        setRoutine(response.data.records);
      })
      .catch((error) => {
        console.log(error.response.data.error.message);
      });
  }, []);

  const cutText = (text, maxLength) => {
    if (text?.length <= maxLength) {
      return text; // Return the original text if it's shorter than or equal to maxLength.
    } else {
      return text?.substring(0, maxLength) + "..."; // Cut the text and add an ellipsis.
    }
  };
  return (
    <div className={styles.maintanance}>
      <div>
        <h2>Routine Maintenance</h2>
      </div>
      <div className={styles.maintanance_card_cover}>
        {routine?.map((item, index) => (
          <div key={index} className={styles.maintanance_card}>
            <div className={styles.maintanance_card_info}>
              <p>Maintenance Tip</p>

              {/* <p>
                {item?.fields?.Name} <span>Due</span>
              </p> */}
              <p>{cutText(item?.fields?.About, 56)}</p>
            </div>
            <div className={styles.maintanance_card_action}>
              {/* <p>{item?.fields?.Due_date}</p> */}
              <Link to={`/maintenance/${item.id}`}>
                <div>
                  <p>Read more</p>
                </div>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RoutineMaintenance;
