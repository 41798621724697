import axios from "axios";

const url = process.env.REACT_APP_API_URL;

const Notifications = {
  getAllNotifications: (payload) => {
    const response = axios({
      url: `${url}/customer/notificationlog/list/?page=1&page_size=1000`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  getSingleNotification: (payload) => {
    const res = axios({
      url: `${url}/customer/notificationlog/${payload.notification_id}/details/`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  sendNotification: (payload) => {
    const response = axios({
      url: `${url}/customer/notificationlog/store/`,
      method: "POST",
      data: JSON.stringify({
        plate_number: payload.plate_number,
        title: payload.title,
        desc: payload.desc,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },
};

export default Notifications;
