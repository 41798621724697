import React from "react";
import "./ProgressBar.css"; // Import the CSS file for styling

const InsuranceNav = (props) => {
  const steps = [
    { name: "Insurance", number: 1 },
    { name: "Profile", number: 2 },
    { name: "Vehicle Info", number: 3 },
    { name: "Success", number: 4 },
  ];

  return (
    <div className="progress-bar">
      {steps.map((step, index) => (
        <div
          key={index}
          className={`step ${props.currentStep >= step.number ? "active" : ""}`}
        >
          <div className="step-name">{step.name}</div>
          <div
            className={`step-number ${
              props.currentStep >= step.number ? "completed" : ""
            }`}
          >
            {step.number}
          </div>
        </div>
      ))}
    </div>
  );
};

export default InsuranceNav;
