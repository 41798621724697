import React, { useEffect, useState } from "react";
import styles from "./Recents.module.css";
import { useSelector } from "react-redux";
import Button from "../../Components/interface/Button";
import Calender from "../../Components/inputs/Calender";
import SearchInput2 from "../../Components/inputs/SearchInput2";
import CustomerScans from "../../utils/api/CustomerScans";
import { Link } from "react-router-dom";

function Diagnosis() {
  const [data, setData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  // const [filteredResults, setFilteredResults] = useState(data);
  const [startDate, setStartDate] = useState(null);
  const [endFilterDate, setEndFilterDate] = useState(null);
  const [validButton, setValidButton] = useState(false);

  const personalData = useSelector((data) => data.auth);

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  const day = currentDate.getDate();
  const date = `${year}-${month}-${day}`;

  useEffect(() => {
    let fetchVendorReport = async () => {
      try {
        const vendorReportRes = await CustomerScans.getVendorReports({
          access_token: personalData.token,
        });
        setData(vendorReportRes.data);
      } catch (err) {
        console.log(err.response);
      }
    };
    fetchVendorReport();
  }, [personalData.token]);

  useEffect(() => {
    if (startDate !== null && endFilterDate !== null) {
      setValidButton(true);
    } else {
      setValidButton(false);
    }
  }, [startDate, endFilterDate]);

  const formSubmitHandler = (e) => {
    e.preventDefault();
  };
  const searchInputChangeHandler = (e) => {
    setSearchInput(e.target.value);
  };

  // useEffect(() => {
  //   const filterResults = data.filter((item) =>
  //     item?.order_type?.toLowerCase().includes(searchInput?.toLowerCase())
  //   );
  //   setFilteredResults(filterResults);
  // }, [searchInput, data]);

  return (
    <div className={styles.history}>
      <form className={styles.form} onSubmit={formSubmitHandler}>
        <div>
          <SearchInput2
            className={styles.input}
            value={searchInput}
            onChange={searchInputChangeHandler}
            name="search"
            placeholder="Search"
          />
        </div>
        <div className={styles.calender_filter}>
          <Calender
            placeholderText="Start Date"
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            openToDate={new Date(date)}
          />

          <Calender
            placeholderText="End Date"
            selected={endFilterDate}
            onChange={(date) => setEndFilterDate(date)}
            openToDate={new Date(date)}
          />
          <div className={styles.filterButton}>
            <Button disabled={!validButton}>
              <div>
                <svg
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.95062 15.4844H6.51562V7.93213H6.52041L6.51196 7.92208L1.26562 1.67643V0.515625H15.4844V1.66989L10.4878 7.91561L10.4844 7.91288V7.92537V12.9506L7.95062 15.4844ZM7.48438 14.5V14.5156H7.5H7.54291V14.5221L7.55395 14.511L9.51105 12.554L9.51562 12.5585V12.5429V7.58011L14.3719 1.50976L14.3922 1.48438H14.3597H2.40313H2.36959L2.39116 1.51005L7.48438 7.57357V14.5Z"
                    fill="white"
                    stroke="white"
                    stroke-width="0.03125"
                  />
                </svg>
                Filter
              </div>
            </Button>
          </div>
        </div>
      </form>
      <div className={styles.list_wrapper}>
        {data?.map((data) => (
          <Link to={`/report-page/${data.id}`}>
            <div className={styles.list}>
              <div>
                <p className={styles.title}>Diagnosis</p>
                <h5>{data?.scan_data?.vehicle_errors?.length} error codes</h5>
                <p>Total error detected</p>
              </div>
              <div>
                <p>
                  {data?.created_at?.slice(0, 10)}{" "}
                  {data?.created_at?.slice(11, 16)}
                </p>
                <h5> {data?.created_at?.slice(11, 19)}</h5>
                <p>Time of diagnosis</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Diagnosis;
