import React, { useState } from "react";
import styles from "./Services.module.css";
import NavBar from "../../../Components/interface/NavBar";
import BottomNav from "../../../Components/BottomNav";
import Button from "../../../Components/interface/Button";
import { useDispatch, useSelector } from "react-redux";
import { partsActions } from "../../../store/redux-store/spare-parts";
import CustomerScans from "../../../utils/api/CustomerScans";
import Modal from "../../../Components/UI/Modal";
import { useNavigate } from "react-router";

function Cart(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [requestSent, setRequestSent] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const items = useSelector((data) => data.parts.products);

  const personalData = useSelector((data) => data.auth);

  const dongle = useSelector((data) => data.dongles);

  const vehicle_data =
    personalData.generalLoginData?.vehicle_data.length !== 0
      ? personalData.generalLoginData?.vehicle_data
      : personalData.generalLoginData?.vendor_vehicle_data;

  const removeProductHandler = (id) => {
    dispatch(partsActions.removeProduct(id));
  };

  const requestPriceHandler = async () => {
    try {
      setIsLoading(true);
      const mechanicFormRes = await CustomerScans.sendTicket({
        to_name: "Admin",
        from_name: `Spare Parts Request Price for - ${personalData.generalLoginData.fullname}, ${personalData.generalLoginData.email}`,
        message: `A user with phone number ${
          personalData.generalLoginData.phone
        } and a ${vehicle_data[dongle.currentVehicle]?.vehicle_make} ${
          vehicle_data[dongle.currentVehicle]?.vehicle_model
        } ${
          vehicle_data[dongle.currentVehicle]?.vehicle_year
        } vehicle has reuested for the rice of the following spare parts - ${items.map(
          (data) =>
            `${data.title} x${data.quantity} at a price of ${Number(
              data.price
            ).toLocaleString()}`
        )}. Click link to see the images of the spare parts ${items.map(
          (data) => data.image
        )}`,
      });
      dispatch(partsActions.clearCart());

      setRequestSent(true);

      setIsLoading(false);

      return mechanicFormRes.data;
    } catch (err) {
      setIsLoading(false);

      console.log(err?.response?.data);
    }
  };

  const addProductHandler = (item) => {
    dispatch(
      partsActions.addProduct({
        id: item.id,
        title: item.title,
        model: item.model,
        name: item.name,
        quantity: 1,
      })
    );
  };

  const backHandler = () => {
    navigate("/services");
    setRequestSent(false);
  };

  return (
    <section>
      {requestSent && (
        <Modal
          title="Request sent Successfully"
          message="Thank you for using Motormata. Expect a mail from us with the price of the spare-part(s) you requested."
          close={backHandler}
          back="Ok"
          accept=""
          confirm={backHandler}
          success="true"
        />
      )}
      <NavBar title="Cart" setShowMenu={props.setShowMenu} />
      <div className={styles.head}>
        <div className={styles.cart_wrapper}>
          {items.map((data, index) => (
            <div className={styles.cart_item} key={index}>
              <div className={styles.item_image}>
                <div className={styles.item_image_wrapper}>
                  <img src={data.image} alt="item" />
                </div>
              </div>
              <div className={styles.item_info}>
                <p>{data.title}</p>
                <p>Model: {data.model}</p>
                <p>{data.name}</p>
              </div>
              <div className={styles.list_action_buttons}>
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.2728 5.52344C11.2728 5.52344 10.9561 9.45219 10.7723 11.1071C10.6848 11.8975 10.1966 12.3607 9.39683 12.3753C7.87492 12.4027 6.35125 12.4044 4.82992 12.3724C4.0605 12.3566 3.58042 11.8876 3.49467 11.1112C3.30975 9.44169 2.99475 5.52344 2.99475 5.52344"
                    stroke="#939393"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.0798 3.63932H2.18762"
                    stroke="#939393"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.1737 3.64072C9.71579 3.64072 9.32146 3.31697 9.23163 2.86839L9.08988 2.15905C9.00238 1.8318 8.70604 1.60547 8.36829 1.60547H5.89904C5.56129 1.60547 5.26496 1.8318 5.17746 2.15905L5.03571 2.86839C4.94588 3.31697 4.55154 3.64072 4.09363 3.64072"
                    stroke="#939393"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div className={styles.list_action_quantity_buttons}>
                  <svg
                    onClick={() => removeProductHandler(data.id)}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="16" height="16" rx="4" fill="#088457" />
                    <path
                      d="M4 8H12.75"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <p>{data.quantity}</p>

                  <svg
                    onClick={() => addProductHandler(data)}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="16" height="16" rx="4" fill="#088457" />
                    <path
                      d="M4 8H12"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 4L8 12"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* <div className={styles.total}>
          <div>
            <p>Sub Total</p>
            <h4>₦ 100,000</h4>
          </div>
          <div>
            <p>Service charge</p>
            <h4>₦ 100,000</h4>
          </div>
          <div className={styles.grandTotal}>
            <p>Grand Total</p>
            <h4>₦ 10,000,000</h4>
          </div>
        </div> */}
        <div>
          {!isLoading ? (
            <Button onClick={requestPriceHandler}>
              Request for price or to buy
            </Button>
          ) : (
            <Button>Loading...</Button>
          )}
        </div>
      </div>
      <BottomNav />
    </section>
  );
}

export default Cart;
