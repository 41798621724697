import React, { useCallback, useEffect, useState } from "react";
import styles from "./Services.module.css";
import serviceImg2 from "../../../asset/images/Save Money/3.jpg";
import serviceImg3 from "../../../asset/images/Save Money/4.jpg";
import NavBar from "../../../Components/interface/NavBar";
import BottomNav from "../../../Components/BottomNav";
import ServiceApi from "../../../utils/api/ServiceApi";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "../../../Components/UI/Modal";

function ScheduledServices(props) {
  const [product, setProduct] = useState([]);
  const [filteredProduct, setFilteredProduct] = useState([]);
  const [engineOilProducts, setEngineOilProducts] = useState([]);
  const [cartList, setCartList] = useState([]);
  const [filterIndex, setFilterIndex] = useState(null);
  const [initLoading, setInitLoading] = useState(false);

  const dongle = useSelector((data) => data.dongles);
  const typesOfEngineOil = ["Full Synthetic", "Semi Synthetic", "Mineral Oil"];

  const personalData = useSelector((data) => data.auth.generalLoginData);

  const plate_number =
    personalData?.vehicle_data[dongle.currentVehicle]?.plate_number !==
    undefined
      ? personalData?.vehicle_data[dongle.currentVehicle]?.plate_number
      : personalData?.vendor_vehicle_data[0].plate_number;

  const { id } = useParams();

  const images = [serviceImg2, serviceImg3];

  useEffect(() => {
    const fetchData = async () => {
      setInitLoading(true);
      try {
        // Fetch data from the database
        const response = await ServiceApi.getProductsList({
          category_name: "Routine Service",
        });

        const productResponse = await ServiceApi.getProductsList({
          category_name: "Product",
        });
        const res = response.data.data.data.find(
          (data) => data.id === Number(id)
        );

        const filteredEngineOilProducts = productResponse.data.data.data.filter(
          (product) =>
            product.product_title.toLowerCase().includes("engine oil")
        );

        setEngineOilProducts(filteredEngineOilProducts);

        setFilteredProduct(filteredEngineOilProducts);

        setProduct(res); // Update state with fetched data
        setInitLoading(false);
      } catch (error) {
        setInitLoading(false);
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [id]);

  const getCart = useCallback(() => {
    const getCartList = async () => {
      try {
        setInitLoading(true);
        const checkoutRes = await ServiceApi.checkout({
          plate_no: plate_number,
        });
        setCartList(checkoutRes.data);
        setInitLoading(false);
        return checkoutRes.data;
      } catch (err) {
        setInitLoading(false);
        toast.error(err?.response?.data?.message, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        console.log(err?.response?.data?.message);
      }
    };
    getCartList();
  }, [plate_number]);

  useEffect(() => {
    getCart();
  }, [getCart]);

  const addToCartHandler = async (product_id) => {
    try {
      const addedCartRes = await ServiceApi.addToCart({
        plate_no: plate_number,
        product_id,
      });
      toast.success(addedCartRes.data.message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      getCart();
      return addedCartRes.data;
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const removeSuffix = (inputString) => {
    const indexOfDash = inputString?.indexOf(" - ");
    return indexOfDash !== -1
      ? inputString?.substring(0, indexOfDash)
      : inputString;
  };

  const removePrefix = (inputString) => {
    const indexOfDash = inputString?.indexOf(" - ");
    return indexOfDash !== -1
      ? inputString?.substring(indexOfDash + 3)
      : inputString;
  };

  const filterOil = (oilType, index) => {
    if (filterIndex === index) {
      setFilterIndex(null);
    } else {
      setFilterIndex(index);
      const filteredProductData = engineOilProducts.filter((data) =>
        data?.product_title?.toLowerCase().includes(oilType.toLowerCase())
      );
      setFilteredProduct(filteredProductData);
    }
  };

  useEffect(() => {
    if (filterIndex === null) {
      setFilteredProduct(engineOilProducts);
    }
  }, [filterIndex, engineOilProducts]);

  return (
    <section>
      <ToastContainer />
      <NavBar title="Products" setShowMenu={props.setShowMenu} />
      {initLoading ? (
        <Modal message="" back="" accept="" loader="true" />
      ) : (
        <div className={styles.head}>
          <div className={styles.main_hero}>
            <div className={styles.back_btn}>
              <Link to="/spare-parts">Back</Link>
            </div>
            <div className={styles.spareParts}>
              <div className={styles.header_img}>
                {images.map((data, index) => (
                  <div className={styles.header_img_wrapper} key={index}>
                    <div className={styles.header_img_wrapper_inner}>
                      <img src={data} alt="service" />
                    </div>
                  </div>
                ))}
              </div>

              <div className={styles.spareParts_container}>
                <div className={styles.spareParts}>
                  <p className={styles.para}>Scheduled Packages</p>
                </div>
              </div>

              <div className={styles.workShopResultList}>
                <div className={styles.workshopImgWrapper}>
                  <img
                    src={product?.product_image}
                    alt={product?.product_title}
                  />
                </div>
                <div className={styles.desc}>
                  <h4>{removeSuffix(product?.product_title)}</h4>
                  <p>{removePrefix(product?.product_title)}</p>
                </div>
                <div className={styles.price_info}>
                  <div className={styles.price}>
                    <p>₦{Number(product.price).toLocaleString()}</p>
                  </div>
                  <div
                    className={styles.icon}
                    onClick={() => addToCartHandler(product.magento_product_id)}
                  >
                    <p>Add</p>
                  </div>
                </div>
              </div>

              {engineOilProducts.length > 1 && (
                <div className={styles.spareParts}>
                  <p className={styles.para}>Add ons engine oil</p>

                  <div className={styles.spareParts_filter}>
                    {typesOfEngineOil.map((data, index) => (
                      <div
                        onClick={() => filterOil(data, index)}
                        style={{
                          backgroundColor: filterIndex === index ? "black" : "",
                        }}
                      >
                        <p
                          style={{
                            color: filterIndex === index ? "white" : "",
                          }}
                        >
                          {data}
                        </p>
                      </div>
                    ))}
                  </div>
                  <div className={styles.spareParts_inner_2}>
                    {filteredProduct?.map((product) => (
                      <div
                        className={styles.spareParts_card_2}
                        key={product.id}
                      >
                        <div className={styles.spareParts_card_image_wrapper}>
                          <div
                            className={
                              styles.spareParts_card_image_wrapper_inner
                            }
                          >
                            <img
                              src={product.product_image}
                              alt={product.product_title}
                            />
                          </div>
                        </div>
                        <div className={styles.spareParts_name}>
                          <div>
                            <p>{removePrefix(product.product_title)}</p>
                          </div>

                          <div>
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.99992 13.999L7.03325 13.1323C5.91103 12.1212 4.98325 11.249 4.24992 10.5156C3.51659 9.78229 2.93325 9.12385 2.49992 8.54029C2.06659 7.95718 1.76392 7.42118 1.59192 6.93229C1.41992 6.4434 1.3337 5.9434 1.33325 5.43229C1.33325 4.38785 1.68325 3.51562 2.38325 2.81562C3.08325 2.11562 3.95547 1.76562 4.99992 1.76562C5.5777 1.76562 6.1277 1.88785 6.64992 2.13229C7.17214 2.37674 7.62214 2.72118 7.99992 3.16562C8.3777 2.72118 8.8277 2.37674 9.34992 2.13229C9.87214 1.88785 10.4221 1.76562 10.9999 1.76562C12.0444 1.76562 12.9166 2.11562 13.6166 2.81562C14.3166 3.51562 14.6666 4.38785 14.6666 5.43229C14.6666 5.9434 14.5804 6.4434 14.4079 6.93229C14.2355 7.42118 13.9328 7.95718 13.4999 8.54029C13.0666 9.12385 12.4833 9.78229 11.7499 10.5156C11.0166 11.249 10.0888 12.1212 8.96659 13.1323L7.99992 13.999ZM7.99992 12.199C9.06659 11.2434 9.94436 10.4238 10.6333 9.74029C11.3221 9.05674 11.8666 8.46251 12.2666 7.95763C12.6666 7.45185 12.9444 7.00163 13.0999 6.60696C13.2555 6.21229 13.3333 5.82074 13.3333 5.43229C13.3333 4.76562 13.111 4.21007 12.6666 3.76562C12.2221 3.32118 11.6666 3.09896 10.9999 3.09896C10.4777 3.09896 9.99436 3.24607 9.54992 3.54029C9.10547 3.83451 8.79992 4.20963 8.63325 4.66563H7.36659C7.19992 4.21007 6.89436 3.83496 6.44992 3.54029C6.00547 3.24562 5.52214 3.09851 4.99992 3.09896C4.33325 3.09896 3.7777 3.32118 3.33325 3.76562C2.88881 4.21007 2.66659 4.76562 2.66659 5.43229C2.66659 5.82118 2.74436 6.21296 2.89992 6.60763C3.05547 7.00229 3.33325 7.45229 3.73325 7.95763C4.13325 8.46296 4.6777 9.0574 5.36659 9.74096C6.05547 10.4245 6.93325 11.2438 7.99992 12.199Z"
                                fill="#008069"
                              />
                            </svg>
                          </div>
                        </div>
                        <div className={styles.spareParts_name}>
                          <div>
                            <p>₦{Number(product.price).toLocaleString()}</p>
                          </div>

                          <div
                            onClick={() =>
                              addToCartHandler(product.magento_product_id)
                            }
                          >
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.5 13.5C5.77614 13.5 6 13.2761 6 13C6 12.7239 5.77614 12.5 5.5 12.5C5.22386 12.5 5 12.7239 5 13C5 13.2761 5.22386 13.5 5.5 13.5Z"
                                stroke="#008069"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M12.5 13.5C12.7761 13.5 13 13.2761 13 13C13 12.7239 12.7761 12.5 12.5 12.5C12.2239 12.5 12 12.7239 12 13C12 13.2761 12.2239 13.5 12.5 13.5Z"
                                stroke="#008069"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M1.5 2.5H3.5L5 11H13"
                                stroke="#008069"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M5 9H12.795C12.8528 9.00004 12.9089 8.98004 12.9536 8.9434C12.9983 8.90676 13.029 8.85576 13.0403 8.79906L13.9403 4.29906C13.9476 4.26278 13.9467 4.22533 13.9377 4.18943C13.9288 4.15352 13.9119 4.12006 13.8885 4.09145C13.865 4.06284 13.8355 4.03979 13.802 4.02398C13.7686 4.00816 13.732 3.99997 13.695 4H4"
                                stroke="#008069"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          {cartList?.cart_list?.length !== 0 && (
            <Link to="/spare-parts/cart">
              <div className={styles.cart_button}>
                <p>{cartList?.cart_list?.length - 1} Items Added</p>
                <p> Go to Cart</p>
              </div>
            </Link>
          )}
        </div>
      )}{" "}
      <BottomNav />
    </section>
  );
}

export default ScheduledServices;
