import React, { useEffect, useRef, useState } from "react";
import styles from "./HomeLivemetrics.module.css";
import CarPapersAPi from "../../utils/api/CarPapersApi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Flickity from "flickity";
import "flickity/css/flickity.css";

function HomeVehiclePapers() {
  const [papersData, setPapersData] = useState(null);
  const [orderInProgress, setOrderInProgress] = useState(false);
  const [isPaperDue, setIsPaperDue] = useState({
    vehicle_licenseExpiry: false,
    roadExpiry: false,
    insuranceExpiry: false,
  });
  const personalData = useSelector((data) => data.auth);

  const dongle = useSelector((data) => data.dongles);

  const flickityRef = useRef(null);

  useEffect(() => {
    // Initialize Flickity when the component mounts
    flickityRef.current = new Flickity(".carousel", {
      cellAlign: "center",
      contain: false,
      wrapAround: true,
      autoPlay: 2000,
      prevNextButtons: false,
      pageDots: false,
    });

    // Clean up and destroy Flickity when the component unmounts
    return () => flickityRef.current.destroy();
  }, []);

  // function to get if any paper will expire soon
  const checkPapersExpiry = (expiry_date) => {
    const currentDate = new Date();

    const expiry = new Date(expiry_date);
    const timeDiff = expiry - currentDate;
    const timeToExpiry = timeDiff / (1000 * 60 * 60 * 24);

    // check days to expiry date to trgger renew button
    if (timeToExpiry < 30) {
      return true;
    } else {
      return false;
    }
  };

  // check if papers records are available
  const isRecordFound = (papersData) => {
    return (
      papersData.insurance !== "No Record Found" &&
      papersData.road !== "No Record Found" &&
      papersData.vehicle_license !== "No Record Found"
    );
  };

  const plate_number =
    personalData.generalLoginData?.vehicle_data?.[dongle.currentVehicle]
      ?.plate_number !== undefined
      ? personalData.generalLoginData?.vehicle_data[dongle.currentVehicle]
          ?.plate_number
      : personalData.generalLoginData?.vendor_vehicle_data[
          dongle.currentVehicle
        ]?.plate_number;

  useEffect(() => {
    let fetchOrderHistory = async () => {
      try {
        const fetchedorderRes = await CarPapersAPi.fetchOrderHistory({
          plate_number: plate_number,
        });

        for (let i = 0; i < fetchedorderRes.data.length; i++) {
          if (
            fetchedorderRes.data[i].order_status === "Pending" &&
            fetchedorderRes.data[i].order_type.toLowerCase().includes("papers")
          ) {
            setOrderInProgress(true);
          }
        }
      } catch (err) {
        console.log(err?.response?.data);
      }
    };
    fetchOrderHistory();
  }, [personalData, plate_number]);

  useEffect(() => {
    const fetchPapers = async () => {
      try {
        const papersRes = await CarPapersAPi.getPaperData({
          plate_no: plate_number,
        });
        setPapersData(papersRes.data);

        const roadExpiry = checkPapersExpiry(
          papersRes.data?.road[0]?.expiry_date
        );

        const insuranceExpiry = checkPapersExpiry(
          papersRes.data?.insurance[0]?.expiry_date
        );

        const vehicle_licenseExpiry = checkPapersExpiry(
          papersRes.data?.vehicle_license[0]?.expiry_date
        );

        setIsPaperDue({
          vehicle_licenseExpiry,
          insuranceExpiry,
          roadExpiry,
        });
        // check if papers record is found
        if (!isRecordFound(papersRes.data)) {
          setIsPaperDue({
            vehicle_licenseExpiry: true,
            insuranceExpiry: true,
            roadExpiry: true,
          });
        }
      } catch (err) {
        console.log(err.response.error);
      }
    };
    fetchPapers();
  }, [dongle.currentVehicle, plate_number]);

  const checkExpiryStatus = (expiryDate) => {
    const currentDate = new Date();
    const expiry = new Date(expiryDate);
    const timeDiff = expiry - currentDate;
    const daysToExpiry = timeDiff / (1000 * 60 * 60 * 24);

    if (daysToExpiry > 30) {
      return {
        color: "#008069",
        background: "#cbf8c994",
        status: "Active",
      };
    } else if (daysToExpiry > 0 && daysToExpiry < 30) {
      return {
        color: "#F90",
        background: "#FFF9E0",
        status: "Expires soon",
      };
    } else {
      return {
        color: "#FD4D1E",
        background: "#FFF3F0",
        status: "Expired",
      };
    }
  };

  const formatDateToShortMonthYear = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "short" };

    const time = date.toLocaleDateString(undefined, options);

    if (time === "Invalid Date") {
      return "Invalid";
    } else {
      return time;
    }
  };

  return (
    <div className={styles.papers}>
      <div className={styles.papers_inner}>
        <div className={styles.papers_head}>
          <div>
            <p>Car papers</p>
          </div>
          <div>
            <div>
              <svg
                width="12"
                height="13"
                viewBox="0 0 12 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="6" cy="6.5" r="6" fill="#0AB34E" />
              </svg>
              <p>Updated</p>
            </div>

            <div>
              <svg
                width="12"
                height="13"
                viewBox="0 0 12 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="6" cy="6.5" r="6" fill="#FFB200" />
              </svg>

              <p>Due soon</p>
            </div>

            <div>
              <svg
                width="12"
                height="13"
                viewBox="0 0 12 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="6" cy="6.5" r="6" fill="#FD4D1E" />
              </svg>

              <p>Expired</p>
            </div>
          </div>
        </div>
        <div
          className="carousel"
          // className={styles.home_live_metrics_inner}
        >
          <div
            style={{
              backgroundColor: checkExpiryStatus(
                papersData?.vehicle_license[0]?.expiry_date
              ).background,
              border: `1px solid ${
                checkExpiryStatus(papersData?.vehicle_license[0]?.expiry_date)
                  .color
              }`,
            }}
            className={styles.papers_card}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 3H11V4H5V3ZM5 5H11V6H5V5ZM5 12H8V13H5V12ZM5 7H8V8H5V7Z"
                fill={
                  checkExpiryStatus(papersData?.vehicle_license[0]?.expiry_date)
                    .color
                }
              />
              <path
                d="M12 15H4C3.73486 14.9997 3.48066 14.8943 3.29319 14.7068C3.10571 14.5193 3.00026 14.2651 3 14V2C3.00026 1.73486 3.10571 1.48066 3.29319 1.29319C3.48066 1.10571 3.73486 1.00026 4 1H12C12.2651 1.00026 12.5193 1.10571 12.7068 1.29319C12.8943 1.48066 12.9997 1.73486 13 2V14C12.9997 14.2651 12.8943 14.5193 12.7068 14.7068C12.5193 14.8943 12.2651 14.9997 12 15ZM4 2V14H12V2H4Z"
                fill={
                  checkExpiryStatus(papersData?.vehicle_license[0]?.expiry_date)
                    .color
                }
              />
              <g clip-path="url(#clip0_9710_50601)">
                <path
                  d="M10.2045 9.66661L10.5556 10.4444H8.77786L9.09341 9.66661H10.2045ZM9.00008 10.6666C8.87786 10.6666 8.77786 10.7666 8.77786 10.8888C8.77786 11.0111 8.87786 11.1111 9.00008 11.1111C9.1223 11.1111 9.2223 11.0111 9.2223 10.8888C9.2223 10.7666 9.1223 10.6666 9.00008 10.6666ZM10.3334 10.6666C10.2112 10.6666 10.1112 10.7666 10.1112 10.8888C10.1112 11.0111 10.2112 11.1111 10.3334 11.1111C10.4556 11.1111 10.5556 11.0111 10.5556 10.8888C10.5556 10.7666 10.4556 10.6666 10.3334 10.6666ZM11.6667 9.11106V10.4444C11.6667 11.6777 10.8134 12.8311 9.66675 13.1111C8.52008 12.8311 7.66675 11.6777 7.66675 10.4444V9.11106L9.66675 8.22217L11.6667 9.11106ZM11.0001 10.6666L10.5201 9.5555C10.4756 9.42661 10.3512 9.33328 10.2045 9.33328H9.09341C8.94675 9.33328 8.8223 9.42661 8.77786 9.5555L8.33341 10.6666V11.5555C8.33341 11.6777 8.43341 11.7777 8.55564 11.7777H8.77786C8.90008 11.7777 9.00008 11.6777 9.00008 11.5555V11.3333H10.3334V11.5555C10.3334 11.6777 10.4334 11.7777 10.5556 11.7777H10.7779C10.9001 11.7777 11.0001 11.6777 11.0001 11.5555V10.6666Z"
                  fill={
                    checkExpiryStatus(
                      papersData?.vehicle_license[0]?.expiry_date
                    ).color
                  }
                />
              </g>
              <defs>
                <clipPath id="clip0_9710_50601">
                  <rect
                    width="5.33333"
                    height="5.33333"
                    fill="white"
                    transform="translate(7 8)"
                  />
                </clipPath>
              </defs>
            </svg>

            <p>Vehicle License</p>

            <p
              style={{
                color: checkExpiryStatus(
                  papersData?.vehicle_license[0]?.expiry_date
                ).color,
              }}
            >
              {papersData?.vehicle_license === "No Record Found"
                ? "No record"
                : formatDateToShortMonthYear(
                    papersData?.vehicle_license[0]?.expiry_date
                  )}
            </p>
          </div>

          <div
            style={{
              backgroundColor: checkExpiryStatus(
                papersData?.insurance[0]?.expiry_date
              ).background,
              border: `1px solid ${
                checkExpiryStatus(papersData?.insurance[0]?.expiry_date).color
              }`,
            }}
            className={styles.papers_card}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 3H11V4H5V3ZM5 5H11V6H5V5ZM5 12H8V13H5V12ZM5 7H8V8H5V7Z"
                fill={
                  checkExpiryStatus(papersData?.insurance[0]?.expiry_date).color
                }
              />
              <path
                d="M12 15H4C3.73486 14.9997 3.48066 14.8943 3.29319 14.7068C3.10571 14.5193 3.00026 14.2651 3 14V2C3.00026 1.73486 3.10571 1.48066 3.29319 1.29319C3.48066 1.10571 3.73486 1.00026 4 1H12C12.2651 1.00026 12.5193 1.10571 12.7068 1.29319C12.8943 1.48066 12.9997 1.73486 13 2V14C12.9997 14.2651 12.8943 14.5193 12.7068 14.7068C12.5193 14.8943 12.2651 14.9997 12 15ZM4 2V14H12V2H4Z"
                fill={
                  checkExpiryStatus(papersData?.insurance[0]?.expiry_date).color
                }
              />
              <g clip-path="url(#clip0_9710_50601)">
                <path
                  d="M10.2045 9.66661L10.5556 10.4444H8.77786L9.09341 9.66661H10.2045ZM9.00008 10.6666C8.87786 10.6666 8.77786 10.7666 8.77786 10.8888C8.77786 11.0111 8.87786 11.1111 9.00008 11.1111C9.1223 11.1111 9.2223 11.0111 9.2223 10.8888C9.2223 10.7666 9.1223 10.6666 9.00008 10.6666ZM10.3334 10.6666C10.2112 10.6666 10.1112 10.7666 10.1112 10.8888C10.1112 11.0111 10.2112 11.1111 10.3334 11.1111C10.4556 11.1111 10.5556 11.0111 10.5556 10.8888C10.5556 10.7666 10.4556 10.6666 10.3334 10.6666ZM11.6667 9.11106V10.4444C11.6667 11.6777 10.8134 12.8311 9.66675 13.1111C8.52008 12.8311 7.66675 11.6777 7.66675 10.4444V9.11106L9.66675 8.22217L11.6667 9.11106ZM11.0001 10.6666L10.5201 9.5555C10.4756 9.42661 10.3512 9.33328 10.2045 9.33328H9.09341C8.94675 9.33328 8.8223 9.42661 8.77786 9.5555L8.33341 10.6666V11.5555C8.33341 11.6777 8.43341 11.7777 8.55564 11.7777H8.77786C8.90008 11.7777 9.00008 11.6777 9.00008 11.5555V11.3333H10.3334V11.5555C10.3334 11.6777 10.4334 11.7777 10.5556 11.7777H10.7779C10.9001 11.7777 11.0001 11.6777 11.0001 11.5555V10.6666Z"
                  fill={
                    checkExpiryStatus(papersData?.insurance[0]?.expiry_date)
                      .color
                  }
                />
              </g>
              <defs>
                <clipPath id="clip0_9710_50601">
                  <rect
                    width="5.33333"
                    height="5.33333"
                    fill="white"
                    transform="translate(7 8)"
                  />
                </clipPath>
              </defs>
            </svg>

            <p>Vehicle Insurance</p>

            <p
              style={{
                color: checkExpiryStatus(papersData?.insurance[0]?.expiry_date)
                  .color,
              }}
            >
              {papersData?.insurance === "No Record Found"
                ? "No record"
                : formatDateToShortMonthYear(
                    papersData?.insurance[0]?.expiry_date
                  )}
            </p>
          </div>

          <div
            style={{
              backgroundColor: checkExpiryStatus(
                papersData?.road[0]?.expiry_date
              ).background,
              border: `1px solid ${
                checkExpiryStatus(papersData?.road[0]?.expiry_date).color
              }`,
            }}
            className={styles.papers_card}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 3H11V4H5V3ZM5 5H11V6H5V5ZM5 12H8V13H5V12ZM5 7H8V8H5V7Z"
                fill={checkExpiryStatus(papersData?.road[0]?.expiry_date).color}
              />
              <path
                d="M12 15H4C3.73486 14.9997 3.48066 14.8943 3.29319 14.7068C3.10571 14.5193 3.00026 14.2651 3 14V2C3.00026 1.73486 3.10571 1.48066 3.29319 1.29319C3.48066 1.10571 3.73486 1.00026 4 1H12C12.2651 1.00026 12.5193 1.10571 12.7068 1.29319C12.8943 1.48066 12.9997 1.73486 13 2V14C12.9997 14.2651 12.8943 14.5193 12.7068 14.7068C12.5193 14.8943 12.2651 14.9997 12 15ZM4 2V14H12V2H4Z"
                fill={checkExpiryStatus(papersData?.road[0]?.expiry_date).color}
              />
              <g clip-path="url(#clip0_9710_50601)">
                <path
                  d="M10.2045 9.66661L10.5556 10.4444H8.77786L9.09341 9.66661H10.2045ZM9.00008 10.6666C8.87786 10.6666 8.77786 10.7666 8.77786 10.8888C8.77786 11.0111 8.87786 11.1111 9.00008 11.1111C9.1223 11.1111 9.2223 11.0111 9.2223 10.8888C9.2223 10.7666 9.1223 10.6666 9.00008 10.6666ZM10.3334 10.6666C10.2112 10.6666 10.1112 10.7666 10.1112 10.8888C10.1112 11.0111 10.2112 11.1111 10.3334 11.1111C10.4556 11.1111 10.5556 11.0111 10.5556 10.8888C10.5556 10.7666 10.4556 10.6666 10.3334 10.6666ZM11.6667 9.11106V10.4444C11.6667 11.6777 10.8134 12.8311 9.66675 13.1111C8.52008 12.8311 7.66675 11.6777 7.66675 10.4444V9.11106L9.66675 8.22217L11.6667 9.11106ZM11.0001 10.6666L10.5201 9.5555C10.4756 9.42661 10.3512 9.33328 10.2045 9.33328H9.09341C8.94675 9.33328 8.8223 9.42661 8.77786 9.5555L8.33341 10.6666V11.5555C8.33341 11.6777 8.43341 11.7777 8.55564 11.7777H8.77786C8.90008 11.7777 9.00008 11.6777 9.00008 11.5555V11.3333H10.3334V11.5555C10.3334 11.6777 10.4334 11.7777 10.5556 11.7777H10.7779C10.9001 11.7777 11.0001 11.6777 11.0001 11.5555V10.6666Z"
                  fill={
                    checkExpiryStatus(papersData?.road[0]?.expiry_date).color
                  }
                />
              </g>
              <defs>
                <clipPath id="clip0_9710_50601">
                  <rect
                    width="5.33333"
                    height="5.33333"
                    fill="white"
                    transform="translate(7 8)"
                  />
                </clipPath>
              </defs>
            </svg>

            <p>Road Worthiness</p>

            <p
              style={{
                color: checkExpiryStatus(papersData?.road[0]?.expiry_date)
                  .color,
              }}
            >
              {papersData?.road === "No Record Found"
                ? "No record"
                : formatDateToShortMonthYear(papersData?.road[0]?.expiry_date)}
            </p>
          </div>

          {/* <div className={styles.papers_card}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 3H11V4H5V3ZM5 5H11V6H5V5ZM5 12H8V13H5V12ZM5 7H8V8H5V7Z"
                fill="#008069"
              />
              <path
                d="M12 15H4C3.73486 14.9997 3.48066 14.8943 3.29319 14.7068C3.10571 14.5193 3.00026 14.2651 3 14V2C3.00026 1.73486 3.10571 1.48066 3.29319 1.29319C3.48066 1.10571 3.73486 1.00026 4 1H12C12.2651 1.00026 12.5193 1.10571 12.7068 1.29319C12.8943 1.48066 12.9997 1.73486 13 2V14C12.9997 14.2651 12.8943 14.5193 12.7068 14.7068C12.5193 14.8943 12.2651 14.9997 12 15ZM4 2V14H12V2H4Z"
                fill="#008069"
              />
              <g clip-path="url(#clip0_9710_50601)">
                <path
                  d="M10.2045 9.66661L10.5556 10.4444H8.77786L9.09341 9.66661H10.2045ZM9.00008 10.6666C8.87786 10.6666 8.77786 10.7666 8.77786 10.8888C8.77786 11.0111 8.87786 11.1111 9.00008 11.1111C9.1223 11.1111 9.2223 11.0111 9.2223 10.8888C9.2223 10.7666 9.1223 10.6666 9.00008 10.6666ZM10.3334 10.6666C10.2112 10.6666 10.1112 10.7666 10.1112 10.8888C10.1112 11.0111 10.2112 11.1111 10.3334 11.1111C10.4556 11.1111 10.5556 11.0111 10.5556 10.8888C10.5556 10.7666 10.4556 10.6666 10.3334 10.6666ZM11.6667 9.11106V10.4444C11.6667 11.6777 10.8134 12.8311 9.66675 13.1111C8.52008 12.8311 7.66675 11.6777 7.66675 10.4444V9.11106L9.66675 8.22217L11.6667 9.11106ZM11.0001 10.6666L10.5201 9.5555C10.4756 9.42661 10.3512 9.33328 10.2045 9.33328H9.09341C8.94675 9.33328 8.8223 9.42661 8.77786 9.5555L8.33341 10.6666V11.5555C8.33341 11.6777 8.43341 11.7777 8.55564 11.7777H8.77786C8.90008 11.7777 9.00008 11.6777 9.00008 11.5555V11.3333H10.3334V11.5555C10.3334 11.6777 10.4334 11.7777 10.5556 11.7777H10.7779C10.9001 11.7777 11.0001 11.6777 11.0001 11.5555V10.6666Z"
                  fill="#008069"
                />
              </g>
              <defs>
                <clipPath id="clip0_9710_50601">
                  <rect
                    width="5.33333"
                    height="5.33333"
                    fill="white"
                    transform="translate(7 8)"
                  />
                </clipPath>
              </defs>
            </svg>

            <p>Comprehensive Insurance</p>

            <p>Soon</p>
          </div> */}

          <div
            style={{
              backgroundColor: checkExpiryStatus(
                papersData?.road[0]?.expiry_date
              ).background,
              border: `1px solid ${
                checkExpiryStatus(papersData?.road[0]?.expiry_date).color
              }`,
            }}
            className={styles.papers_card}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 3H11V4H5V3ZM5 5H11V6H5V5ZM5 12H8V13H5V12ZM5 7H8V8H5V7Z"
                fill={checkExpiryStatus(papersData?.road[0]?.expiry_date).color}
              />
              <path
                d="M12 15H4C3.73486 14.9997 3.48066 14.8943 3.29319 14.7068C3.10571 14.5193 3.00026 14.2651 3 14V2C3.00026 1.73486 3.10571 1.48066 3.29319 1.29319C3.48066 1.10571 3.73486 1.00026 4 1H12C12.2651 1.00026 12.5193 1.10571 12.7068 1.29319C12.8943 1.48066 12.9997 1.73486 13 2V14C12.9997 14.2651 12.8943 14.5193 12.7068 14.7068C12.5193 14.8943 12.2651 14.9997 12 15ZM4 2V14H12V2H4Z"
                fill={checkExpiryStatus(papersData?.road[0]?.expiry_date).color}
              />
              <g clip-path="url(#clip0_9710_50601)">
                <path
                  d="M10.2045 9.66661L10.5556 10.4444H8.77786L9.09341 9.66661H10.2045ZM9.00008 10.6666C8.87786 10.6666 8.77786 10.7666 8.77786 10.8888C8.77786 11.0111 8.87786 11.1111 9.00008 11.1111C9.1223 11.1111 9.2223 11.0111 9.2223 10.8888C9.2223 10.7666 9.1223 10.6666 9.00008 10.6666ZM10.3334 10.6666C10.2112 10.6666 10.1112 10.7666 10.1112 10.8888C10.1112 11.0111 10.2112 11.1111 10.3334 11.1111C10.4556 11.1111 10.5556 11.0111 10.5556 10.8888C10.5556 10.7666 10.4556 10.6666 10.3334 10.6666ZM11.6667 9.11106V10.4444C11.6667 11.6777 10.8134 12.8311 9.66675 13.1111C8.52008 12.8311 7.66675 11.6777 7.66675 10.4444V9.11106L9.66675 8.22217L11.6667 9.11106ZM11.0001 10.6666L10.5201 9.5555C10.4756 9.42661 10.3512 9.33328 10.2045 9.33328H9.09341C8.94675 9.33328 8.8223 9.42661 8.77786 9.5555L8.33341 10.6666V11.5555C8.33341 11.6777 8.43341 11.7777 8.55564 11.7777H8.77786C8.90008 11.7777 9.00008 11.6777 9.00008 11.5555V11.3333H10.3334V11.5555C10.3334 11.6777 10.4334 11.7777 10.5556 11.7777H10.7779C10.9001 11.7777 11.0001 11.6777 11.0001 11.5555V10.6666Z"
                  fill={
                    checkExpiryStatus(papersData?.road[0]?.expiry_date).color
                  }
                />
              </g>
              <defs>
                <clipPath id="clip0_9710_50601">
                  <rect
                    width="5.33333"
                    height="5.33333"
                    fill="white"
                    transform="translate(7 8)"
                  />
                </clipPath>
              </defs>
            </svg>

            <p>Proof of ownership</p>

            <p
              style={{
                color: checkExpiryStatus(papersData?.road[0]?.expiry_date)
                  .color,
              }}
            >
              {papersData?.road === "No Record Found"
                ? "No record"
                : formatDateToShortMonthYear(papersData?.road[0]?.expiry_date)}
            </p>
          </div>
          {/* 
          <div className={styles.papers_card}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 3H11V4H5V3ZM5 5H11V6H5V5ZM5 12H8V13H5V12ZM5 7H8V8H5V7Z"
                fill="#008069"
              />
              <path
                d="M12 15H4C3.73486 14.9997 3.48066 14.8943 3.29319 14.7068C3.10571 14.5193 3.00026 14.2651 3 14V2C3.00026 1.73486 3.10571 1.48066 3.29319 1.29319C3.48066 1.10571 3.73486 1.00026 4 1H12C12.2651 1.00026 12.5193 1.10571 12.7068 1.29319C12.8943 1.48066 12.9997 1.73486 13 2V14C12.9997 14.2651 12.8943 14.5193 12.7068 14.7068C12.5193 14.8943 12.2651 14.9997 12 15ZM4 2V14H12V2H4Z"
                fill="#008069"
              />
              <g clip-path="url(#clip0_9710_50601)">
                <path
                  d="M10.2045 9.66661L10.5556 10.4444H8.77786L9.09341 9.66661H10.2045ZM9.00008 10.6666C8.87786 10.6666 8.77786 10.7666 8.77786 10.8888C8.77786 11.0111 8.87786 11.1111 9.00008 11.1111C9.1223 11.1111 9.2223 11.0111 9.2223 10.8888C9.2223 10.7666 9.1223 10.6666 9.00008 10.6666ZM10.3334 10.6666C10.2112 10.6666 10.1112 10.7666 10.1112 10.8888C10.1112 11.0111 10.2112 11.1111 10.3334 11.1111C10.4556 11.1111 10.5556 11.0111 10.5556 10.8888C10.5556 10.7666 10.4556 10.6666 10.3334 10.6666ZM11.6667 9.11106V10.4444C11.6667 11.6777 10.8134 12.8311 9.66675 13.1111C8.52008 12.8311 7.66675 11.6777 7.66675 10.4444V9.11106L9.66675 8.22217L11.6667 9.11106ZM11.0001 10.6666L10.5201 9.5555C10.4756 9.42661 10.3512 9.33328 10.2045 9.33328H9.09341C8.94675 9.33328 8.8223 9.42661 8.77786 9.5555L8.33341 10.6666V11.5555C8.33341 11.6777 8.43341 11.7777 8.55564 11.7777H8.77786C8.90008 11.7777 9.00008 11.6777 9.00008 11.5555V11.3333H10.3334V11.5555C10.3334 11.6777 10.4334 11.7777 10.5556 11.7777H10.7779C10.9001 11.7777 11.0001 11.6777 11.0001 11.5555V10.6666Z"
                  fill="#008069"
                />
              </g>
              <defs>
                <clipPath id="clip0_9710_50601">
                  <rect
                    width="5.33333"
                    height="5.33333"
                    fill="white"
                    transform="translate(7 8)"
                  />
                </clipPath>
              </defs>
            </svg>

            <p>Driver's License</p>

            <p>Soon</p>
          </div> */}
        </div>
        {(isPaperDue.insuranceExpiry ||
          isPaperDue.roadExpiry ||
          isPaperDue.vehicle_licenseExpiry) && (
          <div className={styles.no_vin_papers_btn}>
            <Link to={orderInProgress ? "" : "/papers"}>
              {orderInProgress ? "Papers Processing" : "Renew"}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default HomeVehiclePapers;
