import React, { useEffect, useState } from "react";
import styles from "./Notifications.module.css";
import { Link, useParams } from "react-router-dom";
import BottomNav from "../../../Components/BottomNav";
import Modal from "../../../Components/UI/Modal";
import Button from "../../../Components/interface/Button";
import { useSelector } from "react-redux";
import Notifications from "../../../utils/api/Notifications";

function NotificationPage() {
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState(null);

  const personalData = useSelector((data) => data.auth);

  const { id } = useParams();

  useEffect(() => {
    const fetchNotifications = async () => {
      setLoading(true);
      try {
        const notificationsRes = await Notifications.getSingleNotification({
          notification_id: id,
          access_token: personalData.token,
        });
        setNotification(notificationsRes.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err?.response?.data?.error);
      }
    };
    fetchNotifications();
  }, [personalData.token, id]);

  return (
    <div>
      {loading && (
        <Modal message="Loading..." back="" accept="" loader="true" />
      )}
      <div className={styles.page_body}>
        <div className={styles.page_header}>
          <p>
            {notification?.title.slice(0, 15)}
            {notification?.title.length >= 15 ? "..." : ""}
          </p>
        </div>
        <div className={styles.maintenance_banner}>
          <div>
            <h3>{notification?.title}</h3>
          </div>
        </div>
        <div className={styles.add_info}>
          <div>
            <div className={styles.image_wrapper_title}>
              <h2>{notification?.desc}</h2>
            </div>
          </div>

          <div></div>
        </div>

        <div className={styles.buttons}>
          <Link to="/notifications">
            <Button>Back</Button>
          </Link>
        </div>
      </div>
      <BottomNav />
    </div>
  );
}

export default NotificationPage;
