import React, { useState, useEffect } from "react";
import styles from "../forgotPassword/ForgotPassword.module.css";
import Logo from "../../Components/interface/Logo";
import Button from "../../Components/interface/Button";
import { useNavigate } from "react-router";
import cars from "../../utils/CarList";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EmailInput from "../../Components/interface/EmailInput";
import Authentication from "../../utils/api/Authentication";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function AddVehicleForm(props) {
  const [loading, setLoading] = useState(false);
  const [selectInput, setSelectInput] = useState("");
  const [selectInput2, setSelectInput2] = useState("");
  const [selectInput3, setSelectInput3] = useState("");
  const [selectInput4, setSelectInput4] = useState("");
  const [formIsValid, setFormIsValid] = useState(false);
  const [number, setNumber] = useState("");
  const [validNumber, setValidNumber] = useState(false);
  const [numberInvalid, setNumberInvalid] = useState(false);

  const navigate = useNavigate();

  const personalData = useSelector((data) => data.auth);

  useEffect(() => {
    if (number.trim().length >= 3) {
      setValidNumber(true);
    } else {
      setValidNumber(false);
    }
  }, [number]);

  useEffect(() => {
    if (
      selectInput.trim().length >= 1 &&
      selectInput2.trim().length >= 1 &&
      selectInput4.trim().length >= 1 &&
      number.trim().length >= 1 &&
      selectInput3.trim().length >= 1
    ) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [number, selectInput, selectInput2, selectInput4, selectInput3]);

  const model = cars?.find((model, index) => model.brand === selectInput2);

  let submitHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setFormIsValid(false);
      const addVehicleRes = Authentication.addNewVehicle({
        id: personalData.generalLoginData.id,
        plate_number: number.replace(/[-,:./\\]/g, "").toLowerCase(),
        vehicle_year: selectInput,
        vehicle_make: selectInput2,
        vehicle_model: selectInput3,
      });

      toast.success(
        `Your new vehicle has been registered successfully. Please wait to be redirected.`,
        {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );

      const regCollectorUpload = await Authentication.regCollectorHandler({
        email: personalData.generalLoginData.email,
        fullname: personalData.generalLoginData?.fullname,
        phone_no: personalData.generalLoginData?.phone,
        plate_no: number.replace(/[-,:./\\]/g, "").toLowerCase(),
      });

      const setEngineUpload = await Authentication.setengineHandler({
        list: number.replace(/[-,:./\\]/g, "").toLowerCase(),
        selectInput2: selectInput2,
        selectInput3: selectInput3,
        selectInput: selectInput,
        selectInput4: selectInput4,
      });

      // Route back to home page after 4 seconds
      setTimeout(() => {
        setLoading(false);

        setFormIsValid(true);

        navigate("/home");
      }, 4000);

      return {
        addVehicleRes,
        setEngineUpload,
        regCollectorUpload,
      };
    } catch (error) {
      setLoading(false);
      setFormIsValid(true);
      toast.error(
        error.response.data.errors.message || error.response.data.errors.email,
        {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    }
  };
  let selectChangeHandler = (e) => {
    setSelectInput(e.target.value);
  };
  let brandSelectChangeHandler = (e) => {
    setSelectInput2(e.target.value);
  };
  let modelSelectChangeHandler = (e) => {
    setSelectInput3(e.target.value);
  };
  let vehicleTypeHandler = (e) => {
    setSelectInput4(e.target.value);
  };

  const numberInputHandler = (e) => {
    setNumber(e.target.value);
  };
  const numberInputBlur = () => {
    if (number.trim().length === 0) {
      setNumberInvalid(true);
    } else {
      setNumberInvalid(false);
    }
  };

  return (
    <section className={styles.login}>
      <ToastContainer />
      <div className={styles.back_btn}>
        <Link to="/">Back</Link>
      </div>
      <div className={styles.login__hero}>
        <Logo />
        <div className={styles.header}>
          <p>Register a new vehicle</p>
        </div>
      </div>
      <div className={styles.form}>
        <form onSubmit={submitHandler}>
          <div className={styles.form__inner}>
            <label htmlFor="Year">Vehicle Brand</label>
            <div className={styles.form__passwordInput}>
              <select onChange={brandSelectChangeHandler}>
                <option value="">Brand</option>
                {cars
                  .sort((a, b) => a.brand.localeCompare(b.brand))
                  .map((data, index) => (
                    <option value={data.brand}>{data.brand}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className={styles.form__inner}>
            <label htmlFor="Year">Vehicle Model</label>
            <div className={styles.form__passwordInput}>
              <select onChange={modelSelectChangeHandler}>
                <option value="">Choose Model</option>
                {model?.models.map((data, index) => (
                  <option value={data}>{data}</option>
                ))}
              </select>
            </div>
          </div>
          <div className={styles.form__inner}>
            <label htmlFor="Year">Vehicle Year</label>
            <div className={styles.form__passwordInput}>
              <select onChange={selectChangeHandler}>
                <option value="">Choose Year</option>
                <option value="2000">2000</option>
                <option value="2001">2001</option>
                <option value="2002">2002</option>
                <option value="2003">2003</option>
                <option value="2004">2004</option>
                <option value="2005">2005</option>
                <option value="2006">2006</option>
                <option value="2007">2007</option>
                <option value="2008">2008</option>
                <option value="2009">2009</option>
                <option value="2010">2010</option>
                <option value="2011">2011</option>
                <option value="2012">2012</option>
                <option value="2013">2013</option>
                <option value="2014">2014</option>
                <option value="2015">2015</option>
                <option value="2016">2016</option>
                <option value="2017">2017</option>
                <option value="2018">2018</option>
                <option value="2019">2019</option>
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
              </select>
            </div>
          </div>
          <div className={styles.form__inner}>
            <label htmlFor="Year">Vehicle Type</label>
            <div className={styles.form__passwordInput}>
              <select onChange={vehicleTypeHandler}>
                <option value="Vehicle Type">Vehicle Type</option>
                <option value="SUV">SUV</option>
                <option value="Hatchback">Hatchback</option>
                <option value="Crossover">Crossover</option>
                <option value="Convertible">Convertible</option>
                <option value="Sedan">Sedan</option>
                <option value="Sports Car">Sports Car</option>
                <option value="Coupe">Coupe</option>
                <option value="Minivan">Minivan</option>
                <option value="Station Wagon">Station Wagon</option>
                <option value="Pickup Truck">Pickup Truck</option>
              </select>
            </div>
          </div>
          <EmailInput
            validEmail={validNumber}
            label="Plate Number"
            value={number}
            emailInvalid={numberInvalid}
            placeholder="KJA123AH"
            type="text"
            onBlur={numberInputBlur}
            onChange={numberInputHandler}
          />
          {!loading && <Button disabled={!formIsValid}>Continue</Button>}
          {loading && <Button disabled={!formIsValid}>Loading...</Button>}
        </form>
      </div>
    </section>
  );
}

export default AddVehicleForm;
