import React from "react";
import NavBar from "../../../Components/interface/NavBar";
import InsuranceNav from "./InsuranceNav";
import styles from "./Papers.module.css";
import BottomNav from "../../../Components/BottomNav";
import Button from "../../../Components/interface/Button";
import { Link } from "react-router-dom";

function InsuranceTerms(props) {
  return (
    <div className={styles.buyDongle}>
      <NavBar title="Insurance" setShowMenu={props.setShowMenu} />
      <div className={styles.buyDongle__body}>
        <InsuranceNav currentStep={4} />

        <div className={styles.head}>
          <div className={styles.head_title}>
            <h1>KYC Completed</h1>
          </div>

          <div className={styles.form}>
            <div className={styles.form_clause}>
              <p>
                Thank you for submitting your KYC. We will review and verify
                with 24 hours. KYC Submitted!
              </p>
            </div>
          </div>

          <div className={styles.button3}>
            <Link to="/">
              <Button>Continue</Button>
            </Link>
          </div>
        </div>
      </div>
      <BottomNav />
    </div>
  );
}

export default InsuranceTerms;
