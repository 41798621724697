import React from "react";
import styles from "../health/Health.module.css";

function Redirect() {
  return (
    <div>
      {" "}
      <div className={styles.motor_ai_head}>
        <iframe
          className={styles.iframe}
          src="https://www.motormata.com/motorists"
          name="redirect"
          // height="620px"
          width="100%"
          title="MotormataAi"
        ></iframe>
      </div>
    </div>
  );
}

export default Redirect;
