import React, { useState } from "react";
import NavBar from "../../../Components/interface/NavBar";
// import SearchInput2 from "../../../Components/inputs/SearchInput2";
import SparepartsList from "./SparepartsList";
import styles from "./Services.module.css";
import serviceImg from "../../../asset/images/Save Money/2.jpg";

import serviceImg4 from "../../../asset/images/Save Money/5.jpg";
import BottomNav from "../../../Components/BottomNav";

function SpareParts(props) {
  // const [searchInput, setSearchInput] = useState("");
  const [openedSearchPage, setOpenedSearchPage] = useState(false);
  // const [text, setText] = useState("");
  // const [placeholderText, setPlaceholderText] = useState("");
  // const [currentIndex, setCurrentIndex] = useState(0);
  // const texts = useMemo(
  //   () => [
  //     "Search for shock absorber",
  //     "Search for belt",
  //     "Search for battery",
  //     "Search for bumper",
  //   ],
  //   []
  // );

  // const searchInputChangeHandler = (e) => {
  //   setSearchInput(e.target.value);
  // };

  // useEffect(() => {
  //   const typeInterval = setInterval(() => {
  //     const currentText = texts[currentIndex];
  //     if (currentText.length > text.length) {
  //       setText(currentText.slice(0, text.length + 1));
  //       setPlaceholderText(""); // Clear placeholder text when typing
  //     } else {
  //       clearInterval(typeInterval);
  //       setTimeout(() => {
  //         const nextIndex = (currentIndex + 1) % texts.length;
  //         setCurrentIndex(nextIndex);
  //         setPlaceholderText(texts[nextIndex]); // Set placeholder to next text
  //       }, 2000); // Wait for 2 seconds before switching to the next text
  //     }
  //   }, 100); // Typing speed: 100 milliseconds per character

  //   return () => clearInterval(typeInterval);
  // }, [currentIndex, text, texts]);

  // const formSubmitHandler = (e) => {
  //   e.preventDefault();
  //   console.log(searchInput);
  //   setSearchInput("");
  // };

  const images = [serviceImg, serviceImg4];

  return (
    <section>
      <NavBar title="Services" setShowMenu={props.setShowMenu} />
      <div className={styles.head}>
        <div className={styles.main_hero}>
          {/* <InsuranceMenu
            first="Services"
            first_link="services"
            second="Spare Parts"
            second_link="spare-parts"
          /> */}
          {/* <form onSubmit={formSubmitHandler} className={styles.form}>
            <SearchInput2
              onClick={() => setOpenedSearchPage(true)}
              value={searchInput}
              onChange={searchInputChangeHandler}
              name="search"
              label="Search for thousands of spare parts"
              placeholder={placeholderText}
            />
          </form> */}
          {/* {!openedSearchPage && !searchInput.trim().length > 0 && (
            <p className={styles.main_hero_paragraph}>Or</p>
          )} */}

          <div className={styles.header_img}>
            {images.map((data, index) => (
              <div className={styles.header_img_wrapper} key={index}>
                <div className={styles.header_img_wrapper_inner}>
                  <img src={data} alt="service" />
                </div>
              </div>
            ))}
          </div>

          <div className={styles.header_text}>
            <div>
              <h1>Experience The Best Car Services in Lagos</h1>
            </div>

            <p>
              Choose from a range of premium car services from periodic car oil
              change, wheel service, and routine car health inspection that can
              be done at your home or office.
            </p>
          </div>

          <SparepartsList
            // searchInput={searchInput}
            setOpenedSearchPage={setOpenedSearchPage}
            openedSearchPage={openedSearchPage}
          />
        </div>
      </div>

      <BottomNav />
    </section>
  );
}

export default SpareParts;
