import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./SignUp.module.css";
import EmailInput from "../../Components/interface/EmailInput";
import Button from "../../Components/interface/Button";
import Input from "../../Components/interface/Input";
import Logo from "../../Components/interface/Logo";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import PasswordInput from "../../Components/interface/PasswordInput";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/redux-store/Index";
import "react-toastify/dist/ReactToastify.css";
import Authentication from "../../utils/api/Authentication";

const initialValues = {
  name: "",
  password: "",
  number: "",
  email: "",
};
const initialBoolenValues = {
  toggle: false,
  nameInvalid: false,
  passwordInvalid: false,
  phoneNumberInvalid: false,
  formIsValid: false,
  validEmail: false,
};

function SignUp() {
  const [values, setValues] = useState(initialValues);
  const [boolen, setBoolen] = useState(initialBoolenValues);
  const [loading, setLoading] = useState(false);
  const [number, setNumber] = useState("");
  const [validNumber, setValidNumber] = useState(false);
  const [numberInvalid, setNumberInvalid] = useState(false);

  //Input state handler//
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  //Input state handler end//
  let navigate = useNavigate();
  let dispatch = useDispatch();

  useEffect(() => {
    if (number.trim().length >= 3) {
      setValidNumber(true);
    } else {
      setValidNumber(false);
    }
  }, [number]);

  const numberInputHandler = (e) => {
    setNumber(e.target.value);
  };
  const plateNumberInputBlur = () => {
    if (number.trim().length === 0) {
      setNumberInvalid(true);
    } else {
      setNumberInvalid(false);
    }
  };

  useEffect(() => {
    if (
      values.password.trim().length >= 1 &&
      values.name.trim().length >= 1 &&
      number.trim().length >= 1 &&
      values.number.trim().length >= 11
    ) {
      setBoolen({ formIsValid: true });
    } else {
      setBoolen({ formIsValid: false });
    }
  }, [values.password, values.number, values.name, number]);

  //Email Validation//
  useEffect(() => {
    if (
      values.email.trim().length >= 1 &&
      values.email.includes("@") &&
      values.email.includes(".com")
    ) {
      setBoolen({ validEmail: true });
    } else {
      setBoolen({ validEmail: false });
    }
  }, [values.email]);

  //Form Submission//
  let submitHandler = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const res = await Authentication.validateAccount({
        email: values.email,
        phone_number: values.number,
      });
      dispatch(
        authActions.signUp({
          name: values.name,
          email: values.email,
          password: values.password,
          number: values.number.trim(),
        })
      );

      dispatch(
        authActions.plateNumber(
          number
            .replace(/[-\s,:./\\]/g, "")
            .trim()
            .toLowerCase()
        )
      );

      navigate("/registervehicle/form");
      setLoading(false);

      return res;
    } catch (err) {
      setLoading(false);
      const errors = [];
      if (err.response.data.errors.message) {
        errors.push(err.response.data.errors.message);
      } else if (err.response.data.errors) {
        const errorMessages = err.response.data.errors;
        Object.keys(errorMessages).forEach((key) => {
          errorMessages[key].forEach((errorMessage) => {
            errors.push(errorMessage);
          });
        });
      }
      toast.error(errors[0], {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  //Form Submission end//

  //Password Toggle Handler//
  let passwordToggleHandler = () => {
    setBoolen({ ...boolen, toggle: !boolen.toggle });
  };

  let numberInputBlur = () => {
    if (values.number.trim().length === 0) {
      setBoolen({ ...boolen, phoneNumberInvalid: true });
    } else {
      setBoolen({ ...boolen, phoneNumberInvalid: false });
    }
  };
  //Password Toggle Handler end//

  //Input Blur Handler Handler//
  let passwordInputBlur = () => {
    if (values.password.trim().length === 0) {
      setBoolen({ ...boolen, passwordInvalid: true });
    } else {
      setBoolen({ ...boolen, passwordInvalid: false });
    }
  };

  let nameInputBlur = () => {
    if (values.name.trim().length === 0) {
      setBoolen({ ...boolen, nameInvalid: true });
    } else {
      setBoolen({ ...boolen, nameInvalid: false });
    }
  };
  //Input Blur Handler Handler end//

  let type = boolen.toggle ? "name" : "password";
  return (
    <section className={styles.login}>
      <ToastContainer />
      <div className={styles.login__hero}>
        <Logo />
        <div className={styles.header}>
          <p>Sign Up</p>
        </div>
      </div>
      <div className={styles.form}>
        <form onSubmit={submitHandler}>
          <Input
            label="Full Name"
            onBlur={nameInputBlur}
            invalid={boolen.nameInvalid}
            onChange={handleInputChange}
            value={values.name}
            type="name"
            placeholder="Enter your full name"
            name="name"
          />
          <Input
            label="Phone Number"
            onBlur={numberInputBlur}
            invalid={boolen.phoneNumberInvalid}
            onChange={handleInputChange}
            value={values.number}
            type="tel"
            placeholder="Enter phone number"
            name="number"
          />
          <EmailInput
            type="email"
            value={values.email}
            label="Email"
            onChange={handleInputChange}
            placeholder="Enter email"
            validEmail={boolen.validEmail}
            name="email"
          />
          <EmailInput
            validEmail={validNumber}
            label="Plate Number"
            value={number}
            emailInvalid={numberInvalid}
            placeholder="KJA123AH"
            type="text"
            onBlur={plateNumberInputBlur}
            onChange={numberInputHandler}
          />
          <PasswordInput
            toggle={boolen.toggle}
            onClick={passwordToggleHandler}
            onBlur={passwordInputBlur}
            passwordInvalid={boolen.passwordInvalid}
            label="Password"
            placeholder="Enter password"
            type={type}
            value={values.password}
            onChange={handleInputChange}
            name="password"
          />
          <div className={styles.button}>
            {!loading && (
              <Button disabled={!boolen.formIsValid}>Continue</Button>
            )}
            {loading && (
              <Button disabled={boolen.formIsValid}>Loading...</Button>
            )}
          </div>
        </form>
        <div className={styles.login__options}>
          <p>
            Already have an account?{" "}
            <span>
              {" "}
              <Link to="/login">Login</Link>
            </span>
          </p>
        </div>
      </div>
    </section>
  );
}

export default SignUp;
