import React, { useCallback, useEffect, useState } from "react";
import BottomNav from "../../../Components/BottomNav";
import NavBar from "../../../Components/interface/NavBar";
import styles from "./Papers.module.css";
import Input from "../../../Components/interface/Input";
import Button from "../../../Components/interface/Button";
import { useDispatch, useSelector } from "react-redux";
import states from "../../../utils/States";
import { useNavigate } from "react-router";
import { authActions } from "../../../store/redux-store/Index";
import CarPapersAPi from "../../../utils/api/CarPapersApi";

function Checkout(props) {
  const [state, setState] = useState("");
  const [address, setAddress] = useState("");
  const [data, setData] = useState([]);
  const [inValidAddresss, setInvalidAddress] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);

  const personalData = useSelector((data) => data.auth.generalLoginData);

  const vehicleIndex = useSelector((data) => data.dongles.currentVehicle);

  const plate_number =
    personalData?.vehicle_data[vehicleIndex]?.plate_number !== undefined
      ? personalData?.vehicle_data[vehicleIndex]?.plate_number
      : personalData?.vendor_vehicle_data[0].plate_number;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const delivery = data.cart_list?.find((data) =>
    data?.product_title.includes("Delivery")
  );

  const deliveryProducts = data.cart_list?.filter(
    (data) => data?.product_title !== "Delivery Fee Only"
  );

  const checkoutCallback = useCallback(() => {
    const getPapersHandler = async () => {
      try {
        const checkoutRes = await CarPapersAPi.cartCheckoutHandler({
          plate_number,
        });
        setData(checkoutRes.data);
      } catch (err) {
        console.log(err?.response?.data?.error);
      }
    };
    getPapersHandler();
  }, [plate_number]);

  useEffect(() => {
    checkoutCallback();
  }, [checkoutCallback]);

  useEffect(() => {
    if (state.trim().length >= 1 && address.trim().length >= 1) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [address, state]);

  const stateSelectChangeHandler = (e) => {
    setState(e.target.value);
  };
  const handleInputChange = (e) => {
    setAddress(e.target.value);
  };

  const addressInputBlur = () => {
    if (address.trim().length === 0) {
      setInvalidAddress(true);
    } else {
      setInvalidAddress(false);
    }
  };

  const ButtonNavigateHandler = () => {
    dispatch(authActions.clearDeliveryAdd());
    const deliveryDetails = {
      state: state,
      zone: "Mobile App",
      street: address,
      location: 10,
    };
    dispatch(authActions.deliveryAdd(deliveryDetails));
    const productIdRes = deliveryProducts?.map((data) => {
      return data?.magento_product_id;
    });
    dispatch(authActions.magentoAdd(productIdRes));
    navigate("/papers/invoice");
  };

  const commafy = (num) => {
    return num?.toLocaleString();
  };

  return (
    <div>
      <NavBar title="Cart" setShowMenu={props.setShowMenu} />
      <div className={styles.head}>
        <div className={styles.main_hero}>
          <div className={styles.cartNav}>
            <p>CART</p>
            <p style={{ color: "#008069", borderBottom: "5px solid #008069" }}>
              DELIVERY
            </p>
            <p>PAYMENT</p>
          </div>
          <div className={styles.cartNav_progress}></div>
        </div>
        <div>
          <p>Delivery to</p>
          <div className={styles.contact_cus}>
            <p>{personalData.fullname}</p>
          </div>
          <div className={styles.contact_cus}>
            <p>{personalData.phone}</p>
          </div>
          <div className={styles.form__inner}>
            <div className={styles.form__passwordInput}>
              <select value={state} onChange={stateSelectChangeHandler}>
                {states?.map((data, index) => (
                  <option value={data}>{data}</option>
                ))}
              </select>
            </div>
            <div className={styles.form__inner}>
              <Input
                onBlur={addressInputBlur}
                invalid={inValidAddresss}
                onChange={handleInputChange}
                value={address}
                type="text"
                placeholder="Enter your address"
                name="number"
              />
            </div>
            <div className={styles.total2}>
              <div>
                <p>Sub Total</p>
                <h4>₦ {commafy(data?.cart_sum - delivery?.price)}</h4>
              </div>
              <div>
                <p>Delivery Fee</p>
                <h4>₦ {commafy(delivery?.price)}</h4>
              </div>
              <div className={styles.grandTotal}>
                <p>Grand Total</p>
                <h4>₦ {commafy(data?.cart_sum || 0)}</h4>
              </div>
            </div>
            <div className={styles.button2}>
              <Button onClick={ButtonNavigateHandler} disabled={!formIsValid}>
                Proceed to Payment
              </Button>
            </div>
          </div>
        </div>
      </div>
      <BottomNav />
    </div>
  );
}

export default Checkout;
