import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Button from "../../../Components/interface/Button";
import NavBar from "../../../Components/interface/NavBar";
import Card from "../../../Components/UI/Card";
import styles from "./Papers.module.css";
import BottomNav from "../../../Components/BottomNav";
import CarPapersAPi from "../../../utils/api/CarPapersApi";

function Cart(props) {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const personalData = useSelector((data) => data.auth.generalLoginData);

  const vehicleIndex = useSelector((data) => data.dongles.currentVehicle);

  const plate_number =
    personalData?.vehicle_data[vehicleIndex]?.plate_number !== undefined
      ? personalData?.vehicle_data[vehicleIndex]?.plate_number
      : personalData?.vendor_vehicle_data[0].plate_number;

  const deliveryProducts = data.cart_list?.filter(
    (data) => data?.product_title !== "Delivery Fee Only"
  );

  const delivery = data.cart_list?.find((data) =>
    data?.product_title.includes("Delivery")
  );

  useEffect(() => {
    if (deliveryProducts?.length === 0) {
      navigate("/papers");
    }
  }, [deliveryProducts?.length, navigate]);

  const checkoutCallback = useCallback(() => {
    const getPapersHandler = async () => {
      try {
        const checkoutRes = await CarPapersAPi.cartCheckoutHandler({
          plate_number: plate_number,
        });
        setData(checkoutRes.data);
      } catch (err) {
        console.log(err?.response?.data?.error);
      }
    };
    getPapersHandler();
  }, [plate_number]);

  useEffect(() => {
    checkoutCallback();
  }, [checkoutCallback]);

  const onClick = (name, id) => {
    console.log(plate_number);
    let removeCartHandler = async () => {
      try {
        const removedCartRes = await CarPapersAPi.removeFromCartHandler({
          plate_no: plate_number,
          cart_type: name,
          id,
        });
        checkoutCallback();
        return removedCartRes.data;
      } catch (err) {
        console.log(err?.response?.data?.error);
      }
    };
    removeCartHandler();
  };

  const ButtonNavigateHandler = () => {
    navigate("/papers/checkout");
  };

  function truncateTitle(input) {
    if (input.length > 8) {
      return input.substring(0, 21) + "";
    }
    return input;
  }

  const commafy = (num) => {
    return num?.toLocaleString();
  };
  return (
    <div>
      <NavBar title="Cart" setShowMenu={props.setShowMenu} />
      <div className={styles.head}>
        <div>
          <div className={styles.main_hero}>
            <div className={styles.cartNav}>
              <p
                style={{ color: "#008069", borderBottom: "5px solid #008069" }}
              >
                CART
              </p>
              <p>DELIVERY</p>
              <p>PAYMENT</p>
            </div>
          </div>
          {deliveryProducts?.map((data) => (
            <Card>
              <div className={styles.vehiclePapers}>
                <div className={styles.papers_title}>
                  <h4>{truncateTitle(data.product_title)}</h4>
                  <p>Car Document</p>
                </div>
                <div className={styles.vehiclPapers_card_price}>
                  <h3>₦ {commafy(data.price)}</h3>
                  <svg
                    onClick={() => onClick(data.cart_type, data.id)}
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.2722 4.52344C10.2722 4.52344 9.95547 8.45219 9.77172 10.1071C9.68422 10.8975 9.19597 11.3607 8.39622 11.3753C6.87431 11.4027 5.35064 11.4044 3.82931 11.3724C3.05989 11.3566 2.57981 10.8876 2.49406 10.1112C2.30914 8.44169 1.99414 4.52344 1.99414 4.52344"
                      stroke="#939393"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.0797 2.64128H1.1875"
                      stroke="#939393"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.17383 2.64072C8.71592 2.64072 8.32158 2.31697 8.23175 1.86839L8.09 1.15905C8.0025 0.831802 7.70617 0.605469 7.36842 0.605469H4.89917C4.56142 0.605469 4.26508 0.831802 4.17758 1.15905L4.03583 1.86839C3.946 2.31697 3.55167 2.64072 3.09375 2.64072"
                      stroke="#939393"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </Card>
          ))}
        </div>
        <div className={styles.total}>
          <div>
            <p>Sub Total</p>
            <h4>₦ {commafy(data?.cart_sum - delivery?.price)}</h4>
          </div>
          <div>
            <p>Delivery Fee</p>
            <h4>₦ {commafy(delivery?.price)}</h4>
          </div>
          <div className={styles.grandTotal}>
            <p>Grand Total</p>
            <h4>₦ {commafy(data?.cart_sum || 0)}</h4>
          </div>
        </div>
        <div className={styles.button}>
          <Button onClick={ButtonNavigateHandler}>Proceed to Delivery</Button>
        </div>
      </div>

      <BottomNav />
    </div>
  );
}

export default Cart;
