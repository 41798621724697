import axios from "axios";

const baseUrl = `http://bpms.test/api/v1/main/app/claim/reg`;

const url = process.env.REACT_APP_API_URL;

const url2 = process.env.REACT_APP_API_URL_BPMS;

const Insurance = {
  claimRegistration: (payload) => {
    const response = axios({
      url: `${baseUrl}`,
      method: "POST",
      data: JSON.stringify({
        plate_no: payload.plate_no,
        pic1: payload.pic1,
        pic2: payload.pic2,
        pic3: payload.pic3,
        pic4: payload.pic4,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  fetchClaimsData: (payload) => {
    const response = axios({
      url: `${baseUrl}/record/${payload.plateNumber}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  postPlayerId: (payload) => {
    const response = axios({
      url: `${url2}/main/app/post/playerID`,
      method: "POST",
      data: JSON.stringify({
        phone_no: payload.phone_no,
        player_id: payload.player_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  pythonPostPlayerId: (payload) => {
    const response = axios({
      url: `${url}/auth/users/register/player-id/`,
      method: "POST",
      data: JSON.stringify({
        player_id: payload.player_id,
      }),
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  },
};

export default Insurance;
