import React, { useCallback, useEffect, useState } from "react";
import styles from "./Services.module.css";
import FilteredInput from "../../../Components/inputs/FilteredInput";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "../../../Components/inputs/Input";
import ServiceApi from "../../../utils/api/ServiceApi";
import Modal from "../../../Components/UI/Modal";

const list = [
  "Accessories",
  "Body Parts",
  "Underneath",
  "Interior Parts",
  "Engine Parts",
];

function SparepartsList({
  showFilter,
  openedSearchPage,
  setOpenedSearchPage,
  searchInput,
}) {
  const [openedCategory, setOpenedCategory] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedCategoryItem, setSelectedCategoryItem] = useState([]);
  const [yearInput, setYearInput] = useState("");
  const [makeInput, setMakeInput] = useState("");
  const [enteredInput, setEnteredInput] = useState(false);
  const [modelInput, setModelInput] = useState("");
  const [sparePartsCategories, setSparePartsCategories] = useState([]);
  const [itemsToBeSearched, setItemsToBeSearched] = useState([]);
  const [searchFilter, setSearchFilter] = useState([]);
  const [products, setProducts] = useState([]);
  const [services, setServices] = useState([]);
  const [engineOilProducts, setEngineOilProducts] = useState([]);
  const [batteryProducts, setBatteryProducts] = useState([]);
  const [tyreProducts, setTyreProducts] = useState([]);
  const [donglesProducts, setDonglesProducts] = useState([]);
  const [cartList, setCartList] = useState([]);
  const [initLoading, setInitLoading] = useState(false);

  const dongle = useSelector((data) => data.dongles);

  const navigate = useNavigate();

  const personalData = useSelector((data) => data.auth.generalLoginData);

  const plate_number =
    personalData?.vehicle_data[dongle.currentVehicle]?.plate_number !==
    undefined
      ? personalData?.vehicle_data[dongle.currentVehicle]?.plate_number
      : personalData?.vendor_vehicle_data[0].plate_number;

  useEffect(() => {
    const vehicle_data =
      personalData?.vehicle_data.length !== 0
        ? personalData?.vehicle_data
        : personalData?.vendor_vehicle_data;

    setYearInput(vehicle_data[dongle.currentVehicle]?.vehicle_year);

    setModelInput(vehicle_data[dongle.currentVehicle]?.vehicle_model);

    setMakeInput(vehicle_data[dongle.currentVehicle]?.vehicle_make);
  }, [dongle.currentVehicle, personalData]);

  useEffect(() => {
    if (
      yearInput.length >= 4 &&
      makeInput.length > 0 &&
      modelInput.length > 0
    ) {
      setEnteredInput(true);
    } else {
      setEnteredInput(false);
    }
  }, [yearInput, makeInput, modelInput]);

  const getCart = useCallback(() => {
    const getCartList = async () => {
      try {
        const checkoutRes = await ServiceApi.checkout({
          plate_no: plate_number,
        });
        setCartList(checkoutRes.data);

        return checkoutRes.data;
      } catch (err) {
        toast.error(err?.response?.data?.message, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        console.log(err?.response?.data?.message);
      }
    };
    getCartList();
  }, [plate_number]);

  useEffect(() => {
    getCart();
  }, [getCart]);

  const addToCartHandler = async (itemId) => {
    try {
      const addedCartRes = await ServiceApi.addToCart({
        plate_no: plate_number,
        product_id: itemId,
      });
      toast.success(addedCartRes.data.message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      getCart();
      return addedCartRes.data;
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      console.log(err?.response?.data?.message);
    }
  };

  useEffect(() => {
    const fetchServices = async () => {
      const services = await ServiceApi.fetchServiceCategories();
      const arrayFromObject = Object.entries(services.data).map(
        ([key, value]) => ({
          key,
          ...value,
        })
      );

      arrayFromObject.forEach((data) => {
        const filteredEntries = Object.entries(data).filter(
          ([key, value]) => key !== "key"
        );

        filteredEntries.forEach(([key, value]) => {
          setItemsToBeSearched((prevKeysArray) => [
            ...prevKeysArray,
            { key, value },
          ]);
        });
      });

      setSparePartsCategories(arrayFromObject);
    };
    fetchServices();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setInitLoading(true);
      try {
        // Fetch data from the database
        const response = await ServiceApi.getProductsList({
          category_name: "Product",
        });
        setInitLoading(false);
        setProducts(response.data.data.data); // Update state with fetched data
      } catch (error) {
        setInitLoading(false);
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setInitLoading(true);
      try {
        // Fetch data from the database
        const response = await ServiceApi.getProductsList({
          category_name: "Routine Service",
        });
        setInitLoading(false);
        setServices(response.data.data.data); // Update state with fetched data
      } catch (error) {
        setInitLoading(false);
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const filteredEngineOilProducts = products
      .filter((product) =>
        product.product_title.toLowerCase().includes("engine oil")
      )
      .slice(0, 2);
    setEngineOilProducts(filteredEngineOilProducts);

    const filteredTyreProducts = products
      .filter((product) => product.product_title.toLowerCase().includes("tyre"))
      .slice(0, 2);
    setTyreProducts(filteredTyreProducts);

    const filteredBatteryProducts = products
      .filter((product) =>
        product.product_title.toLowerCase().includes("battery")
      )
      .slice(0, 2);
    setBatteryProducts(filteredBatteryProducts);

    const filteredDongleProducts = products
      .filter((product) =>
        product.product_title.toLowerCase().includes("tracker")
      )
      .slice(0, 2);
    setDonglesProducts(filteredDongleProducts);
  }, [products]);

  const filterSearchResults = useCallback(() => {
    return itemsToBeSearched?.filter((item) => {
      const lowerCaseKey = item?.key?.toLowerCase();
      const lowerCaseSearchInput = searchInput?.toLowerCase();
      if (lowerCaseKey && lowerCaseSearchInput) {
        return lowerCaseKey.includes(lowerCaseSearchInput);
      }
      return false;
    });
  }, [itemsToBeSearched, searchInput]);

  useEffect(() => {
    const filteredSearchResults = filterSearchResults();
    setSearchFilter(filteredSearchResults);
  }, [filterSearchResults]);

  const selectCategoryHandler = (category) => {
    if (selectedCategory[selectedCategory.length - 1] === category.key) {
      setSelectedCategory([]);
    } else {
      const arrayFromObject = Object.entries(category).map(([key, value]) => ({
        key,
        ...value,
      }));
      const trimmedArr = arrayFromObject.slice(1);

      const objKey = Object.values(arrayFromObject[0]); // Get the values of the first object
      const concatenatedString = objKey.slice(0, objKey.length - 1).join(""); // Join the values into a string

      setSelectedCategory([...trimmedArr, concatenatedString]);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optionally, you can add smooth scrolling behavior
    });
  };

  const removePrefix = (inputString) => {
    const indexOfDash = inputString.indexOf(" - ");
    return indexOfDash !== -1
      ? inputString.substring(indexOfDash + 3)
      : inputString;
  };

  return (
    <div className={styles.workShop}>
      <ToastContainer />
      {initLoading ? (
        <Modal message="" back="" accept="" loader="true" />
      ) : (
        <div>
          {showFilter && (
            <div className={styles.filter}>
              <FilteredInput title="Car Parts Sections" listNames={list} />
            </div>
          )}
          {openedSearchPage && (
            <div className={styles.searchInputPage}>
              <div onClick={() => setOpenedSearchPage(false)}>
                <div className={styles.svg_wrapper}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 22L0 12L10 2L11.775 3.775L3.55 12L11.775 20.225L10 22Z"
                      fill="#263238"
                    />
                  </svg>
                </div>
              </div>

              <div className={styles.search_input_wrapper}>
                {searchFilter.map((data) => (
                  <div
                    className={styles.search_input_container}
                    onClick={() => {
                      setSelectedCategoryItem(data);
                      setOpenedCategory(false);
                      setOpenedSearchPage(false);
                      scrollToTop();
                    }}
                  >
                    <p>{data.key}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className={styles.spareParts_wrapper}>
            {/* Select by category
        {!openedSearchPage && !searchInput.trim().length > 0 && (
          <div
            className={styles.spareParts_wrapper_categories}
            onClick={() => {
              setOpenedCategory((openedCategory) => !openedCategory);
            }}
          >
            <p>View by category</p>
            {!openedCategory ? (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.5919 6.91205C15.9173 7.23748 15.9173 7.76512 15.5919 8.09056L10.5919 13.0906C10.2664 13.416 9.73879 13.416 9.41335 13.0906L4.41335 8.09056C4.08791 7.76512 4.08791 7.23748 4.41335 6.91205C4.73879 6.58661 5.26642 6.58661 5.59186 6.91205L10.0026 11.3228L14.4133 6.91205C14.7388 6.58661 15.2664 6.58661 15.5919 6.91205Z"
                  fill="#6B7280"
                />
              </svg>
            ) : (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.40814 13.088C4.0827 12.7625 4.0827 12.2349 4.40814 11.9094L9.40814 6.90944C9.73358 6.58401 10.2612 6.58401 10.5867 6.90944L15.5867 11.9094C15.9121 12.2349 15.9121 12.7625 15.5867 13.088C15.2612 13.4134 14.7336 13.4134 14.4081 13.088L9.9974 8.67721L5.58665 13.088C5.26121 13.4134 4.73358 13.4134 4.40814 13.088Z"
                  fill="#6B7280"
                />
              </svg>
            )}
          </div>
        )} */}

            {openedCategory && (
              <div className={styles.categories_list}>
                <div className={styles.categories_list_specifications}>
                  <p>Car Specifications</p>

                  <div>
                    <Input
                      type="number"
                      placeholder="Enter Year"
                      onChange={(e) => setYearInput(e.target.value)}
                      value={yearInput}
                    />
                    <Input
                      placeholder="Enter Make"
                      onChange={(e) => setMakeInput(e.target.value)}
                      value={makeInput}
                    />
                    <Input
                      placeholder="Enter Model"
                      onChange={(e) => setModelInput(e.target.value)}
                      value={modelInput}
                    />
                  </div>
                  <p>
                    To find part(s) for other vehicle using category system,
                    please fill the vehicle specification table above
                  </p>
                </div>
                {enteredInput && (
                  <div className={styles.categories_list_categories}>
                    <p>Categories</p>

                    <div className={styles.categories_list_category_list}>
                      {sparePartsCategories?.map((category, index) => (
                        <div key={index}>
                          <div
                            className={
                              styles.categories_list_category_list_items
                            }
                            onClick={() => selectCategoryHandler(category)}
                          >
                            <p>{category.key}</p>
                          </div>
                          {selectedCategory[selectedCategory.length - 1] ===
                            category?.key && (
                            <div>
                              {selectedCategory
                                ?.slice(0, -1)
                                ?.map((subCategory, subindex) => (
                                  <div
                                    onClick={() => {
                                      setSelectedCategoryItem(subCategory);
                                      setOpenedCategory(false);
                                      scrollToTop();
                                    }}
                                    key={subindex}
                                    className={
                                      styles.categories_list_category_list_items_inner
                                    }
                                  >
                                    <div>
                                      <div
                                        className={
                                          styles.categories_list_category_list_items_inner_image
                                        }
                                      ></div>
                                      <p>{subCategory.key}</p>
                                    </div>

                                    <svg
                                      width="20"
                                      height="20"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M6.90814 4.41205C7.23358 4.08661 7.76121 4.08661 8.08665 4.41205L13.0867 9.41205C13.4121 9.73748 13.4121 10.2651 13.0867 10.5906L8.08665 15.5906C7.76121 15.916 7.23358 15.916 6.90814 15.5906C6.5827 15.2651 6.5827 14.7375 6.90814 14.412L11.3189 10.0013L6.90814 5.59056C6.5827 5.26512 6.5827 4.73748 6.90814 4.41205Z"
                                        fill="#6B7280"
                                      />
                                    </svg>
                                  </div>
                                ))}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}

            {selectedCategoryItem.key && (
              <div className={styles.spareParts}>
                <p className={styles.para}>Selected Category</p>
                <div className={styles.spareParts_inner}>
                  <div className={styles.spareParts_category_card}>
                    <div className={styles.spareParts_card_image_wrapper}>
                      <div
                        className={styles.spareParts_card_image_wrapper_inner}
                      >
                        <img
                          src={
                            selectedCategoryItem?.image ||
                            selectedCategoryItem?.value?.image
                          }
                          alt="parts"
                        />
                      </div>
                    </div>
                    <div className={styles.spare_parts_name_wrapper}>
                      <div className={styles.spareParts_name}>
                        <div>
                          <p>{selectedCategoryItem?.key}</p>
                        </div>

                        <div>
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.99992 13.999L7.03325 13.1323C5.91103 12.1212 4.98325 11.249 4.24992 10.5156C3.51659 9.78229 2.93325 9.12385 2.49992 8.54029C2.06659 7.95718 1.76392 7.42118 1.59192 6.93229C1.41992 6.4434 1.3337 5.9434 1.33325 5.43229C1.33325 4.38785 1.68325 3.51562 2.38325 2.81562C3.08325 2.11562 3.95547 1.76562 4.99992 1.76562C5.5777 1.76562 6.1277 1.88785 6.64992 2.13229C7.17214 2.37674 7.62214 2.72118 7.99992 3.16562C8.3777 2.72118 8.8277 2.37674 9.34992 2.13229C9.87214 1.88785 10.4221 1.76562 10.9999 1.76562C12.0444 1.76562 12.9166 2.11562 13.6166 2.81562C14.3166 3.51562 14.6666 4.38785 14.6666 5.43229C14.6666 5.9434 14.5804 6.4434 14.4079 6.93229C14.2355 7.42118 13.9328 7.95718 13.4999 8.54029C13.0666 9.12385 12.4833 9.78229 11.7499 10.5156C11.0166 11.249 10.0888 12.1212 8.96659 13.1323L7.99992 13.999ZM7.99992 12.199C9.06659 11.2434 9.94436 10.4238 10.6333 9.74029C11.3221 9.05674 11.8666 8.46251 12.2666 7.95763C12.6666 7.45185 12.9444 7.00163 13.0999 6.60696C13.2555 6.21229 13.3333 5.82074 13.3333 5.43229C13.3333 4.76562 13.111 4.21007 12.6666 3.76562C12.2221 3.32118 11.6666 3.09896 10.9999 3.09896C10.4777 3.09896 9.99436 3.24607 9.54992 3.54029C9.10547 3.83451 8.79992 4.20963 8.63325 4.66563H7.36659C7.19992 4.21007 6.89436 3.83496 6.44992 3.54029C6.00547 3.24562 5.52214 3.09851 4.99992 3.09896C4.33325 3.09896 3.7777 3.32118 3.33325 3.76562C2.88881 4.21007 2.66659 4.76562 2.66659 5.43229C2.66659 5.82118 2.74436 6.21296 2.89992 6.60763C3.05547 7.00229 3.33325 7.45229 3.73325 7.95763C4.13325 8.46296 4.6777 9.0574 5.36659 9.74096C6.05547 10.4245 6.93325 11.2438 7.99992 12.199Z"
                              fill="#008069"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className={styles.spareParts_name}>
                        <div
                          className={styles.cartBtn}
                          onClick={() => {
                            addToCartHandler(selectedCategoryItem);
                          }}
                        >
                          <p>Request for price (Add to cart)</p>
                        </div>

                        {/* <div onClick={() => addToCartHandler(selectedCategoryItem)}>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.5 13.5C5.77614 13.5 6 13.2761 6 13C6 12.7239 5.77614 12.5 5.5 12.5C5.22386 12.5 5 12.7239 5 13C5 13.2761 5.22386 13.5 5.5 13.5Z"
                        stroke="#008069"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12.5 13.5C12.7761 13.5 13 13.2761 13 13C13 12.7239 12.7761 12.5 12.5 12.5C12.2239 12.5 12 12.7239 12 13C12 13.2761 12.2239 13.5 12.5 13.5Z"
                        stroke="#008069"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M1.5 2.5H3.5L5 11H13"
                        stroke="#008069"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5 9H12.795C12.8528 9.00004 12.9089 8.98004 12.9536 8.9434C12.9983 8.90676 13.029 8.85576 13.0403 8.79906L13.9403 4.29906C13.9476 4.26278 13.9467 4.22533 13.9377 4.18943C13.9288 4.15352 13.9119 4.12006 13.8885 4.09145C13.865 4.06284 13.8355 4.03979 13.802 4.02398C13.7686 4.00816 13.732 3.99997 13.695 4H4"
                        stroke="#008069"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* All initial spare parts (images) */}
            {!openedSearchPage && (
              <div className={styles.spareParts_container}>
                <div className={styles.spareParts}>
                  <p className={styles.para}>Scheduled Services</p>
                  <div className={styles.spareParts_inner}>
                    {services?.map((product) => (
                      <div
                        className={styles.spareParts_card}
                        key={product.id}
                        onClick={() =>
                          navigate(
                            `/spare-parts/scheduled-services/${product.id}`
                          )
                        }
                      >
                        {/* <p>{item}</p> */}
                        <div className={styles.spareParts_card_image_wrapper}>
                          <div
                            className={
                              styles.spareParts_card_image_wrapper_inner
                            }
                          >
                            <img
                              src={product.product_image}
                              alt={product.product_title}
                            />
                          </div>
                        </div>
                        <div className={styles.spareParts_name}>
                          <div>
                            <p>{product.product_title}</p>
                          </div>

                          {/* <div>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.99992 13.999L7.03325 13.1323C5.91103 12.1212 4.98325 11.249 4.24992 10.5156C3.51659 9.78229 2.93325 9.12385 2.49992 8.54029C2.06659 7.95718 1.76392 7.42118 1.59192 6.93229C1.41992 6.4434 1.3337 5.9434 1.33325 5.43229C1.33325 4.38785 1.68325 3.51562 2.38325 2.81562C3.08325 2.11562 3.95547 1.76562 4.99992 1.76562C5.5777 1.76562 6.1277 1.88785 6.64992 2.13229C7.17214 2.37674 7.62214 2.72118 7.99992 3.16562C8.3777 2.72118 8.8277 2.37674 9.34992 2.13229C9.87214 1.88785 10.4221 1.76562 10.9999 1.76562C12.0444 1.76562 12.9166 2.11562 13.6166 2.81562C14.3166 3.51562 14.6666 4.38785 14.6666 5.43229C14.6666 5.9434 14.5804 6.4434 14.4079 6.93229C14.2355 7.42118 13.9328 7.95718 13.4999 8.54029C13.0666 9.12385 12.4833 9.78229 11.7499 10.5156C11.0166 11.249 10.0888 12.1212 8.96659 13.1323L7.99992 13.999ZM7.99992 12.199C9.06659 11.2434 9.94436 10.4238 10.6333 9.74029C11.3221 9.05674 11.8666 8.46251 12.2666 7.95763C12.6666 7.45185 12.9444 7.00163 13.0999 6.60696C13.2555 6.21229 13.3333 5.82074 13.3333 5.43229C13.3333 4.76562 13.111 4.21007 12.6666 3.76562C12.2221 3.32118 11.6666 3.09896 10.9999 3.09896C10.4777 3.09896 9.99436 3.24607 9.54992 3.54029C9.10547 3.83451 8.79992 4.20963 8.63325 4.66563H7.36659C7.19992 4.21007 6.89436 3.83496 6.44992 3.54029C6.00547 3.24562 5.52214 3.09851 4.99992 3.09896C4.33325 3.09896 3.7777 3.32118 3.33325 3.76562C2.88881 4.21007 2.66659 4.76562 2.66659 5.43229C2.66659 5.82118 2.74436 6.21296 2.89992 6.60763C3.05547 7.00229 3.33325 7.45229 3.73325 7.95763C4.13325 8.46296 4.6777 9.0574 5.36659 9.74096C6.05547 10.4245 6.93325 11.2438 7.99992 12.199Z"
                            fill="#008069"
                          />
                        </svg>
                      </div> */}
                        </div>
                        {/* <div className={styles.spareParts_name}>
                      <div>
                        <p>₦{Number(product.price).toLocaleString()}</p>
                      </div>

                      <div onClick={() => addToCartHandler(product.magento_product_id)}>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.5 13.5C5.77614 13.5 6 13.2761 6 13C6 12.7239 5.77614 12.5 5.5 12.5C5.22386 12.5 5 12.7239 5 13C5 13.2761 5.22386 13.5 5.5 13.5Z"
                            stroke="#008069"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M12.5 13.5C12.7761 13.5 13 13.2761 13 13C13 12.7239 12.7761 12.5 12.5 12.5C12.2239 12.5 12 12.7239 12 13C12 13.2761 12.2239 13.5 12.5 13.5Z"
                            stroke="#008069"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M1.5 2.5H3.5L5 11H13"
                            stroke="#008069"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M5 9H12.795C12.8528 9.00004 12.9089 8.98004 12.9536 8.9434C12.9983 8.90676 13.029 8.85576 13.0403 8.79906L13.9403 4.29906C13.9476 4.26278 13.9467 4.22533 13.9377 4.18943C13.9288 4.15352 13.9119 4.12006 13.8885 4.09145C13.865 4.06284 13.8355 4.03979 13.802 4.02398C13.7686 4.00816 13.732 3.99997 13.695 4H4"
                            stroke="#008069"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </div> */}
                      </div>
                    ))}
                  </div>
                  {/* <Link to={`/spare-parts/services`} className={styles.para2}>
                See more
              </Link> */}
                </div>

                {engineOilProducts.length > 1 && (
                  <div className={styles.spareParts}>
                    <p className={styles.para}>Engine Oil</p>
                    <div className={styles.spareParts_inner}>
                      {engineOilProducts?.map((product) => (
                        <div
                          className={styles.spareParts_card}
                          key={product.id}
                        >
                          <div className={styles.spareParts_card_image_wrapper}>
                            <div
                              className={
                                styles.spareParts_card_image_wrapper_inner
                              }
                            >
                              <img
                                src={product.product_image}
                                alt={product.product_title}
                              />
                            </div>
                          </div>
                          <div className={styles.spareParts_name}>
                            <div>
                              <p>{removePrefix(product.product_title)}</p>
                            </div>

                            <div>
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.99992 13.999L7.03325 13.1323C5.91103 12.1212 4.98325 11.249 4.24992 10.5156C3.51659 9.78229 2.93325 9.12385 2.49992 8.54029C2.06659 7.95718 1.76392 7.42118 1.59192 6.93229C1.41992 6.4434 1.3337 5.9434 1.33325 5.43229C1.33325 4.38785 1.68325 3.51562 2.38325 2.81562C3.08325 2.11562 3.95547 1.76562 4.99992 1.76562C5.5777 1.76562 6.1277 1.88785 6.64992 2.13229C7.17214 2.37674 7.62214 2.72118 7.99992 3.16562C8.3777 2.72118 8.8277 2.37674 9.34992 2.13229C9.87214 1.88785 10.4221 1.76562 10.9999 1.76562C12.0444 1.76562 12.9166 2.11562 13.6166 2.81562C14.3166 3.51562 14.6666 4.38785 14.6666 5.43229C14.6666 5.9434 14.5804 6.4434 14.4079 6.93229C14.2355 7.42118 13.9328 7.95718 13.4999 8.54029C13.0666 9.12385 12.4833 9.78229 11.7499 10.5156C11.0166 11.249 10.0888 12.1212 8.96659 13.1323L7.99992 13.999ZM7.99992 12.199C9.06659 11.2434 9.94436 10.4238 10.6333 9.74029C11.3221 9.05674 11.8666 8.46251 12.2666 7.95763C12.6666 7.45185 12.9444 7.00163 13.0999 6.60696C13.2555 6.21229 13.3333 5.82074 13.3333 5.43229C13.3333 4.76562 13.111 4.21007 12.6666 3.76562C12.2221 3.32118 11.6666 3.09896 10.9999 3.09896C10.4777 3.09896 9.99436 3.24607 9.54992 3.54029C9.10547 3.83451 8.79992 4.20963 8.63325 4.66563H7.36659C7.19992 4.21007 6.89436 3.83496 6.44992 3.54029C6.00547 3.24562 5.52214 3.09851 4.99992 3.09896C4.33325 3.09896 3.7777 3.32118 3.33325 3.76562C2.88881 4.21007 2.66659 4.76562 2.66659 5.43229C2.66659 5.82118 2.74436 6.21296 2.89992 6.60763C3.05547 7.00229 3.33325 7.45229 3.73325 7.95763C4.13325 8.46296 4.6777 9.0574 5.36659 9.74096C6.05547 10.4245 6.93325 11.2438 7.99992 12.199Z"
                                  fill="#008069"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className={styles.spareParts_name}>
                            <div>
                              <p>₦{Number(product.price).toLocaleString()}</p>
                            </div>

                            <div
                              onClick={() =>
                                addToCartHandler(product.magento_product_id)
                              }
                            >
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5.5 13.5C5.77614 13.5 6 13.2761 6 13C6 12.7239 5.77614 12.5 5.5 12.5C5.22386 12.5 5 12.7239 5 13C5 13.2761 5.22386 13.5 5.5 13.5Z"
                                  stroke="#008069"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M12.5 13.5C12.7761 13.5 13 13.2761 13 13C13 12.7239 12.7761 12.5 12.5 12.5C12.2239 12.5 12 12.7239 12 13C12 13.2761 12.2239 13.5 12.5 13.5Z"
                                  stroke="#008069"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M1.5 2.5H3.5L5 11H13"
                                  stroke="#008069"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M5 9H12.795C12.8528 9.00004 12.9089 8.98004 12.9536 8.9434C12.9983 8.90676 13.029 8.85576 13.0403 8.79906L13.9403 4.29906C13.9476 4.26278 13.9467 4.22533 13.9377 4.18943C13.9288 4.15352 13.9119 4.12006 13.8885 4.09145C13.865 4.06284 13.8355 4.03979 13.802 4.02398C13.7686 4.00816 13.732 3.99997 13.695 4H4"
                                  stroke="#008069"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <Link to={`/spare-parts/engine`} className={styles.para2}>
                      See more
                    </Link>
                  </div>
                )}
                {tyreProducts?.length > 0 && (
                  <div className={styles.spareParts}>
                    <p className={styles.para}>Tyre</p>
                    <div className={styles.spareParts_inner}>
                      {tyreProducts?.map((product) => (
                        <div
                          className={styles.spareParts_card}
                          key={product.id}
                        >
                          <div className={styles.spareParts_card_image_wrapper}>
                            <div
                              className={
                                styles.spareParts_card_image_wrapper_inner
                              }
                            >
                              <img
                                src={product.product_image}
                                alt={product.product_title}
                              />
                            </div>
                          </div>
                          <div className={styles.spareParts_name}>
                            <div>
                              <p>{removePrefix(product.product_title)}</p>
                            </div>

                            <div>
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.99992 13.999L7.03325 13.1323C5.91103 12.1212 4.98325 11.249 4.24992 10.5156C3.51659 9.78229 2.93325 9.12385 2.49992 8.54029C2.06659 7.95718 1.76392 7.42118 1.59192 6.93229C1.41992 6.4434 1.3337 5.9434 1.33325 5.43229C1.33325 4.38785 1.68325 3.51562 2.38325 2.81562C3.08325 2.11562 3.95547 1.76562 4.99992 1.76562C5.5777 1.76562 6.1277 1.88785 6.64992 2.13229C7.17214 2.37674 7.62214 2.72118 7.99992 3.16562C8.3777 2.72118 8.8277 2.37674 9.34992 2.13229C9.87214 1.88785 10.4221 1.76562 10.9999 1.76562C12.0444 1.76562 12.9166 2.11562 13.6166 2.81562C14.3166 3.51562 14.6666 4.38785 14.6666 5.43229C14.6666 5.9434 14.5804 6.4434 14.4079 6.93229C14.2355 7.42118 13.9328 7.95718 13.4999 8.54029C13.0666 9.12385 12.4833 9.78229 11.7499 10.5156C11.0166 11.249 10.0888 12.1212 8.96659 13.1323L7.99992 13.999ZM7.99992 12.199C9.06659 11.2434 9.94436 10.4238 10.6333 9.74029C11.3221 9.05674 11.8666 8.46251 12.2666 7.95763C12.6666 7.45185 12.9444 7.00163 13.0999 6.60696C13.2555 6.21229 13.3333 5.82074 13.3333 5.43229C13.3333 4.76562 13.111 4.21007 12.6666 3.76562C12.2221 3.32118 11.6666 3.09896 10.9999 3.09896C10.4777 3.09896 9.99436 3.24607 9.54992 3.54029C9.10547 3.83451 8.79992 4.20963 8.63325 4.66563H7.36659C7.19992 4.21007 6.89436 3.83496 6.44992 3.54029C6.00547 3.24562 5.52214 3.09851 4.99992 3.09896C4.33325 3.09896 3.7777 3.32118 3.33325 3.76562C2.88881 4.21007 2.66659 4.76562 2.66659 5.43229C2.66659 5.82118 2.74436 6.21296 2.89992 6.60763C3.05547 7.00229 3.33325 7.45229 3.73325 7.95763C4.13325 8.46296 4.6777 9.0574 5.36659 9.74096C6.05547 10.4245 6.93325 11.2438 7.99992 12.199Z"
                                  fill="#008069"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className={styles.spareParts_name}>
                            <div>
                              <p>₦{Number(product.price).toLocaleString()}</p>
                            </div>

                            <div
                              onClick={() =>
                                addToCartHandler(product.magento_product_id)
                              }
                            >
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5.5 13.5C5.77614 13.5 6 13.2761 6 13C6 12.7239 5.77614 12.5 5.5 12.5C5.22386 12.5 5 12.7239 5 13C5 13.2761 5.22386 13.5 5.5 13.5Z"
                                  stroke="#008069"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M12.5 13.5C12.7761 13.5 13 13.2761 13 13C13 12.7239 12.7761 12.5 12.5 12.5C12.2239 12.5 12 12.7239 12 13C12 13.2761 12.2239 13.5 12.5 13.5Z"
                                  stroke="#008069"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M1.5 2.5H3.5L5 11H13"
                                  stroke="#008069"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M5 9H12.795C12.8528 9.00004 12.9089 8.98004 12.9536 8.9434C12.9983 8.90676 13.029 8.85576 13.0403 8.79906L13.9403 4.29906C13.9476 4.26278 13.9467 4.22533 13.9377 4.18943C13.9288 4.15352 13.9119 4.12006 13.8885 4.09145C13.865 4.06284 13.8355 4.03979 13.802 4.02398C13.7686 4.00816 13.732 3.99997 13.695 4H4"
                                  stroke="#008069"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <Link to={`/spare-parts/tyre`} className={styles.para2}>
                      See more
                    </Link>
                  </div>
                )}
                {donglesProducts.length > 1 && (
                  <div className={styles.spareParts}>
                    <p className={styles.para}>Trackers</p>
                    <div className={styles.spareParts_inner}>
                      {donglesProducts?.map((product) => (
                        <div
                          className={styles.spareParts_card}
                          key={product.id}
                        >
                          <div className={styles.spareParts_card_image_wrapper}>
                            <div
                              className={
                                styles.spareParts_card_image_wrapper_inner
                              }
                            >
                              <img
                                src={product.product_image}
                                alt={product.product_title}
                              />
                            </div>
                          </div>
                          <div className={styles.spareParts_name}>
                            <div>
                              <p>{removePrefix(product.product_title)}</p>
                            </div>

                            <div>
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.99992 13.999L7.03325 13.1323C5.91103 12.1212 4.98325 11.249 4.24992 10.5156C3.51659 9.78229 2.93325 9.12385 2.49992 8.54029C2.06659 7.95718 1.76392 7.42118 1.59192 6.93229C1.41992 6.4434 1.3337 5.9434 1.33325 5.43229C1.33325 4.38785 1.68325 3.51562 2.38325 2.81562C3.08325 2.11562 3.95547 1.76562 4.99992 1.76562C5.5777 1.76562 6.1277 1.88785 6.64992 2.13229C7.17214 2.37674 7.62214 2.72118 7.99992 3.16562C8.3777 2.72118 8.8277 2.37674 9.34992 2.13229C9.87214 1.88785 10.4221 1.76562 10.9999 1.76562C12.0444 1.76562 12.9166 2.11562 13.6166 2.81562C14.3166 3.51562 14.6666 4.38785 14.6666 5.43229C14.6666 5.9434 14.5804 6.4434 14.4079 6.93229C14.2355 7.42118 13.9328 7.95718 13.4999 8.54029C13.0666 9.12385 12.4833 9.78229 11.7499 10.5156C11.0166 11.249 10.0888 12.1212 8.96659 13.1323L7.99992 13.999ZM7.99992 12.199C9.06659 11.2434 9.94436 10.4238 10.6333 9.74029C11.3221 9.05674 11.8666 8.46251 12.2666 7.95763C12.6666 7.45185 12.9444 7.00163 13.0999 6.60696C13.2555 6.21229 13.3333 5.82074 13.3333 5.43229C13.3333 4.76562 13.111 4.21007 12.6666 3.76562C12.2221 3.32118 11.6666 3.09896 10.9999 3.09896C10.4777 3.09896 9.99436 3.24607 9.54992 3.54029C9.10547 3.83451 8.79992 4.20963 8.63325 4.66563H7.36659C7.19992 4.21007 6.89436 3.83496 6.44992 3.54029C6.00547 3.24562 5.52214 3.09851 4.99992 3.09896C4.33325 3.09896 3.7777 3.32118 3.33325 3.76562C2.88881 4.21007 2.66659 4.76562 2.66659 5.43229C2.66659 5.82118 2.74436 6.21296 2.89992 6.60763C3.05547 7.00229 3.33325 7.45229 3.73325 7.95763C4.13325 8.46296 4.6777 9.0574 5.36659 9.74096C6.05547 10.4245 6.93325 11.2438 7.99992 12.199Z"
                                  fill="#008069"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className={styles.spareParts_name}>
                            <div>
                              <p>₦{Number(product.price).toLocaleString()}</p>
                            </div>

                            <div
                              onClick={() =>
                                addToCartHandler(product.magento_product_id)
                              }
                            >
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5.5 13.5C5.77614 13.5 6 13.2761 6 13C6 12.7239 5.77614 12.5 5.5 12.5C5.22386 12.5 5 12.7239 5 13C5 13.2761 5.22386 13.5 5.5 13.5Z"
                                  stroke="#008069"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M12.5 13.5C12.7761 13.5 13 13.2761 13 13C13 12.7239 12.7761 12.5 12.5 12.5C12.2239 12.5 12 12.7239 12 13C12 13.2761 12.2239 13.5 12.5 13.5Z"
                                  stroke="#008069"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M1.5 2.5H3.5L5 11H13"
                                  stroke="#008069"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M5 9H12.795C12.8528 9.00004 12.9089 8.98004 12.9536 8.9434C12.9983 8.90676 13.029 8.85576 13.0403 8.79906L13.9403 4.29906C13.9476 4.26278 13.9467 4.22533 13.9377 4.18943C13.9288 4.15352 13.9119 4.12006 13.8885 4.09145C13.865 4.06284 13.8355 4.03979 13.802 4.02398C13.7686 4.00816 13.732 3.99997 13.695 4H4"
                                  stroke="#008069"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <Link to={`/spare-parts/tracker`} className={styles.para2}>
                      See more
                    </Link>
                  </div>
                )}
                {batteryProducts?.length > 0 && (
                  <div className={styles.spareParts}>
                    <p className={styles.para}>Battery</p>
                    <div className={styles.spareParts_inner}>
                      {batteryProducts?.map((product) => (
                        <div
                          className={styles.spareParts_card}
                          key={product.id}
                        >
                          <div className={styles.spareParts_card_image_wrapper}>
                            <div
                              className={
                                styles.spareParts_card_image_wrapper_inner
                              }
                            >
                              <img
                                src={product.product_image}
                                alt={product.product_title}
                              />
                            </div>
                          </div>
                          <div className={styles.spareParts_name}>
                            <div>
                              <p>{removePrefix(product.product_title)}</p>
                            </div>

                            <div>
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.99992 13.999L7.03325 13.1323C5.91103 12.1212 4.98325 11.249 4.24992 10.5156C3.51659 9.78229 2.93325 9.12385 2.49992 8.54029C2.06659 7.95718 1.76392 7.42118 1.59192 6.93229C1.41992 6.4434 1.3337 5.9434 1.33325 5.43229C1.33325 4.38785 1.68325 3.51562 2.38325 2.81562C3.08325 2.11562 3.95547 1.76562 4.99992 1.76562C5.5777 1.76562 6.1277 1.88785 6.64992 2.13229C7.17214 2.37674 7.62214 2.72118 7.99992 3.16562C8.3777 2.72118 8.8277 2.37674 9.34992 2.13229C9.87214 1.88785 10.4221 1.76562 10.9999 1.76562C12.0444 1.76562 12.9166 2.11562 13.6166 2.81562C14.3166 3.51562 14.6666 4.38785 14.6666 5.43229C14.6666 5.9434 14.5804 6.4434 14.4079 6.93229C14.2355 7.42118 13.9328 7.95718 13.4999 8.54029C13.0666 9.12385 12.4833 9.78229 11.7499 10.5156C11.0166 11.249 10.0888 12.1212 8.96659 13.1323L7.99992 13.999ZM7.99992 12.199C9.06659 11.2434 9.94436 10.4238 10.6333 9.74029C11.3221 9.05674 11.8666 8.46251 12.2666 7.95763C12.6666 7.45185 12.9444 7.00163 13.0999 6.60696C13.2555 6.21229 13.3333 5.82074 13.3333 5.43229C13.3333 4.76562 13.111 4.21007 12.6666 3.76562C12.2221 3.32118 11.6666 3.09896 10.9999 3.09896C10.4777 3.09896 9.99436 3.24607 9.54992 3.54029C9.10547 3.83451 8.79992 4.20963 8.63325 4.66563H7.36659C7.19992 4.21007 6.89436 3.83496 6.44992 3.54029C6.00547 3.24562 5.52214 3.09851 4.99992 3.09896C4.33325 3.09896 3.7777 3.32118 3.33325 3.76562C2.88881 4.21007 2.66659 4.76562 2.66659 5.43229C2.66659 5.82118 2.74436 6.21296 2.89992 6.60763C3.05547 7.00229 3.33325 7.45229 3.73325 7.95763C4.13325 8.46296 4.6777 9.0574 5.36659 9.74096C6.05547 10.4245 6.93325 11.2438 7.99992 12.199Z"
                                  fill="#008069"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className={styles.spareParts_name}>
                            <div>
                              <p>₦{Number(product.price).toLocaleString()}</p>
                            </div>

                            <div
                              onClick={() =>
                                addToCartHandler(product.magento_product_id)
                              }
                            >
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5.5 13.5C5.77614 13.5 6 13.2761 6 13C6 12.7239 5.77614 12.5 5.5 12.5C5.22386 12.5 5 12.7239 5 13C5 13.2761 5.22386 13.5 5.5 13.5Z"
                                  stroke="#008069"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M12.5 13.5C12.7761 13.5 13 13.2761 13 13C13 12.7239 12.7761 12.5 12.5 12.5C12.2239 12.5 12 12.7239 12 13C12 13.2761 12.2239 13.5 12.5 13.5Z"
                                  stroke="#008069"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M1.5 2.5H3.5L5 11H13"
                                  stroke="#008069"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M5 9H12.795C12.8528 9.00004 12.9089 8.98004 12.9536 8.9434C12.9983 8.90676 13.029 8.85576 13.0403 8.79906L13.9403 4.29906C13.9476 4.26278 13.9467 4.22533 13.9377 4.18943C13.9288 4.15352 13.9119 4.12006 13.8885 4.09145C13.865 4.06284 13.8355 4.03979 13.802 4.02398C13.7686 4.00816 13.732 3.99997 13.695 4H4"
                                  stroke="#008069"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <Link to={`/spare-parts/battery`} className={styles.para2}>
                      See more
                    </Link>
                  </div>
                )}
              </div>
            )}
          </div>
          {cartList?.cart_list?.length !== 0 && (
            <Link to="/spare-parts/cart">
              <div className={styles.cart_button}>
                <p>{cartList?.cart_list?.length - 1} Items Added</p>
                <p> Go to Cart</p>
              </div>
            </Link>
          )}
        </div>
      )}
    </div>
  );
}

export default SparepartsList;
