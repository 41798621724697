import React, { useCallback, useEffect, useState } from "react";
import styles from "../papers/Papers.module.css";
import NavBar from "../../../Components/interface/NavBar";
import BottomNav from "../../../Components/BottomNav";
import Button from "../../../Components/interface/Button";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../Components/UI/Modal";
import Modal2 from "../../../Components/UI/Modal";
import { authActions } from "../../../store/redux-store/Index";
import ServiceApi from "../../../utils/api/ServiceApi";

function ServiceInvoice(props) {
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [errMsgModal, setErrMsgModal] = useState(false);
  const [bankDetails, setBankDetails] = useState(false);
  const [generatedAccountData, setGeneratedAccountData] = useState([]);
  const [checkoutData, setCheckoutData] = useState([]);

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const personalData = useSelector((data) => data.auth.generalLoginData);

  const vehicleIndex = useSelector((data) => data.dongles.currentVehicle);

  const magento = useSelector((data) => data.auth.magentoProdId);
  const deliveryData = useSelector((data) => data.auth.deliveryDetails);

  const checkoutCallback = useCallback(() => {
    const getPapersHandler = async () => {
      try {
        setLoading(true);
        const checkoutRes = await ServiceApi.checkout({
          plate_no: personalData?.vehicle_data[vehicleIndex]?.plate_number,
        });
        setLoading(false);
        setCheckoutData(checkoutRes.data);
      } catch (err) {
        setLoading(false);
        console.log(err?.response?.data?.error);
      }
    };
    getPapersHandler();
  }, [personalData, vehicleIndex]);

  useEffect(() => {
    checkoutCallback();
  }, [checkoutCallback]);

  const delivery = checkoutData.cart_list?.find((data, index) =>
    data?.product_title.includes("Delivery")
  );

  const order_type = checkoutData?.cart_list?.filter(
    (data) => data.product_category_type !== null
  );

  // useEffect(() => {
  //   if (OTP.length >= 23) {
  //     const getCoupon = async () => {
  //       try {
  //         const couponRes = await ServiceApi.couponVerification({
  //           OTP,
  //           id: checkoutData?.customer_info[0]?.id,
  //         });
  //         if (couponRes.data === "Expired or Incorrect Coupon Code") {
  //           setModal(true);
  //           setOTP("");
  //         }
  //         setDiscount(couponRes.data[0]?.percentage);
  //       } catch (err) {
  //         console.log(err?.response?.data?.error);
  //       }
  //     };
  //     getCoupon();
  //   }
  // }, [OTP, checkoutData?.customer_info]);

  // const otpChangeHandler = (e) => {
  //   setOTP(e.target.value);
  // };

  const submitOrderHandler = async () => {
    setLoading(true);
    try {
      const submitOrderRes = await ServiceApi.submitOrder({
        customer_id: checkoutData?.customer_info[0]?.id,
        firstname: personalData.fullname,
        lastname: "",
        street: deliveryData.street,
        location: deliveryData.location,
        service_delivery_date: "",
        order_type: `${order_type.map((data) => data.product_category_type)}`,
        city: deliveryData.street,
        state: deliveryData.state,
        plateno: checkoutData?.customer_info[0]?.plate_no,
        phone: checkoutData?.customer_info[0]?.phone_no,
        products: magento,
      });
      dispatch(authActions.clearMagentoId());

      const generatedAccount = await ServiceApi.generateBankAccount({
        order_id: submitOrderRes.data.order,
      });

      setGeneratedAccountData(generatedAccount.data);
      setBankDetails(true);
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const completeTransactionHandler = () => {
    navigate("/papers/success");
  };
  const backHandler = () => {
    setModal(false);
    setErrMsgModal(false);
  };
  const commafy = (num) => {
    return num?.toLocaleString();
  };

  return (
    <div>
      <ToastContainer />

      {modal && (
        <Modal
          title="Wrong or expired Coupon!"
          message="The coupon code you entered is either not valid or expired."
          back=""
          close={backHandler}
          accept=""
        />
      )}
      {errMsgModal && (
        <Modal2
          title="Error!"
          message="Order Already Exists, Check 'My orders' page to see order."
          back=""
          close={backHandler}
          accept=""
        />
      )}
      <NavBar title="Invoice" setShowMenu={props.setShowMenu} />
      <div className={styles.head}>
        <div className={styles.main_hero}>
          <div className={styles.cartNav}>
            <p>CART</p>
            <p>DELIVERY</p>
            <p style={{ color: "#008069", borderBottom: "5px solid #008069" }}>
              PAYMENT
            </p>
          </div>
          <div className={styles.cartNav_progress}></div>
        </div>
        <div>
          {magento?.map((data, index) => (
            <input
              type="hidden"
              name="products"
              id="products[]"
              defaultValue={data}
            />
          ))}
        </div>
        <div className={styles.total2}>
          <div className={styles.headingCost}>
            <p>Total amount to be paid</p>
            <h4>₦ {commafy(checkoutData?.cart_sum)}</h4>
          </div>

          <div>
            <p>Sub Total</p>
            <h4>₦{commafy(checkoutData?.cart_sum - delivery?.price)}</h4>
          </div>
          <div>
            <p>Delivery Fee</p>
            <h4>₦ {commafy(delivery?.price)}</h4>
          </div>
          <div className={styles.grandTotal}>
            <p>Grand Total</p>
            <h4>₦ {commafy(checkoutData?.cart_sum)}</h4>
          </div>
          {/* <div className={styles.form}>
            <p>Enter discount code (optional)</p>
            <Input type="text" value={OTP} onChange={otpChangeHandler} />
          </div> */}
        </div>
        {!bankDetails && (
          <div className={styles.button2}>
            {!loading && (
              <Button onClick={submitOrderHandler}>
                Pay via bank transfer
              </Button>
            )}
            {loading && <Button disabled="false">Loading...</Button>}
          </div>
        )}
        {bankDetails && (
          <div className={styles.bankDetails}>
            <h3>Here is the account details you are to make transfer to.</h3>
            <h2>{generatedAccountData.account_number}</h2>
            <h3>Account name: {generatedAccountData.account_name}</h3>
            <h3>Bank Name: {generatedAccountData.bank_name}</h3>
            <div className={styles.button}>
              <Button onClick={completeTransactionHandler}>
                Wait to verify the transaction
              </Button>
            </div>
          </div>
        )}
      </div>
      <BottomNav />
    </div>
  );
}

export default ServiceInvoice;
