import { createSlice } from "@reduxjs/toolkit";

const defaultDongleState = {
  dongleData: [],
  currentVehicle: 0,
  liveMetrics: [],
  notifications: [],
  thirdPartyPlusInfo: [],
};

const dongleSlice = createSlice({
  name: "dongle",
  initialState: defaultDongleState,
  reducers: {
    donglesInfo(state, action) {
      const payload = action.payload;
      state.dongleData = payload;
    },

    thirdPartyPlusKyc(state, action) {
      const payload = action.payload;
      state.thirdPartyPlusInfo = payload;
    },
    clearThirdPartyKyc(state) {
      state.thirdPartyPlusInfo = [];
    },
    allNotifications(state, action) {
      const payload = action.payload;
      state.notifications.push(payload);
    },

    clearDonglesInfo(state) {
      state.dongleData = [];
    },

    clearNotifications(state) {
      state.notifications = [];
    },

    changeVehicle(state, action) {
      const payload = action.payload;
      state.currentVehicle = payload;
    },

    getLiveMetrics(state, action) {
      const payload = action.payload;
      state.liveMetrics.splice(0, 1, payload);
    },
  },
});

export const dongleActions = dongleSlice.actions;
export default dongleSlice;
