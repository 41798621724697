import React, { useCallback, useEffect, useState } from "react";
import NavBar from "../../../Components/interface/NavBar";
import styles from "./BuyDongle.module.css";
import image4 from "../../../asset/images/car.png";
import suggestionImage1 from "../../../asset/images/suggestion_images/Avatar (1).png";
import suggestionImage2 from "../../../asset/images/suggestion_images/Avatar.png";
import suggestionImage3 from "../../../asset/images/suggestion_images/istockphoto-1177351973-612x612 1.png";
import BottomNav from "../../../Components/BottomNav";
import "react-multi-carousel/lib/styles.css";
import ScanForm from "../../../Components/interface/ScanForm";
import { useDispatch, useSelector } from "react-redux";
import VehicleDetails2 from "../../../Components/interface/VehicleDetails2";
import VehicleInfo from "../../../Components/customer-profile/VehicleInfo";
import CustomerScans from "../../../utils/api/CustomerScans";
import { dongleActions } from "../../../store/redux-store/dongle-store";
import Modal from "../../../Components/UI/Modal";
import HomeVehiclePapers from "../../../Components/interface/HomeVehiclePapers";
import marketting from "../../../asset/images/Save Money/2.jpg";
import marketting2 from "../../../asset/images/Save Money/5.jpg";
import ServiceApi from "../../../utils/api/ServiceApi";
import Authentication from "../../../utils/api/Authentication";
import { authActions } from "../../../store/redux-store/Index";
import { Link, useNavigate } from "react-router-dom";

function BuyDongle(props) {
  const [diagForm, setDiagForm] = useState(false);
  const [showVehicleDetails, setShowVehicleDetails] = useState(false);
  const [initLoading, setInitLoading] = useState(false);
  const [carState, setCarState] = useState("");
  const [carImage, setCarImage] = useState([]);
  const [device, setDevice] = useState(null);
  const [carImages, setCarImages] = useState([]);
  const [successfulRequest, setSuccessfulRequest] = useState(false);

  const personalData = useSelector((data) => data.auth);

  // get index of vehicle to get current vehicle data
  const dongle = useSelector((data) => data.dongles);

  const liveMetrics = dongle.liveMetrics[0];

  const dispatch = useDispatch();

  const navigate = useNavigate();

  // defining all functions
  const dongleDataCallback = useCallback(async () => {
    dispatch(dongleActions.clearDonglesInfo());
    setInitLoading(true);
    try {
      const res = await CustomerScans.getDevices({
        id: personalData.generalLoginData.id,
        access_token: personalData.token,
      });
      dispatch(dongleActions.donglesInfo(res.data));
      setDevice(res.data);
      setInitLoading(false);
    } catch (err) {
      setInitLoading(false);
    }
  }, [personalData.generalLoginData, personalData.token, dispatch]);

  const fetchBasicMetrics = useCallback(async () => {
    try {
      const res = await CustomerScans.scanVehiclesHandler({
        user: personalData.generalLoginData.id,
        device: device?.dongle,
        access_token: personalData.token,
      });
      dispatch(dongleActions.getLiveMetrics(res.data.data));
    } catch (err) {
      setInitLoading(false);
    }
  }, [personalData.generalLoginData, personalData.token, device, dispatch]);

  const fetchUserDataCallback = useCallback(() => {
    const fetchUserDetails = async () => {
      // Endpoint to get the customers information
      try {
        const userDetails = await Authentication.fetchUserDetails({
          user_id: personalData.generalLoginData.id,
          access_token: personalData.token,
        });

        dispatch(authActions.generalLoginData(userDetails.data));
      } catch (err) {
        if (
          err?.response?.data.errors ===
          "Authentication Error - Please login again."
        ) {
          dispatch(authActions.logOut());
          dispatch(authActions.resetToDefault());
          dispatch(authActions.resetToDefault2());
          dispatch(authActions.resetLiveMetrics());
          dispatch(dongleActions.clearDonglesInfo());
          dispatch(authActions.loggedOut());
          navigate("/login");
          window.location.reload();
        }
      }
    };
    fetchUserDetails();
  }, [
    navigate,
    dispatch,
    personalData.token,
    personalData.generalLoginData.id,
  ]);

  // Fetch User Data
  useEffect(() => {
    fetchUserDataCallback();
  }, [fetchUserDataCallback]);

  useEffect(() => {
    if (dongle?.dongleData?.dongle?.length !== 0) {
      //fetch basic metrics
      fetchBasicMetrics();
    }
  }, [fetchBasicMetrics, dongle?.dongleData]);

  const vehicle_data =
    personalData?.generalLoginData?.vehicle_data?.length !== 0
      ? personalData?.generalLoginData?.vehicle_data
      : personalData?.generalLoginData?.vendor_vehicle_data;

  useEffect(() => {
    const fetchVehicleImages = async () => {
      try {
        const imageRes = await ServiceApi.fetchVehicles();
        setCarImages(imageRes.data);
        const vehicleImage = imageRes.data.find(
          (data) =>
            data.year === vehicle_data[dongle?.currentVehicle]?.vehicle_year &&
            data.model?.toUpperCase() ===
              vehicle_data[
                dongle?.currentVehicle
              ]?.vehicle_model?.toUpperCase() &&
            data.make?.toUpperCase() ===
              vehicle_data[dongle?.currentVehicle]?.vehicle_make?.toUpperCase()
        );

        setCarImage(vehicleImage);
      } catch (err) {
        console.log(err);
      }
    };
    fetchVehicleImages();
  }, [dongle?.currentVehicle, vehicle_data]);

  const requestForVehicleImageHandler = async () => {
    try {
      setInitLoading(true);
      const imageRequestRes = await CustomerScans.sendTicket({
        to_name: "Admin",
        from_name: `Request for car image`,
        message: `Please I need the image of my ${
          vehicle_data[dongle?.currentVehicle]?.vehicle_year
        } ${vehicle_data[
          dongle?.currentVehicle
        ]?.vehicle_make?.toUpperCase()} ${vehicle_data[
          dongle?.currentVehicle
        ]?.vehicle_model?.toUpperCase()} on the home page of the Motormata app`,
      });
      setInitLoading(false);
      setSuccessfulRequest(true);
      return imageRequestRes.data;
    } catch (err) {
      setInitLoading(false);
      console.log(err?.response?.data);
    }
  };

  let closeMenuHandler = () => {
    props.setShowMenu(false);
  };

  useEffect(() => {
    dispatch(dongleActions.clearDonglesInfo());
    dongleDataCallback();
  }, [dongleDataCallback, dispatch]);

  useEffect(() => {
    let intervalId;
    if (liveMetrics?.scan_data?.vehicle_live_data?.online === true) {
      fetchBasicMetrics(); // Initial fetch
      intervalId = setInterval(() => {
        fetchBasicMetrics(); // Fetch data every 30 seconds
      }, 30000); // 30 seconds in milliseconds
    }

    return () => {
      clearInterval(intervalId); // Clear interval when component unmounts
    };
  }, [liveMetrics?.scan_data?.vehicle_live_data?.online, fetchBasicMetrics]);

  const backHandler = () => {
    setSuccessfulRequest(false);
  };

  useEffect(() => {
    if (liveMetrics?.scan_data?.vehicle_live_data?.online !== true) {
      setCarState("Offline");
    } else if (
      liveMetrics?.scan_data?.vehicle_live_data?.online === true &&
      liveMetrics?.scan_data?.vehicle_live_data?.speed === 0
    ) {
      setCarState("Parked");
    } else {
      setCarState("Online");
    }
  }, [
    liveMetrics?.scan_data?.vehicle_live_data?.online,
    liveMetrics?.scan_data?.vehicle_live_data?.speed,
  ]);

  return (
    <section className={styles.buyDongle}>
      {successfulRequest && (
        <Modal
          title="Success"
          message="Request sent. Image will be updated as soon as possible."
          accept="Back"
          back=""
          close={backHandler}
          confirm={backHandler}
        />
      )}
      {diagForm && <ScanForm setDiagForm={setDiagForm} />}
      <NavBar
        onlineStatus={liveMetrics?.scan_data?.vehicle_live_data?.online}
        title="Home"
        setShowMenu={props.setShowMenu}
      />
      {initLoading ? (
        <Modal message="" back="" accept="" loader="true" />
      ) : (
        <div className={styles.buyDongle__body} onClick={closeMenuHandler}>
          <div className={styles.buyDongle__bodyInner}>
            {!props.registerVehicle && (
              <div className={styles.home_unregistered_vehicles}>
                <div className={styles.carType}>
                  <div className={styles.buyDongle__wrapper}>
                    <img src={carImage?.imageUrl || image4} alt="car" />
                  </div>
                  <div className={styles.online_status}>
                    {props.onlineStatus ? (
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.9999 13.5999C8.88356 13.5999 9.5999 12.8836 9.5999 11.9999C9.5999 11.1162 8.88356 10.3999 7.9999 10.3999C7.11625 10.3999 6.3999 11.1162 6.3999 11.9999C6.3999 12.8836 7.11625 13.5999 7.9999 13.5999Z"
                          fill="#008069"
                        />
                        <path
                          d="M0.800049 5.91997C1.70093 4.81334 2.80886 3.92705 4.05025 3.31997C5.29163 2.71289 6.63804 2.39893 8.00005 2.39893C9.36205 2.39893 10.7085 2.71289 11.9499 3.31997C13.1912 3.92705 14.2992 4.81334 15.2 5.91997L14 7.03997C13.2493 6.09508 12.326 5.33832 11.2916 4.81997C10.2571 4.30162 9.13505 4.03354 8.00005 4.03354C6.86504 4.03354 5.74303 4.30162 4.70855 4.81997C3.67406 5.33832 2.75078 6.09508 2.00005 7.03997L0.800049 5.91997ZM3.76005 8.47997C4.28572 7.83026 4.94107 7.30807 5.68032 6.94986C6.41957 6.59164 7.2249 6.40606 8.04005 6.40606C8.8552 6.40606 9.66053 6.59164 10.3998 6.94986C11.139 7.30807 11.7944 7.83026 12.32 8.47997L11.2 9.59997C10.8252 9.10477 10.3503 8.7051 9.8103 8.43048C9.27029 8.15585 8.67899 8.01328 8.08005 8.01328C7.48111 8.01328 6.88981 8.15585 6.3498 8.43048C5.80979 8.7051 5.33486 9.10477 4.96005 9.59997L3.76005 8.47997Z"
                          fill="#008069"
                        />
                      </svg>
                    ) : (
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.9999 13.5999C8.88356 13.5999 9.5999 12.8836 9.5999 11.9999C9.5999 11.1162 8.88356 10.3999 7.9999 10.3999C7.11625 10.3999 6.3999 11.1162 6.3999 11.9999C6.3999 12.8836 7.11625 13.5999 7.9999 13.5999Z"
                          fill="#8EA396"
                        />
                        <path
                          d="M0.800049 5.91997C1.70093 4.81334 2.80886 3.92705 4.05025 3.31997C5.29163 2.71289 6.63804 2.39893 8.00005 2.39893C9.36205 2.39893 10.7085 2.71289 11.9499 3.31997C13.1912 3.92705 14.2992 4.81334 15.2 5.91997L14 7.03997C13.2493 6.09508 12.326 5.33832 11.2916 4.81997C10.2571 4.30162 9.13505 4.03354 8.00005 4.03354C6.86504 4.03354 5.74303 4.30162 4.70855 4.81997C3.67406 5.33832 2.75078 6.09508 2.00005 7.03997L0.800049 5.91997ZM3.76005 8.47997C4.28572 7.83026 4.94107 7.30807 5.68032 6.94986C6.41957 6.59164 7.2249 6.40606 8.04005 6.40606C8.8552 6.40606 9.66053 6.59164 10.3998 6.94986C11.139 7.30807 11.7944 7.83026 12.32 8.47997L11.2 9.59997C10.8252 9.10477 10.3503 8.7051 9.8103 8.43048C9.27029 8.15585 8.67899 8.01328 8.08005 8.01328C7.48111 8.01328 6.88981 8.15585 6.3498 8.43048C5.80979 8.7051 5.33486 9.10477 4.96005 9.59997L3.76005 8.47997Z"
                          fill="#8EA396"
                        />
                      </svg>
                    )}
                    <p>{carState}</p>
                  </div>
                </div>
                {carImage?.imageUrl === undefined && (
                  <div
                    onClick={requestForVehicleImageHandler}
                    className={styles.request_for_vehicle_image}
                  >
                    <p>Click to request for your vehicle image</p>
                  </div>
                )}
                <VehicleDetails2
                  showVehicleDetails={showVehicleDetails}
                  setShowVehicleDetails={setShowVehicleDetails}
                  vehicle_make={
                    vehicle_data[dongle.currentVehicle]?.vehicle_make
                  }
                  vehicle_model={
                    vehicle_data[dongle.currentVehicle]?.vehicle_model
                  }
                  vehicle_year={
                    vehicle_data[dongle.currentVehicle]?.vehicle_year
                  }
                  vin="Not Available"
                  plate_number={
                    vehicle_data[dongle.currentVehicle]?.plate_number
                  }
                />
                {showVehicleDetails && (
                  <VehicleInfo
                    carImages={carImages}
                    info={vehicle_data[dongle.currentVehicle]}
                  />
                )}
              </div>
            )}
          </div>

          <div>
            <HomeVehiclePapers />
          </div>

          <div>
            <div className={styles.suggestions}>
              <p>Suggestions For You</p>
              <div className={styles.suggestions_inner}>
                <div className={styles.suggestions_card}>
                  <Link to="/inspection-report">
                    <div className={styles.suggestions_card_image}>
                      <div className={styles.suggestions_card_image_inner}>
                        <img src={suggestionImage1} alt="suggestion" />
                      </div>
                    </div>
                    <div className={styles.suggestions_card_text}>
                      <h1>Inspection Report</h1>
                      <p>Car Secrets Revealed View Demo Report</p>
                    </div>
                  </Link>
                </div>

                <div className={styles.suggestions_card}>
                  <Link to="/insurance">
                    {" "}
                    <div className={styles.suggestions_card_image}>
                      <div className={styles.suggestions_card_image_inner}>
                        <img src={suggestionImage2} alt="suggestion" />
                      </div>
                    </div>
                    <div className={styles.suggestions_card_text}>
                      <h1>3RD PARTY PLUS</h1>
                      <p>Up to ₦500,000 for your Peace of mind</p>
                    </div>
                  </Link>
                </div>

                <div className={styles.suggestions_card}>
                  <Link to="/motorAi">
                    <div className={styles.suggestions_card_image}>
                      <div className={styles.suggestions_card_image_inner}>
                        <img src={suggestionImage3} alt="suggestion" />
                      </div>
                    </div>

                    <div className={styles.suggestions_card_text}>
                      <h1>Chat with Moto AI</h1>
                      <p>AI Mechanic in your pocket always</p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div>
            {/* {device?.dongle_info ? (
              <RoutineMaintenance />
            ) : ( */}
            <div className={styles.suggestions}>
              <p>Offers</p>
              <div className={styles.products}>
                <div className={styles.home_marketting}>
                  <div className={styles.home_marketting_inner}>
                    {/* <div className={styles.home_marketting_inner_text}>
                    <p>
                      SAFEGUARD YOUR VEHICLE, UNLOCK THE POWER OF PEACE OF MIND.
                    </p>
                    <h1>Stay connected with your vehicle.</h1>
                    <Link to="/track">
                      <p>Order Now</p>
                    </Link>
                  </div> */}
                    <div>
                      <Link to="/track">
                        <div className={styles.home_marketting_inner_image}>
                          <div
                            className={
                              styles.home_marketting_inner_image_wrapper
                            }
                          >
                            <img src={marketting2} alt="marketting" />
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>

                  {/* <div></div> */}

                  <div className={styles.home_marketting_inner}>
                    {/* <div className={styles.home_marketting_inner_text}>
                    <p>GET TYRES THAT NEVER COMPROMISE OF SAFETY AND STYLE.</p>
                    <h1>Drive with confidence.</h1>
                   
                      <p>Visit</p>
                  
                  </div> */}
                    <div>
                      {" "}
                      <Link to="/spare-parts">
                        {" "}
                        <div className={styles.home_marketting_inner_image}>
                          <div
                            className={
                              styles.home_marketting_inner_image_wrapper
                            }
                          >
                            <img src={marketting} alt="marketting" />
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div></div>
                  </div>
                </div>
              </div>
            </div>
            {/* )} */}
          </div>
        </div>
      )}
      <BottomNav />
    </section>
  );
}

export default BuyDongle;
