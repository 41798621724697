import "./App.css";
import { useState, useEffect } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router";
import Login from "./Pages/auth/Login";
import SignUp from "./Pages/auth/SignUp";
import ForgotPassword from "./Pages/forgotPassword/ForgotPassword";
import RecoveryPassword from "./Pages/forgotPassword/RecoveryPassword";
import ForgotPasswordForm1 from "./Pages/forgotPassword/ForgotPasswordForm1";
import ForgotPasswordForm2 from "./Pages/forgotPassword/ForgotPasswordForm2";
import RegisterVehicle from "./Pages/auth/RegisterVehicle";
import Home from "./Pages/home/home/BuyDongle";
import Services from "./Pages/home/services/Services";
import Menu from "./Components/interface/Menu";
import RegisterVehicleOutlet from "./Pages/auth/RegisterVehicleOutlet";
import RegisterVehicleForm from "./Pages/auth/RegisterVehicleForm";
import MyCar from "./Pages/home/myCar/MyCar";
import MyCarPage from "./Pages/home/myCar/MyCarPage";
import LiveMetrics from "./Pages/home/myCar/LiveMetrics";
import Driving from "./Pages/home/myCar/Driving";
import Analysis from "./Pages/home/myCar/Analysis";
import Maintenance from "./Pages/home/myCar/Maintenance";
import { useDispatch, useSelector } from "react-redux";
import SmartScan from "./Pages/home/smartscan/SmartScan";
import AddRecipient from "./Pages/home/services/AddRecipient";
import InsufficientBallance from "./Pages/wallet/InsufficientBallance";
import History from "./Pages/history/History";
import Recents from "./Pages/history/Recents";
import Diagnosis from "./Pages/history/Diagnosis";
import Transaction from "./Pages/history/Transaction";
import { authActions } from "./store/redux-store/Index";
import Papers from "./Pages/home/papers/Papers";
import PapersOutlet from "./Pages/home/papers/PapersOutlet";
import Checkout from "./Pages/home/papers/Checkout";
import Invoice from "./Pages/home/papers/Invoice";
import Success from "./Pages/home/papers/Success";
import Account from "./Pages/account/Account";
import RecentTransaction from "./Pages/account/RecentTransaction";
import CreditTransaction from "./Pages/account/CreditTransaction";
import DebitTransaction from "./Pages/account/DebitTransaction";
import Wallet from "./Pages/account/Wallet";
import OrderDetails from "./Pages/history/OrderDetails";
// import Insurance from "./Pages/Insurance/Insurance";
// import InsuranceRegister from "./Pages/Insurance/InsuranceRegister";
import InsuranceClaims from "./Pages/Insurance/InsuranceClaims";
// import InsuranceHome from "./Pages/Insurance/InsuranceHome";
import Notifications from "./Pages/home/home/Notifications";
import Cart from "./Pages/home/papers/Cart";
import SparePartsCart from "./Pages/home/services/Cart";
import SpareParts from "./Pages/home/services/SpareParts";
import Track from "./Pages/track/Track";
import Health from "./Pages/health/Health";
import ReportPage2 from "./Components/customer-profile/ReportPage2";
import Report from "./Components/customer-profile/Report";
import HomeMaintenance from "./Components/vehicle/Maintenance";
import ResetPassword from "./Pages/forgotPassword/ResetPassword";
import ProductsList from "./Pages/home/services/ProductsList";
import MotorAi from "./Pages/health/MotorAi";
import AddVehicleForm from "./Pages/auth/AddVehicleForm";
import NotificationPage from "./Pages/home/home/NotificationPage";
import ScheduledServices from "./Pages/home/services/ScheduledServices";
import ServicesCarts from "./Pages/home/services/ServicesCarts";
import ServiceCheckout from "./Pages/home/services/ServiceCheckout";
import ServiceInvoice from "./Pages/home/services/ServiceInvoice";
import Redirect from "./Pages/redirect/Redirect";
import Insurance from "./Pages/home/papers/Insurance";
import InsuranceItemPage from "./Pages/home/papers/InsuranceItemPage";
import InsuranceKyc from "./Pages/home/papers/InsuranceKyc";
import InsuranceClaim from "./Pages/home/papers/InsuranceClaim";
import InsuranceTerms from "./Pages/home/papers/InsuranceTerms";
import InsuranceCheckout from "./Pages/home/papers/InsuranceCheckout";
import InsuranceLandingPage from "./Pages/home/papers/InsuranceLandingPage";
import InspectionReport from "./Pages/inspeaction-report/InspectionReport";
import InspectionReportHistory from "./Pages/inspeaction-report/InspectionReportHistory";
import InspectionSingleReport from "./Pages/inspeaction-report/InspectionSingleReport";

function App() {
  const [showMenu, setShowMenu] = useState(false);
  const [parameter, setParameter] = useState("");
  const { pathname } = useLocation();
  const loggedIn = useSelector((state) => state.auth.userLoggedIn);

  const dispatch = useDispatch();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paramValue = params.get("player_id");
    setParameter(paramValue);
  }, []);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/progressier.js", { scope: "/" })
        .then((registration) => {
          // Registration was successful
          console.log(
            "Service Worker registered with scope:",
            registration.scope
          );
        })
        .catch((error) => {
          // Registration failed
          console.error("Service Worker registration failed:", error);
        });
    }
  }, []);

  //Toggle register vehicle page//
  const [registerVehicle, setRegisterVehicle] = useState(false);
  //Toggle register vehicle page//
  let navigate = useNavigate();

  let closeMenuHandler = () => {
    setShowMenu(false);
  };
  useEffect(() => {
    if (pathname === "/") {
      navigate("/home");
    }
    if (pathname === "/history") {
      navigate("/history/diagnosis");
    }
    if (pathname === "/account") {
      navigate("/account/recent-transaction");
    }
  }, [navigate, pathname, registerVehicle]);

  useEffect(() => {
    let timer = setTimeout(() => {
      dispatch(authActions.loggedOut());
    }, 300000);
    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <div>
      <div className="body">
        <div className="menu" onClick={closeMenuHandler}>
          {showMenu && <Menu setShowMenu={setShowMenu} />}
        </div>
        <Routes>
          {/* Home page. */}
          <Route
            path="/home"
            element={
              (loggedIn && (
                <Home
                  registerVehicle={registerVehicle}
                  setShowMenu={setShowMenu}
                  showMenu={showMenu}
                />
              )) ||
              (!loggedIn && <Navigate to="/login" />)
            }
          />

          {/* Home maintenance*/}
          <Route
            path="/maintenance/:id"
            element={
              <HomeMaintenance setShowMenu={setShowMenu} showMenu={showMenu} />
            }
          />

          {/* Services Page */}
          <Route
            path="/services"
            element={
              (loggedIn && (
                <Services setShowMenu={setShowMenu} showMenu={showMenu} />
              )) ||
              (!loggedIn && <Navigate to="/login" />)
            }
          />

          <Route
            path="/spare-parts"
            element={
              (loggedIn && (
                <SpareParts setShowMenu={setShowMenu} showMenu={showMenu} />
              )) ||
              (!loggedIn && <Navigate to="/login" />)
            }
          />

          <Route
            path="/spare-parts/:name"
            element={
              <ProductsList setShowMenu={setShowMenu} showMenu={showMenu} />
            }
          />
          <Route
            path="/spare-parts/scheduled-services/:id"
            element={
              <ScheduledServices
                setShowMenu={setShowMenu}
                showMenu={showMenu}
              />
            }
          />

          <Route
            path="/spare-parts/cart"
            element={
              <ServicesCarts setShowMenu={setShowMenu} showMenu={showMenu} />
            }
          />

          <Route
            path="/spare-parts/checkout"
            element={
              <ServiceCheckout setShowMenu={setShowMenu} showMenu={showMenu} />
            }
          />

          <Route
            path="/spare-parts/invoice"
            element={
              <ServiceInvoice setShowMenu={setShowMenu} showMenu={showMenu} />
            }
          />

          <Route
            path="/cart"
            element={
              (loggedIn && (
                <SparePartsCart setShowMenu={setShowMenu} showMenu={showMenu} />
              )) ||
              (!loggedIn && <Navigate to="/login" />)
            }
          />

          <Route
            path="/addrecipiept"
            element={
              (loggedIn && (
                <AddRecipient setShowMenu={setShowMenu} showMenu={showMenu} />
              )) ||
              (!loggedIn && <Navigate to="/login" />)
            }
          />

          {/* Papers Page */}
          <Route
            path="/papers"
            element={
              (loggedIn && <PapersOutlet />) ||
              (!loggedIn && <Navigate to="/login" />)
            }
          >
            <Route
              path="/papers"
              element={<Papers setShowMenu={setShowMenu} showMenu={showMenu} />}
            />
            <Route
              path="/papers/cart"
              element={<Cart setShowMenu={setShowMenu} showMenu={showMenu} />}
            />
            <Route
              path="/papers/checkout"
              element={
                <Checkout setShowMenu={setShowMenu} showMenu={showMenu} />
              }
            />
            <Route
              path="/papers/invoice"
              element={
                <Invoice setShowMenu={setShowMenu} showMenu={showMenu} />
              }
            />
            <Route path="/papers/success" element={<Success />} />
          </Route>

          {/* Account Page/wallet/subscription */}
          <Route
            path="/account"
            element={
              (loggedIn && (
                <Account setShowMenu={setShowMenu} showMenu={showMenu} />
              )) ||
              (!loggedIn && <Navigate to="/login" />)
            }
          >
            <Route path="/account" element={<Wallet />}>
              <Route
                path="/account/recent-transaction"
                element={<RecentTransaction />}
              />
              <Route
                path="/account/credit-transaction"
                element={<CreditTransaction />}
              />
              <Route
                path="/account/debit-transaction"
                element={<DebitTransaction />}
              />
            </Route>
          </Route>

          <Route
            path="/insufficientballance"
            element={
              (loggedIn && <InsufficientBallance />) ||
              (!loggedIn && <Navigate to="/login" />)
            }
          />

          {/* History page for all transactions */}

          <Route
            path="/orders"
            element={
              (loggedIn && (
                <Recents setShowMenu={setShowMenu} showMenu={showMenu} />
              )) ||
              (!loggedIn && <Navigate to="/login" />)
            }
          />

          <Route
            path="/history"
            element={
              (loggedIn && (
                <History setShowMenu={setShowMenu} showMenu={showMenu} />
              )) ||
              (!loggedIn && <Navigate to="/login" />)
            }
          >
            {/* <Route path="/history/order" element={<Recents />} /> */}
            <Route path="/history/diagnosis" element={<Diagnosis />} />
            <Route path="/history/transaction" element={<Transaction />} />
          </Route>

          <Route
            path="/orders/:id"
            element={
              <OrderDetails setShowMenu={setShowMenu} showMenu={showMenu} />
            }
          />

          {/* Insurance and Insurance claims
          <Route
            path="/insurance"
            element={
              (loggedIn && (
                <Insurance setShowMenu={setShowMenu} showMenu={showMenu} />
              )) ||
              (!loggedIn && <Navigate to="/login" />)
            }
          >
            <Route path="/insurance/register" element={<InsuranceRegister />} />
            <Route path="/insurance/claims" element={<InsuranceClaims />} />
          </Route>

          <Route
            path="/insurance-home"
            element={
              (loggedIn && (
                <InsuranceHome
                  registerVehicle={registerVehicle}
                  setShowMenu={setShowMenu}
                  showMenu={showMenu}
                />
              )) ||
              (!loggedIn && <Navigate to="/login" />)
            }
          /> */}

          {/* SmartScan Page */}
          <Route
            path="/scanner"
            element={
              (loggedIn && (
                <SmartScan setShowMenu={setShowMenu} showMenu={showMenu} />
              )) ||
              (!loggedIn && <Navigate to="/login" />)
            }
          />

          {/* Reset password Page */}
          <Route
            path="/reset-password"
            element={
              (loggedIn && (
                <ResetPassword setShowMenu={setShowMenu} showMenu={showMenu} />
              )) ||
              (!loggedIn && <Navigate to="/login" />)
            }
          />

          {/* Report page */}
          <Route
            path="/report-page/:id"
            element={<Report setShowMenu={setShowMenu} showMenu={showMenu} />}
          />

          <Route
            path="/health/report-page"
            element={
              <ReportPage2 setShowMenu={setShowMenu} showMenu={showMenu} />
            }
          />

          {/* Health Page */}
          <Route
            path="/health"
            element={
              (loggedIn && (
                <Health setShowMenu={setShowMenu} showMenu={showMenu} />
              )) ||
              (!loggedIn && <Navigate to="/login" />)
            }
          />

          <Route
            path="/motorAi"
            element={
              (loggedIn && (
                <MotorAi setShowMenu={setShowMenu} showMenu={showMenu} />
              )) ||
              (!loggedIn && <Navigate to="/login" />)
            }
          />

          {/* Track Page */}
          <Route
            path="/track"
            element={
              (loggedIn && (
                <Track setShowMenu={setShowMenu} showMenu={showMenu} />
              )) ||
              (!loggedIn && <Navigate to="/login" />)
            }
          />

          {/* Notifications */}
          <Route
            path="/notifications"
            element={
              (loggedIn && (
                <Notifications
                  parameter={parameter}
                  setShowMenu={setShowMenu}
                  showMenu={showMenu}
                />
              )) ||
              (!loggedIn && <Navigate to="/login" />)
            }
          />

          <Route
            path="/notifications/:id"
            element={
              (loggedIn && (
                <NotificationPage
                  setShowMenu={setShowMenu}
                  showMenu={showMenu}
                />
              )) ||
              (!loggedIn && <Navigate to="/login" />)
            }
          />

          {/* My car page (Not in basic plan app) */}
          <Route
            path="/mycar"
            element={
              (loggedIn && <MyCarPage />) ||
              (!loggedIn && <Navigate to="/login" />)
            }
          >
            <Route
              path="/mycar"
              element={<MyCar setShowMenu={setShowMenu} showMenu={showMenu} />}
            >
              <Route path="/mycar/livemetrics" element={<LiveMetrics />} />
              <Route path="/mycar/driving" element={<Driving />} />
              <Route path="/mycar/analysis" element={<Analysis />} />
              <Route path="/mycar/maintenance" element={<Maintenance />} />
            </Route>
          </Route>

          {/* Login, Sign Up, Onboarding and Forgot password (Authentication) Pages */}
          {!loggedIn && (
            <Route path="/login" element={<Login parameter={parameter} />} />
          )}

          <Route path="/add-new-vehicle" element={<AddVehicleForm />} />

          <Route path="/signup" element={<SignUp />} parameter={parameter} />

          <Route path="/registervehicle" element={<RegisterVehicleOutlet />}>
            <Route path="/registervehicle" element={<RegisterVehicle />} />
            <Route
              path="form"
              element={
                <RegisterVehicleForm
                  setRegisterVehicle={setRegisterVehicle}
                  parameter={parameter}
                />
              }
            />
          </Route>

          <Route path="/forgotpassword" element={<RecoveryPassword />}>
            <Route path="/forgotpassword" element={<ForgotPasswordForm1 />} />
            <Route path="form1" element={<ForgotPassword />} />
            <Route path="form2" element={<ForgotPasswordForm2 />} />
          </Route>

          <Route path="/insurance" element={<Insurance />}>
            <Route
              path="/insurance"
              element={
                <InsuranceLandingPage
                  setShowMenu={setShowMenu}
                  showMenu={showMenu}
                />
              }
            />
            <Route
              path="third-party-claim"
              element={
                <InsuranceClaims
                  setShowMenu={setShowMenu}
                  showMenu={showMenu}
                />
              }
            />
            <Route
              path="insurance-products"
              element={
                <InsuranceItemPage
                  setShowMenu={setShowMenu}
                  showMenu={showMenu}
                />
              }
            />
            <Route
              path="kyc"
              element={
                <InsuranceKyc setShowMenu={setShowMenu} showMenu={showMenu} />
              }
            />
            <Route
              path="claim"
              element={
                <InsuranceClaim setShowMenu={setShowMenu} showMenu={showMenu} />
              }
            />
            <Route
              path="terms"
              element={
                <InsuranceTerms setShowMenu={setShowMenu} showMenu={showMenu} />
              }
            />
            <Route
              path="checkout"
              element={
                <InsuranceCheckout
                  setShowMenu={setShowMenu}
                  showMenu={showMenu}
                />
              }
            />
          </Route>

          <Route path="/inspection-report" element={<InspectionReport />}>
            <Route
              path="/inspection-report"
              element={
                <InspectionReportHistory
                  setShowMenu={setShowMenu}
                  showMenu={showMenu}
                />
              }
            />
            <Route
              path=":id"
              element={
                <InspectionSingleReport
                  setShowMenu={setShowMenu}
                  showMenu={showMenu}
                />
              }
            />
          </Route>

          <Route
            path="/pwa-redirect"
            element={<Redirect setShowMenu={setShowMenu} showMenu={showMenu} />}
          />
        </Routes>
      </div>
    </div>
  );
}

export default App;
