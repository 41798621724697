import React from "react";
import styles from "./VehicleInformation.module.css";
import photo from "../../asset/images/Avatar.png";
import { useSelector } from "react-redux";
import AddedVehicles from "../vehicle/AddedVehicles";

function VehicleInformation2(props) {
  const personalData = useSelector((data) => data.auth);

  return (
    <div className={styles.vehicleDetails_2}>
      {props.vehicle_engine && (
        <div className={styles.vehicleDetails_2_head}>
          <p>Vehicle Infomation</p>
        </div>
      )}
      <div>
        <div className={styles.card}>
          {props.vehicle_engine && (
            <div className={styles.card_personal_info}>
              <div className={styles.card_personal_info_inner}>
                <div className={styles.image_outer}>
                  <div className={styles.image}>
                    <div className={styles.image_wrapper}>
                      <img src={photo} alt="user" />
                    </div>
                  </div>
                  <div className={styles.user_info}>
                    <h4>{personalData?.generalLoginData?.fullname}</h4>
                    <p>{personalData?.generalLoginData?.phone}</p>
                    <p>{personalData?.generalLoginData?.email}</p>
                  </div>
                </div>

                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="8" cy="8" r="8" fill="#0DB226" />
                </svg>
              </div>
            </div>
          )}
          <div className={styles.car_details}>
            <div className={styles.car_details_inner}>
              <div className={styles.car_details_info}>
                <div className={styles.car_details_row}>
                  <svg
                    width="5"
                    height="34"
                    viewBox="0 0 5 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="5" height="34" rx="2.5" fill="#5D5FEF" />
                  </svg>
                  <div>
                    <p>Make</p>
                    <h3>{`${props.vehicle_make} ${props.vehicle_model}`}</h3>
                  </div>
                </div>

                <div className={styles.car_details_row}>
                  <svg
                    width="5"
                    height="34"
                    viewBox="0 0 5 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="5" height="34" rx="2.5" fill="#0DB226" />
                  </svg>

                  <div>
                    <p>VIN {props?.vin}</p>
                    <h3>{props?.plate_number?.toUpperCase()}</h3>
                  </div>
                </div>
              </div>

              <div className={styles.car_details_info}>
                <div className={styles.car_details_row}>
                  <svg
                    width="5"
                    height="34"
                    viewBox="0 0 5 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="5" height="34" rx="2.5" fill="#5D5FEF" />
                  </svg>
                  <div>
                    <p>{props.vehicle_engine ? `Vehicle Body` : `Date`}</p>
                    {props.vehicle_engine ? (
                      <h3>
                        {props.vehicle_body ||
                          props?.data3?.scan_data?.vehicle_data?.vehicle_body}
                      </h3>
                    ) : (
                      <h3>{props?.inspection_date}</h3>
                    )}
                  </div>
                </div>

                <div className={styles.car_details_row}>
                  <svg
                    width="5"
                    height="34"
                    viewBox="0 0 5 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="5" height="34" rx="2.5" fill="#0DB226" />
                  </svg>

                  <div>
                    <p>
                      {props?.vehicle_engine
                        ? `Engine Type`
                        : `Inspection Number`}
                    </p>

                    {props?.vehicle_engine ? (
                      <h3>
                        {props.vehicle_engine ||
                          props?.data3?.scan_data?.vehicle_data?.vehicle_engine}
                      </h3>
                    ) : (
                      <h3>{props?.inspection_number}</h3>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {props.multi_vehicles && (
              <AddedVehicles carImages={props?.carImages} />
            )}
          </div>
          {/* <div className={styles.car_details_inner}> */}

          {/* </div> */}
        </div>
      </div>
    </div>
  );
}

export default VehicleInformation2;
